import React, {useState, useCallback, useContext, useEffect} from "react";
import Table from "../kanzleistandard/editable_table";
import {useFetch} from "use-http";
import {TaxDigitsContext} from "../../context";
import {notify} from "react-notify-toast";
import ConnectedCell from "../kanzleistandard/connected_cell";
import Spinner from "../../components/Spinner";
import ConfirmationModal from "../../components/cofirnmation_modal";
import {
    columns_expense,
    columns_inventory,
    columns_revenue,
    columns_voucher,
    colums_shipment
} from "./columns";


const MandantSchnittstelle = (props) => {
    const {put, del, get, response, loading, error} = useFetch({cachePolicy: 'no-cache'});
    const [standard, setStandard] = useState(null)

    const [revenues, setRevenues] = useState(null)
    const [revenues_other, setRevenuesOther] = useState(null)
    const [voucher, setVoucher] = useState(null)
    const [expense, setExpense] = useState(null)
    const [inventory, setInventory] = useState(null)
    const [shipment, setShipment] = useState(null)
    const [fee, setFee] = useState(null)
    const [customer_payment_type, setCustomer_payment_type] = useState(null)
    const [payment_providers, setPayment_providers] = useState(null)
    const [country_code, setCountry_code] = useState(null)

    const [triggerFee, setTriggerFee] = useState(null)
    const [triggerCustomer_payment_type, setTriggerCustomer_payment_type] = useState(null)
    const [triggerPaymentProviders, setTriggerPaymentProviders] = useState(null)
    const [triggerCountryCode, setTriggerCountryCode] = useState(null)

    const [hideRevenues, setHideRevenues] = useState(true)
    const [hideRevenuesOther, setHideRevenuesOther] = useState(true)
    const [hideVoucher, setHideVoucher] = useState(true)
    const [hideExpense, setHideExpense] = useState(true)
    const [hideInventory, setHideInventory] = useState(true)
    const [hideShipment, setHideShipment] = useState(true)
    const [hideFee, setHideFee] = useState(true)
    const [hideCustomerPaymentType, setHideCustomerPaymentType] = useState(true)
    const [hideCustomerPaymentProvider, setHideCustomerPaymentProvider] = useState(true)
    const [hideCountryCode, setHideCountryCode] = useState(true)

    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showLengthModal, setShowLengthModal] = useState(false)
    const [showSetStandardModal, setShowSetStandardModal] = useState(false)

    const [newLength, setNewLength] = useState(null)

    const userContext = useContext(TaxDigitsContext);

    let requestId
    if (userContext.profileUser.company) {
        requestId = userContext.profileUser.company
    }
    if (userContext.profileUser.is_tax_user === true && userContext.selectedClient) {
        requestId = userContext.selectedClient.id
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        updateFee()
        updateCustomerPaymentType()
        updatePaymentProvider()
        updateCountryCode()
    }, [standard])

    useEffect(() => {
        if (fee && customer_payment_type && payment_providers && country_code) {
            const new_fee = fee
            const new_customer_payment_type = customer_payment_type
            const new_payment_providers = payment_providers
            const new_country_code = country_code
            new_fee.forEach((entry) => {
                entry.account_ending = entry.account_ending.substring(0, userContext.account_length - 4)
                entry.skr = entry.skr.substring(0, userContext.account_length)

            })
            new_customer_payment_type.forEach((entry) => {
                entry.account_ending = entry.account_ending.substring(0, userContext.account_length - 4)
                entry.skr = entry.skr.substring(0, userContext.account_length)

            })
            new_payment_providers.forEach((entry) => {
                entry.account_ending = entry.account_ending.substring(0, userContext.account_length - 4)
                entry.money_transfer_skr = entry.money_transfer_skr.substring(0, userContext.account_length)
                entry.fee_skr = entry.fee_skr.substring(0, userContext.account_length)
                entry.bankaccount_skr = entry.bankaccount_skr.substring(0, userContext.account_length)
            })
            new_country_code.forEach((entry) => {
                entry.account_ending = entry.account_ending.substring(0, userContext.account_length - 4)
                entry.income_de_eu_lowrate_skr = entry.income_de_eu_lowrate_skr.substring(0, userContext.account_length)
                entry.income_de_eu_highrate_skr = entry.income_de_eu_highrate_skr.substring(0, userContext.account_length)
                entry.intra_community_one_skr = entry.intra_community_one_skr.substring(0, userContext.account_length)
                entry.intra_community_two_skr = entry.intra_community_two_skr.substring(0, userContext.account_length)
                entry.intra_community_three_skr = entry.intra_community_three_skr.substring(0, userContext.account_length)
                entry.intra_community_four_skr = entry.intra_community_four_skr.substring(0, userContext.account_length)
                entry.intra_community_five_skr = entry.intra_community_five_skr.substring(0, userContext.account_length)
                entry.domestic_sale_skr = entry.domestic_sale_skr.substring(0, userContext.account_length)
            })
            setFee([...new_fee])
            setPayment_providers([...new_payment_providers])
            setCustomer_payment_type([...new_customer_payment_type])
            setCountry_code([...new_country_code])
        }

    }, [userContext.account_length])

    const updateCountryCode = () => {
        if (country_code) {
            const new_country_code = country_code
            new_country_code.forEach((entry) => {
                entry.income_de_eu_lowrate_skr = standard.income_de_eu_lowrate_skr + entry.account_ending
                entry.income_de_eu_highrate_skr = standard.income_de_eu_highrate_skr + entry.account_ending
                entry.intra_community_one_skr = standard.intra_community_one_skr + entry.account_ending
                entry.intra_community_two_skr = standard.intra_community_two_skr + entry.account_ending
                entry.intra_community_three_skr = standard.intra_community_three_skr + entry.account_ending
                entry.intra_community_four_skr = standard.intra_community_four_skr + entry.account_ending
                entry.intra_community_five_skr = standard.intra_community_five_skr + entry.account_ending
                entry.domestic_sale_skr = standard.domestic_sale_skr + entry.account_ending
            })
            setCountry_code([...new_country_code])
        }
    }
    const updatePaymentProvider = () => {
        if (payment_providers) {
            const new_payment_providers = payment_providers
            payment_providers.forEach((entry) => {
                entry.money_transfer_skr = standard.payment_provider_money_transfer_skr + entry.account_ending
                entry.fee_skr = standard.payment_provider_fee_skr + entry.account_ending
                entry.bankaccount_skr = standard.payment_service_bankaccount_skr + entry.account_ending
            })
            setPayment_providers([...new_payment_providers])
        }
    }

    const updateCustomerPaymentType = () => {
        if (customer_payment_type) {
            const new_customer_payment_type = customer_payment_type
            new_customer_payment_type.forEach((entry) => {
                entry.skr = standard.debitor_skr + entry.account_ending
            })
            setCustomer_payment_type([...new_customer_payment_type])
        }
    }

    const updateFee = () => {
        if (fee) {
            const new_fee = fee
            new_fee.forEach((entry) => {
                entry.skr = standard.payment_fee_skr + entry.account_ending
            })
            setFee([...new_fee])
        }
    }

    // set fee connected cells
    useEffect(() => {
        updateFee()
    }, [triggerFee])

    // set customer payment type connected cells
    useEffect(() => {
        updateCustomerPaymentType()
    }, [triggerCustomer_payment_type])

    // set customer payment type connected cells
    useEffect(() => {
        updatePaymentProvider()
    }, [triggerPaymentProviders])
    useEffect(() => {
        updateCountryCode()
    }, [triggerCountryCode])

    const getData = useCallback(async () => {
        const data = await get('/api/v1/taxdigits/company_datev_standard/' + requestId + '/')
        if (response.ok) {
            setStandard(data)
            userContext.setAccount_length(data.account_length)
            setRevenues(data.revenues_details)
            setRevenuesOther(data.revenues_other_details)
            setVoucher(data.voucher_details)
            setExpense(data.amazon_expense_account_details)
            setInventory(data.amazon_inventory_details)
            setShipment(data.amazon_shipment_of_goods_details)
            setFee(data.payment_fees_details)
            setCustomer_payment_type(data.customer_payment_type_details)
            setPayment_providers(data.payment_providers_details)
            setCountry_code(data.country_code_details)
        }
    })

    const saveData = async () => {
        const data = standard
        data.account_length = userContext.account_length
        data.revenues_details = revenues
        data.revenues_other_details = revenues_other
        data.voucher_details = voucher
        data.amazon_expense_account_details = expense
        data.amazon_inventory_details = inventory
        data.amazon_shipment_of_goods_details = shipment
        data.payment_fees_details = fee
        data.customer_payment_type_details = customer_payment_type
        data.payment_providers_details = payment_providers
        data.country_code_details = country_code
        setShowSaveModal(false)
        const sendRevenues = await put('/api/v1/taxdigits/company_datev_standard/' + requestId + '/', data)
        if (response.ok) {
            notify.show("Daten erfolgreich gespeichert.", "success", 3000);
        } else {
            notify.show("Fehler: " + sendRevenues.detail, "error", 3000);
        }
    }

    const deleteData = async () => {
        const deleteData = await del('/api/v1/taxdigits/company_datev_standard/' + requestId + '/')
        setShowDeleteModal(false)
        if (response.ok) {
            getData()
            notify.show("Daten erfolgreich entfernt.", "success", 3000);
        } else {
            notify.show("Fehler: " + deleteData.detail, "error", 3000);
        }
    }

    const restoreTaxDigitsStandard = async () => {
        const restoreData = await put('/api/v1/taxdigits/company_datev_standard/' + requestId + '/restore/')
        setShowSetStandardModal(false)
        if (response.ok) {
            getData()
            notify.show("Kanzlei-Standard erfolgreich übernommen.", "success", 3000);
        } else {
            notify.show("Fehler: " + restoreData.detail, "error", 3000);
        }
    }
    const changeLength = (data) => {
        userContext.setAccount_length(data)
        setShowLengthModal(false)
    }

    const onBlur = (value, accessor) => {
        const new_standard = standard
        new_standard[accessor] = value
        setStandard({...new_standard})
    }

    const fee_footer = [
        {value: 'Hauptkonto'},
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.payment_fee_skr),
            accessor: 'payment_fee_skr',
            editable: true,
            blurFunction: onBlur
        },
    ]

    const columns_fee = [
        {
            accessor: 'title', Header: 'Paymentgebühren', Footer: '', Cell: (cell) => {
                return <span>{cell.value}</span>
            }
        },
        {
            accessor: 'account_ending', Header: 'Kontoendung', Footer: '', Cell: (cell) => {
                return <ConnectedCell account_length={userContext.account_length} cell={cell} data={fee}
                                      setData={setFee}
                                      setTrigger={setTriggerFee}
                />
            }
        },

        {Header: 'BU-Schlüssel', accessor: 'bu', Footer: ''},
        {
            Header: 'SKR', accessor: 'skr', Cell: (cell) => {
                return <span>{cell.value}</span>
            },
        }
    ]
    const customer_payment_type_footer = [
        {value: 'Hauptkonto'},
        {value: ''},
        {
            value: (standard && standard.debitor_skr),
            accessor: 'debitor_skr',
            editable: true,
            blurFunction: onBlur
        },
    ]
    const columns_customer_payment_type = [
        {
            Header: "Debitoren nach Paymentype", accessor: "title", Cell: (cell) => {
                return <span>{cell.value}</span>
            }
        },
        {
            Header: "Kontoendung", accessor: "account_ending", Cell: (cell) => {
                return <ConnectedCell account_length={userContext.account_length} cell={cell}
                                      data={customer_payment_type}
                                      setData={setCustomer_payment_type}
                                      setTrigger={setTriggerCustomer_payment_type}
                />
            }
        },
        {
            Header: "SKR04/SKR03", accessor: "skr", Cell: (cell) => {
                return <span>{cell.value}</span>
            }
        },
    ]

    const payment_providers_footer = [
        {value: 'Hauptkonto'},
        {value: ''},
        {
            value: (standard && standard.payment_provider_money_transfer_skr),
            editable: true,
            blurFunction: onBlur,
            accessor: 'payment_provider_money_transfer_skr'
        },
        {
            value: (standard && standard.payment_provider_fee_skr),
            editable: true,
            blurFunction: onBlur,
            accessor: 'payment_provider_fee_skr'
        },
        {
            value: (standard && standard.payment_service_bankaccount_skr),
            editable: true,
            blurFunction: onBlur,
            accessor: 'payment_service_bankaccount_skr',
        }
    ]
    const columns_payment_providers = [
        {
            accessor: 'title', Header: 'Payment-Anbieter', Cell: (cell) => {
                return <span>{cell.value}</span>
            }
        },
        {
            accessor: 'account_ending', Header: 'Kontoendung', Cell: (cell) => {
                return <ConnectedCell account_length={userContext.account_length} cell={cell} data={payment_providers}
                                      setData={setPayment_providers} setTrigger={setTriggerPaymentProviders}
                />
            }
        },
        {
            Header: 'Geldtransit', columns: [
                {
                    Header: 'SKR', accessor: 'money_transfer_skr', Cell: (cell) => {
                        return <span>{cell.value}</span>
                    }
                },
            ]
        }, {
            Header: 'Sicherheitseinbehalte', columns: [
                {
                    Header: 'SKR', accessor: 'fee_skr', Cell: (cell) => {
                        return <span>{cell.value}</span>
                    }
                }
            ]
        }, {
            Header: 'Bankkonto', columns: [
                {
                    Header: 'SKR', accessor: 'bankaccount_skr', Cell: (cell) => {
                        return <span>{cell.value}</span>
                    }
                }
            ]
        },
    ]

    const country_code_footer = [
        {value: 'Hauptkonto'},
        {value: ''},
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.income_de_eu_lowrate_skr),
            editable: true,
            blurFunction: onBlur,
            accessor: 'income_de_eu_lowrate_skr'
        },
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.income_de_eu_highrate_skr),
            editable: true,
            blurFunction: onBlur,
            accessor: 'income_de_eu_highrate_skr'
        },
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.intra_community_one_skr),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_one_skr'
        },
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.intra_community_two_skr),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_two_skr'
        },
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.intra_community_three_skr),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_three_skr'
        },
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.intra_community_four_skr),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_four_skr'
        },
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.intra_community_five_skr),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_five_skr'
        },
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.domestic_sale_skr),
            editable: true,
            blurFunction: onBlur,
            accessor: 'domestic_sale_skr'
        },
    ]

    const columns_country_code = [
        {
            accessor: 'title', Header: 'Ländercode', Cell: (cell) => {
                return <span>{cell.value}</span>
            }
        },
        {
            accessor: 'account_ending', Header: 'Kontoendung', Cell: (cell) => {
                return <ConnectedCell account_length={userContext.account_length} cell={cell} data={country_code}
                                      setData={setCountry_code}
                                      setTrigger={setTriggerCountryCode}
                />
            }
        },
        {
            Header: 'Erlöse aus im Inland stpfl. EU-Lieferungen 7 % USt', columns: [
                {
                    Header: "DE-EU   B2C   kein OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'income_de_eu_lowrate_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'income_de_eu_lowrate_bu_payment'},
                        {
                            Header: 'SKR', accessor: 'income_de_eu_lowrate_skr', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                    ]
                }
            ]
        },
        {
            Header: 'Erlöse aus im Inland stpfl.  EU-Lieferungen 19 % USt', columns: [
                {
                    Header: " DE-EU   B2C   kein OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'income_de_eu_highrate_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'income_de_eu_highrate_bu_payment'},
                        {
                            Header: 'SKR', accessor: 'income_de_eu_highrate_skr', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                    ]
                }
            ]
        }, {
            Header: 'Innergemeinschaftlicher Fernverkauf 1', columns: [
                {
                    Header: "EU-DE   B2C   OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'intra_community_one_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'intra_community_one_bu_payment'},
                        {
                            Header: 'SKR', accessor: 'intra_community_one_skr', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                    ]
                }
            ]
        }, {
            Header: 'Innergemeinschaftlicher Fernverkauf 2', columns: [
                {
                    Header: "EU-DE   B2C   kein OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'intra_community_two_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'intra_community_two_bu_payment'},
                        {
                            Header: 'SKR', accessor: 'intra_community_two_skr', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                    ]
                }
            ]
        }, {
            Header: 'Innergemeinschaftlicher Fernverkauf 3', columns: [
                {
                    Header: "DE-EU   B2C   OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'intra_community_three_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'intra_community_three_bu_payment'},
                        {
                            Header: 'SKR', accessor: 'intra_community_three_skr', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                    ]
                }
            ]
        }, {
            Header: 'Innergemeinschaftlicher Fernverkauf 4', columns: [
                {
                    Header: "EU1-EU2   B2C   OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'intra_community_four_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'intra_community_four_bu_payment'},
                        {
                            Header: 'SKR', accessor: 'intra_community_four_skr', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                    ]
                }
            ]
        }, {
            Header: 'Innergemeinschaftlicher Fernverkauf 5', columns: [
                {
                    Header: "EU1-EU2   B2C   kein OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'intra_community_five_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'intra_community_five_bu_payment'},
                        {
                            Header: 'SKR', accessor: 'intra_community_five_skr', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                    ]
                }
            ]
        }, {
            Header: 'Erlöse innerhalb eines Mitgliedstaates', columns: [
                {
                    Header: "EU1-EU1   B2C/B2B   kein OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'domestic_sale_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'domestic_sale_bu_payment'},
                        {
                            Header: 'SKR', accessor: 'domestic_sale_skr', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                    ]
                }
            ]
        }
    ]


    return <div className="page-wrapper" style={{display: "block"}}>
        {(loading) && <Spinner/>}
        <div className="container-fluid">
            <div className="reverse-mode">
                <div className="dashboard-part" style={{height: "100%"}}>
                    <div className="row page-titles">
                        <div className="col-md-5 col-12 align-self-center">
                            <h3 className="text-themecolor mb-0">Mandant-Datev-Schnittstelle</h3>
                        </div>
                        <div className="col-md-7 col-12 align-self-center d-none d-md-flex justify-content-end">
                            <ol className="breadcrumb mb-0 p-0 bg-transparent">
                                <li className="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                                <li className="breadcrumb-item active d-flex align-items-center">Mandant-Datev-Schnittstelle</li>
                            </ol>
                        </div>
                    </div>
                    {showSaveModal &&
                    <ConfirmationModal message={"Möchten Sie die Daten speichern?"}
                                       header={'Speichern'}
                                       hide={setShowSaveModal}
                                       confirm="Speichern"
                                       submitFunction={saveData}

                    />}
                    {showDeleteModal &&
                    <ConfirmationModal message={"Möchten Sie die Daten löschen?"}
                                       header={'Löschen'}
                                       hide={setShowDeleteModal}
                                       confirm="Löschen"
                                       submitFunction={deleteData}

                    />}
                    {showLengthModal &&
                    <ConfirmationModal
                        message={"Achtung, wenn Sie die Kontenlänge ändern, verändern Sie die Daten in den Tabellen."}
                        header={'Achtung'}
                        hide={setShowLengthModal}
                        confirm="Ändern"
                        submitFunction={changeLength}
                        data={newLength}

                    />}
                    {showSetStandardModal &&
                    <ConfirmationModal
                        message={"Achtung, alle Daten werden auf den Kanzlei-Standard zurückgesetzt."}
                        header={'Achtung'}
                        hide={setShowSetStandardModal}
                        confirm="Zurücksetzen"
                        submitFunction={restoreTaxDigitsStandard}
                    />}
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="border-bottom title-part-padding">
                                    <h4 className="card-title mb-0">Mandant DATEV-Schnittstelle</h4>
                                </div>
                                <div className="card-body">
                                    <label htmlFor={"account_length"}
                                           className="control-label">Sachkontenlänge</label>
                                    <div className="row ">
                                        <div className="input-group mb-3 col-md-12">
                                            <select className="form-control form-select"
                                                    id={"account_length"}
                                                    data-placeholder="Choose a Category" tabIndex="1"
                                                    value={userContext.account_length}
                                                    onChange={(e) => {
                                                        setNewLength(e.target.value)
                                                        setShowLengthModal(true)
                                                    }}
                                                    name="account_length">
                                                <option value={6}>6</option>
                                                <option value={7}>7</option>
                                            </select>
                                            <div className="input-group-append">
                                                <button type="button" className="btn btn-warning"
                                                        onClick={() => {
                                                            setShowSetStandardModal(true)
                                                        }}>
                                                    Kanzleistandard übernehmen
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    {(revenues !== null && revenues.length > 0) &&
                                    <>
                                        <div className="row">
                                            <h3 className="col-auto mr-auto">Erlöse aus Warenlieferungen</h3>
                                            <button title="Ausklappen/Einklappen"
                                                    className="col-auto btn btn-secondary hide-btn mr-3"
                                                    onClick={() => {
                                                        setHideRevenues(!hideRevenues)
                                                    }
                                                    }
                                                    type="button">{!hideRevenues && '-' || '+'}
                                            </button>
                                        </div>
                                        {!hideRevenues &&
                                        <div className="repeater-default mt-4">
                                            <div data-repeater-list="">
                                                <div data-repeater-item="">
                                                    <div className="row">
                                                        <div className="col-sm-12 table-responsive">
                                                            <Table
                                                                columns={columns_revenue}
                                                                data={revenues}
                                                                setData={setRevenues}
                                                                name="Erlöse aus Warenlieferungen"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        <hr/>
                                    </>
                                    }
                                    {(revenues_other !== null && revenues_other.length > 0) &&
                                    <>
                                        <div className="row">
                                            <h3 className="col-auto mr-auto">Erlöse aus sonstigen Leistungen</h3>
                                            <button title="Ausklappen/Einklappen"
                                                    className="col-auto btn btn-secondary hide-btn mr-3"
                                                    onClick={() => {
                                                        setHideRevenuesOther(!hideRevenuesOther)
                                                    }
                                                    }
                                                    type="button">{!hideRevenuesOther && '-' || '+'}
                                            </button>
                                        </div>
                                        {!hideRevenuesOther &&
                                        <div className="repeater-default mt-4">
                                            <div data-repeater-list="">
                                                <div data-repeater-item="">
                                                    <div className="row">
                                                        <div className="col-sm-12 table-responsive">
                                                            <Table
                                                                columns={columns_revenue}
                                                                data={revenues_other}
                                                                setData={setRevenuesOther}
                                                                name="Erlöse aus sonstigen Leistungen"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        <hr/>
                                    </>
                                    }
                                    {(voucher !== null && voucher.length > 0) &&
                                    <>
                                        <div className="row">
                                            <h3 className="col-auto mr-auto">Gutscheine</h3>
                                            <button title="Ausklappen/Einklappen"
                                                    className="col-auto btn btn-secondary hide-btn mr-3"
                                                    onClick={() => {
                                                        setHideVoucher(!hideVoucher)
                                                    }
                                                    }
                                                    type="button">{!hideVoucher && '-' || '+'}
                                            </button>
                                        </div>
                                        {!hideVoucher &&
                                        <div className="repeater-default mt-4">
                                            <div data-repeater-list="">
                                                <div data-repeater-item="">
                                                    <div className="row">
                                                        <div className="col-sm-12 table-responsive">
                                                            <Table
                                                                columns={columns_voucher}
                                                                data={voucher}
                                                                setData={setVoucher}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        <hr/>
                                    </>
                                    }
                                    {(expense !== null && expense.length > 0) &&
                                    <>
                                        <div className="row">
                                            <h3 className="col-auto mr-auto">Amazon-Spezifische Aufwandskonten</h3>
                                            <button title="Ausklappen/Einklappen"
                                                    className="col-auto btn btn-secondary hide-btn mr-3"
                                                    onClick={() => {
                                                        setHideExpense(!hideExpense)
                                                    }
                                                    }
                                                    type="button">{!hideExpense && '-' || '+'}
                                            </button>
                                        </div>
                                        {!hideExpense &&
                                        <div className="repeater-default mt-4">
                                            <div data-repeater-list="">
                                                <div data-repeater-item="">
                                                    <div className="row">
                                                        <div className="col-sm-12 table-responsive">
                                                            <Table
                                                                columns={columns_expense}
                                                                data={expense}
                                                                setData={setExpense}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        <hr/>
                                    </>
                                    }
                                    {(inventory !== null && inventory.length > 0) &&
                                    <>
                                        <div className="row">
                                            <h3 className="col-auto mr-auto">Vermischter Warenbestand Amazon</h3>
                                            <button title="Ausklappen/Einklappen"
                                                    className="col-auto btn btn-secondary hide-btn mr-3"
                                                    onClick={() => {
                                                        setHideInventory(!hideInventory)
                                                    }
                                                    }
                                                    type="button">{!hideInventory && '-' || '+'}
                                            </button>
                                        </div>
                                        {!hideInventory &&
                                        <div className="repeater-default mt-4">
                                            <div data-repeater-list="">
                                                <div data-repeater-item="">
                                                    <div className="row">
                                                        <div className="col-sm-12 table-responsive">
                                                            <Table
                                                                columns={columns_inventory}
                                                                data={inventory}
                                                                setData={setInventory}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        <hr/>
                                    </>
                                    }
                                    {(shipment !== null && shipment.length > 0) &&
                                    <>
                                        <div className="row">
                                            <h3 className="col-auto mr-auto">Warenverbringungen (Amazon CEE/PAN-EU)</h3>
                                            <button title="Ausklappen/Einklappen"
                                                    className="col-auto btn btn-secondary hide-btn mr-3"
                                                    onClick={() => {
                                                        setHideShipment(!hideShipment)
                                                    }
                                                    }
                                                    type="button">{!hideShipment && '-' || '+'}
                                            </button>
                                        </div>
                                        {!hideShipment &&
                                        <div className="repeater-default mt-4">
                                            <div data-repeater-list="">
                                                <div data-repeater-item="">
                                                    <div className="row">
                                                        <div className="col-sm-12 table-responsive">
                                                            <Table
                                                                columns={colums_shipment}
                                                                data={shipment}
                                                                setData={setShipment}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        <hr/>
                                    </>
                                    }
                                    {(fee !== null && fee.length > 0) &&
                                    <>
                                        <div className="row">
                                            <h3 className="col-auto mr-auto">Paymentgebühren</h3>
                                            <button title="Ausklappen/Einklappen"
                                                    className="col-auto btn btn-secondary hide-btn mr-3"
                                                    onClick={() => {
                                                        setHideFee(!hideFee)
                                                    }
                                                    }
                                                    type="button">{!hideFee && '-' || '+'}
                                            </button>
                                        </div>
                                        {!hideFee &&
                                        <div className="repeater-default mt-4">
                                            <div data-repeater-list="">
                                                <div data-repeater-item="">
                                                    <div className="row">
                                                        <div className="col-sm-12 table-responsive">
                                                            <Table
                                                                columns={columns_fee}
                                                                data={fee}
                                                                setData={setFee}
                                                                footer={fee_footer}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        <hr/>
                                    </>
                                    }
                                    {(customer_payment_type !== null && customer_payment_type.length > 0) &&
                                    <>
                                        <div className="row">
                                            <h3 className="col-auto mr-auto">Debitoren nach Paymenttype</h3>
                                            <button title="Ausklappen/Einklappen"
                                                    className="col-auto btn btn-secondary hide-btn mr-3"
                                                    onClick={() => {
                                                        setHideCustomerPaymentType(!hideCustomerPaymentType)
                                                    }
                                                    }
                                                    type="button">{!hideCustomerPaymentType && '-' || '+'}
                                            </button>
                                        </div>
                                        {!hideCustomerPaymentType &&
                                        <div className="repeater-default mt-4">
                                            <div data-repeater-list="">
                                                <div data-repeater-item="">
                                                    <div className="row">
                                                        <div className="col-sm-12 table-responsive">
                                                            <Table
                                                                columns={columns_customer_payment_type}
                                                                data={customer_payment_type}
                                                                setData={setCustomer_payment_type}
                                                                footer={customer_payment_type_footer}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        <hr/>
                                    </>
                                    }
                                    {(payment_providers !== null && payment_providers.length > 0) &&
                                    <>
                                        <div className="row">
                                            <h3 className="col-auto mr-auto">Payment-Anbieter</h3>
                                            <button title="Ausklappen/Einklappen"
                                                    className="col-auto btn btn-secondary hide-btn mr-3"
                                                    onClick={() => {
                                                        setHideCustomerPaymentProvider(!hideCustomerPaymentProvider)
                                                    }
                                                    }
                                                    type="button">{!hideCustomerPaymentProvider && '-' || '+'}
                                            </button>
                                        </div>
                                        {!hideCustomerPaymentProvider &&
                                        <div className="repeater-default mt-4">
                                            <div data-repeater-list="">
                                                <div data-repeater-item="">
                                                    <div className="row">
                                                        <div className="col-sm-12 table-responsive">
                                                            <Table
                                                                columns={columns_payment_providers}
                                                                data={payment_providers}
                                                                setData={setPayment_providers}
                                                                footer={payment_providers_footer}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        <hr/>
                                    </>
                                    }
                                    {((country_code !== null && country_code.length > 0) && (userContext.selectedClient.country_specific_accounts)) &&
                                    <>
                                        <div className="row">
                                            <h3 className="col-auto mr-auto">Länderspezifische Erlöskonten</h3>
                                            <button title="Ausklappen/Einklappen"
                                                    className="col-auto btn btn-secondary hide-btn mr-3"
                                                    onClick={() => {
                                                        setHideCountryCode(!hideCountryCode)
                                                    }
                                                    }
                                                    type="button">{!hideCountryCode && '-' || '+'}
                                            </button>
                                        </div>
                                        {!hideCountryCode &&
                                        <div className="repeater-default mt-4">
                                            <div data-repeater-list="">
                                                <div data-repeater-item="">
                                                    <div className="row">
                                                        <div className="col-sm-12 table-responsive">
                                                            <Table
                                                                columns={columns_country_code}
                                                                data={country_code}
                                                                setData={setCountry_code}
                                                                footer={country_code_footer}
                                                                additionalClasses={'fixed-first-columns'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        <hr/>
                                    </>
                                    }
                                </div>
                                <div className="row m-1">
                                    <div className="btn-toolbar">
                                        <button type="button" className="btn mx-1 btn-success"
                                                onClick={() => {
                                                    setShowSaveModal(true)
                                                }}>
                                            Speichern
                                        </button>
                                        <button data-repeater-delete=""
                                                className="btn px-4 btn-danger font-weight-medium waves-effect waves-light m-l-10"
                                                type="button"
                                                onClick={() => {
                                                    setShowDeleteModal(true)
                                                }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                 height="24" viewBox="0 0 24 24" fill="none"
                                                 stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-trash-2 feather-sm ms-2 fill-white">
                                                <polyline points="3 6 5 6 21 6"/>
                                                <path
                                                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/>
                                                <line x1="10" y1="11" x2="10" y2="17"/>
                                                <line x1="14" y1="11" x2="14" y2="17"/>
                                            </svg>
                                            Alles löschen
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        ;
}

export default MandantSchnittstelle;