import React from "react"
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import Benutzerverwaltung from "./benutzerverwaltung";

const BenutzerverwaltungPage = (props) => {
    return <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
     data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
        <Header/>
        <Sidebar/>
        <Benutzerverwaltung/>
        </div>;
}

export default BenutzerverwaltungPage;