import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useFetch} from "use-http";
import Notifications, {notify} from "react-notify-toast";
import ConfirmationModal from "../../components/cofirnmation_modal";
import * as openpgp from 'openpgp'
import {defaultPublicKey} from "./pgp_key";

const ShopsystemCard = (props) => {
    const {register, handleSubmit, setError, errors, reset} = useForm();
    const {patch, post, del, response, loading, error} = useFetch({cachePolicy: 'no-cache'});
    const [name, setName] = useState('amazon')
    const [dropshipping, setDropshipping] = useState(null)
    const [origin_country, setOrigin_Country] = useState('de')
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [title, setTitle] = useState(null)
    const [activeFrom, setActiveFrom] = useState(null)
    const [activeTo, setActiveTo] = useState(null)
    const [keys, setKeys] = useState([])
    const keyMapping = {
        'amazon': [
            {key: 'seller_id', title: 'Verkäufer-ID'},
            {key: 'mms_token', title: 'Token für MWS-Autorisierung'},
        ],
        'shopify': [
            {key: 'api_key', title: 'API-Schlüssel'},
            {key: 'password', title: 'API-Admin-Zugriffstoken'},
            {key: 'example_url', title: 'Admin-URL (shopname.myshopify.com)'},
            {key: 'shared_secret_key', title: ' Geheimer API-Schlüssel'},
        ],
        'billbee': [
            {key: 'user', title: 'Benutzer'},
            {key: 'api_password', title: 'API Passwort'},
        ],
        'easybill': [
            {key: 'user', title: 'Benutzer'},
            {key: 'api_key', title: 'API-Schlüssel'},
        ],
        woo_commerce: [
            {key: 'name', title: 'Name'},
            {key: 'password', title: 'Passwort'},
            {key: 'url', title: 'Base-URL'},
        ],
        ebay: ["Für ebay-Transaktionen bieten wir einen CSV-Upload an.", "Bitte stellen Sie den Transaktionsbericht monatsweise im Bereich CSV-Upload bereit."],
        gyg: ["Für 'Get Your Guide'-Transaktionen bieten wir einen CSV-Upload an.", "Bitte stellen Sie den Transaktionsbericht monatsweise im Bereich CSV-Upload bereit."],
        viator: ["Für Viator-Transaktionen bieten wir einen CSV-Upload an.", "Bitte stellen Sie den Transaktionsbericht monatsweise im Bereich CSV-Upload bereit."],
        avocado: ["Für Avocadostore-Transaktionen bieten wir einen CSV-Upload an.", "Bitte stellen Sie den Transaktionsbericht monatsweise im Bereich CSV-Upload bereit."],
        bookingkit: ["Aktivieren Sie die TaxDigits App bei Bookingkit."],
    }

    useEffect(() => {
        if (props.data) {
            reset()
            setName(props.data.name)
            setDropshipping(props.data.dropshipping)
            setOrigin_Country(props.data.origin_country)
            setKeys([])
            setTitle(props.data.title)
            setActiveFrom(props.data.active_from)
            setActiveTo(props.data.active_to)
        }
        if (props.newShopSystem) {
            setName('amazon')
            setDropshipping(null)
            setOrigin_Country(null)
            setKeys(keyMapping[name])
            setTitle(null)
            setActiveFrom(null)
            setActiveTo(null)
        }
    }, [props.data])

    const inspectionStates = {
        unchecked: "Steht aus",
        check_ok: "Ok",
        check_failed: "Nicht Ok",
    }

    useEffect(() => {
        if (props.data && props.data.name === name) {
            keyMapping[name] = []
        }
        if ((name === 'woo_commerce' || name === 'shopify') && (dropshipping === "true" || dropshipping === true)) {
            if (props.data) {
                setOrigin_Country(props.data.origin_country)
            } else {
                setOrigin_Country('de')
            }
        } else {
            setOrigin_Country(null)
        }
        setKeys(keyMapping[name])
    }, [name])

    useEffect(() => {
        if (dropshipping === "true" || dropshipping === true) {
            if (props.data) {
                setOrigin_Country(props.data.origin_country)
            } else {
                setOrigin_Country('de')
            }
        }
    }, [dropshipping])

    async function submitForm(data) {
        let formResponse = null
        data.name = name
        data.dropshipping = dropshipping
        data.origin_country = origin_country
        data.active_from = !activeFrom ? null : activeFrom
        data.active_to = !activeTo ? null : activeTo
        data.title = title
        setShowSaveModal(false)
        if (data.name === 'ebay' || data.name === 'avocado' || data.name === 'bookingkit') {
            data.keys = null
        } else {
            const publicKey = await openpgp.readKey({armoredKey: defaultPublicKey});
            data.keys = await openpgp.encrypt({
                message: await openpgp.createMessage({text: JSON.stringify(keys)}), // input as Message object
                encryptionKeys: publicKey, // for encryption
            });
        }
        if (props.data) {
            delete data['keys']
            formResponse = await patch(props.url + props.data.id + '/' + props.url_query, data);
        } else {
            formResponse = await post(props.url + props.url_query, data);
        }
        if (response.ok) {
            notify.show("Daten erfolgreich gespeichert", "success", 3000);
            props.setSelectedShopSystem(null)
            props.reload()
            if (formResponse.multiple_shopsystems) {
                props.setShowMultipleShopSystemsModal(true)
            }
            reset()
        } else if (response.status === 403) {
            notify.show("Fehler: " + formResponse.detail, "error", 3000);
        } else {
            for (const [key, value] of Object.entries(formResponse)) {
                setError(key, {
                    type: "manual",
                    message: value[0]
                })
            }
            notify.show("Fehler: Die Daten konnten nicht gespeichert werden.", "error", 3000);
        }
    }


    async function deleteShopSystem(data) {
        setShowDeleteModal(false)
        let formResponse = null
        if (props.data) {
            formResponse = await del(props.url + props.data.id + '/' + props.url_query);
        }
        if (response.ok || !props.data.id) {
            notify.show("Shopsystem erfolgreich entfernt", "success", 3000);
            props.setSelectedShopSystem(null)
            props.reload()
            reset()
        } else if (response.status === 403) {
            notify.show("Fehler: " + formResponse.detail, "error", 3000);
        } else {
            notify.show("Beim entfernen ist ein Fehler aufgetreten.", "error", 3000);
        }
    }


    return <div className="card mt-3">
        {(props.data === null && !props.newShopSystem) &&
        <div className="disabled-overlay">
            <h3>Kein Shopsystem ausgewählt</h3>
        </div>}
        <Notifications options={{zIndex: 45, top: '70px', animationDuration: 800}}/>
        <form onSubmit={handleSubmit(submitForm)}
              onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault();
              }}>
            {showSaveModal &&
            <ConfirmationModal message={"Möchten Sie die Daten speichern?"}
                               header={'Speichern'}
                               hide={setShowSaveModal}
                               confirm="Speichern"
            />}
            {showDeleteModal &&
            <ConfirmationModal message={"Möchten Sie das Shopsystem löschen?"}
                               header={'Löschen'}
                               hide={setShowDeleteModal}
                               confirm="Löschen"
                               submitFunction={deleteShopSystem}

            />}
            <div className="border-bottom title-part-padding d-flex justify-content-between">
                {props.data &&
                <>
                    <h4 className="card-title mb-0">Shopysteme &
                        Plattformen: {props.names[props.data.name]}</h4>
                    <h4 className="">Prüfstatus: {inspectionStates[props.data.inspection_state]}</h4>
                </>
                }
                {!props.data &&
                <h4 className="card-title mb-0 text-info">Shopysteme &
                    Plattformen: Neues Shopsystem</h4>
                }
            </div>
            <div className="card-body">
                <div className="repeater-default mt-4">
                    <div data-repeater-list="">
                        <div data-repeater-item="">
                            <div className="row">
                                <div className="mb-3 col-md-3">
                                    <label className="control-label">Plattform</label>
                                    <select ref={register("name")}
                                            className="form-control form-select" tabIndex="1"
                                            value={name} name="name" disabled={!props.newShopSystem}
                                            onChange={(e) => {
                                                if (e.currentTarget.value === 'shopify' || e.currentTarget.value === 'woo_commerce') {
                                                    setDropshipping(false)
                                                } else {
                                                    setDropshipping(null)
                                                    setOrigin_Country(null)
                                                }
                                                setName(e.currentTarget.value)
                                            }}>
                                        {Object.keys(props.names).map(function (key) {
                                            return <option
                                                value={key}>{props.names[key]}</option>
                                        })}
                                    </select>
                                    {errors.name && <div style={{display: "block"}} className="invalid-feedback">
                                        {errors.name.message}
                                    </div>}
                                </div>
                                <div className="mb-3 col-md-3">
                                    <label htmlFor="title">Name des Shops</label>
                                    <input ref={register()} type="text" className="form-control"
                                           placeholder="" required={false}
                                           value={title}
                                           name="title"
                                           onChange={(e) => {
                                               setTitle(e.currentTarget.value)
                                           }}
                                    />
                                    {errors.title &&
                                    <div style={{display: "block"}}
                                         className="invalid-feedback">
                                        {errors.title.message}
                                    </div>}
                                </div>
                                <div className="mb-3 col-md-3">
                                    <label htmlFor="active_from">Aktiv seit</label>
                                    <input ref={register()} type="date" className="form-control"
                                           placeholder="" required={false}
                                           value={activeFrom}
                                           onChange={(e) => {
                                               setActiveFrom(e.currentTarget.value)
                                           }}
                                           name="active_from"/>
                                    {errors.active_from &&
                                    <div style={{display: "block"}}
                                         className="invalid-feedback">
                                        {errors.active_from.message}
                                    </div>}
                                </div>
                                <div className="mb-3 col-md-3">
                                    <label htmlFor="active_to">Aktiv bis</label>
                                    <input ref={register()} type="date" className="form-control"
                                           placeholder="" required={false}
                                           onChange={(e) => {
                                               setActiveTo(e.currentTarget.value)
                                           }}
                                           value={activeTo} name="active_to"/>
                                    {errors.active_to &&
                                    <div style={{display: "block"}}
                                         className="invalid-feedback">
                                        {errors.active_to.message}
                                    </div>}
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="kost_1">Kost 1</label>
                                    <input ref={register()} type="text" className="form-control"
                                           placeholder=""
                                           defaultValue={props.data && props.data.kost_1}
                                           name="kost_1"/>
                                    {errors.kost_1 &&
                                    <div style={{display: "block"}}
                                         className="invalid-feedback">
                                        {errors.kost_1.message}
                                    </div>}
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="kost_2">Kost 2</label>
                                    <input ref={register()} type="text" className="form-control"
                                           placeholder=""
                                           defaultValue={props.data && props.data.kost_2}
                                           name="kost_2"/>
                                    {errors.kost_2 &&
                                    <div style={{display: "block"}}
                                         className="invalid-feedback">
                                        {errors.kost_2.message}
                                    </div>}
                                </div>
                                {(name === 'woo_commerce' || name === 'shopify') &&
                                <div className="mb-3 col-md-6">
                                    <label
                                        className="control-label">Dropshipping</label>
                                    <select ref={register("dropshipping")}
                                            className="form-control form-select"
                                            data-placeholder="Choose a Category" tabIndex="1"
                                            value={dropshipping}
                                            onChange={(e) => {
                                                if (e.target.value === true) {
                                                    setOrigin_Country('de')
                                                } else {
                                                    setOrigin_Country(null)
                                                }
                                                setDropshipping(e.currentTarget.value)
                                            }}
                                            name="dropshipping">
                                        <option value={true}>Ja</option>
                                        <option value={false}>Nein</option>
                                    </select>
                                    {errors.dropshipping &&
                                    <div style={{display: "block"}} className="invalid-feedback">
                                        {errors.dropshipping.message}
                                    </div>}
                                </div>
                                }
                                {((name === 'woo_commerce' || name === 'shopify') && (dropshipping === "true" || dropshipping === true)) &&
                                <div className="mb-3 col-md-6">
                                    <label
                                        className="control-label">Ursprunglsand</label>
                                    <select className="form-control form-select"
                                            data-placeholder="Choose a Category"
                                            tabIndex="1"
                                            value={origin_country}
                                            onChange={e => setOrigin_Country(e.currentTarget.value)}>
                                        {Object.keys(props.countries).map(function (key) {
                                            return <option
                                                value={key}>{props.countries[key]}</option>
                                        })}
                                    </select>
                                    {errors.country_specific_accounts &&
                                    <div style={{display: "block"}}
                                         className="invalid-feedback">
                                        {errors.country_specific_accounts.message}
                                    </div>}
                                </div>
                                }
                                {(props.data && !(name === 'woo_commerce' || name === 'ebay' || name === 'avocado' || name === 'bookingkit')) &&
                                <div className="my-5 col-md-12">
                                    <h3 className="text-center">Daten wurden bereits hinterlegt.</h3>
                                </div>
                                }
                                <div className="w-100"/>
                                {keys.map((entry, i) => {
                                    if (typeof entry === 'string') {
                                        return <div className="mb-1 col-md-12">
                                            <h4 className="text-center">{entry}</h4>
                                        </div>
                                    }
                                    return <div className="mb-3 col-md-6">
                                        <label htmlFor={entry.key}>{entry.title}</label>
                                        <input type="text" className="form-control" name={entry.key} required
                                               value={entry.value || ""} onChange={(e) => {
                                            const new_keys = keys
                                            new_keys[i].value = e.target.value
                                            setKeys([...new_keys])
                                        }}
                                        />
                                    </div>
                                })}
                                <div className="mb-3 col-md-12 ">
                                    <button
                                        className="btn rounded-pill px-4 btn-success font-weight-medium waves-effect waves-light"
                                        type="button" onClick={() => setShowSaveModal(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             width="24"
                                             height="24" viewBox="0 0 24 24" fill="none"
                                             stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round"
                                             strokeLinejoin="round"
                                             className="feather feather-send feather-sm ms-2 fill-white">
                                            <line x1="22" y1="2" x2="11" y2="13"/>
                                            <polygon points="22 2 15 22 11 13 2 9 22 2"/>
                                        </svg>
                                        Speichern
                                    </button>
                                    <button data-repeater-delete="" onClick={() => setShowDeleteModal(true)}
                                            className="btn rounded-pill ml-1 px-4 btn-danger font-weight-medium waves-effect waves-light m-l-10"
                                            type="button" hidden={props.newShopSystem}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                             height="24" viewBox="0 0 24 24" fill="none"
                                             stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="feather feather-trash-2 feather-sm ms-2 fill-white">
                                            <polyline points="3 6 5 6 21 6"/>
                                            <path
                                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/>
                                            <line x1="10" y1="11" x2="10" y2="17"/>
                                            <line x1="14" y1="11" x2="14" y2="17"/>
                                        </svg>
                                        Löschen
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

}

export default ShopsystemCard;