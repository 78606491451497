import React from "react";
import Nav from "./nav";

const Header = (props) => {
    return (
        <header className="topbar" data-navbarbg="skin6">
            <Nav/>
        </header>
    );
}

export default Header;