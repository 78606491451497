import React, {useCallback, useState, useEffect, useContext} from "react";
import Table from "../../components/table"
import {notify} from "react-notify-toast";
import {useFetch} from "use-http";
import {useForm} from "react-hook-form";
import ConfirmationModal from "../../components/cofirnmation_modal";
import {TaxDigitsContext} from "../../context";

const CSVUpload = (props) => {
    const {register, handleSubmit, watch, reset, errors, clearErrors} = useForm();
    const userContext = useContext(TaxDigitsContext);
    const [file_data, setFileData] = useState(null)
    const [addFileData, setAddFileData] = useState(null)
    const [addFileId, setAddFileId] = useState()
    const {post, put, del, get, response, loading, error} = useFetch({cachePolicy: 'no-cache'}, opts => {
        delete opts.headers['Content-Type']
        return opts
    });
    const [name, setName] = useState('ebay')
    const [filterName, setFilterName] = useState('choose')
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showFileModal, setShowFileModal] = useState(false)
    const [file, setFile] = useState(null)
    const [deleteId, setDeleteId] = useState()
    const defaultInputText = '(Optional) Klicke hier um eine Datei auszuwählen.'
    const [addInputText, setAddInputText] = useState(defaultInputText)
    const [fileInputText, setFileInputText] = useState(defaultInputText)
    const [fileType, setFileType] = useState('.csv')
    let query = ''
    if (userContext.selectedClient) {
        query = '?company_id=' + userContext.selectedClient.id
    }


    useEffect(() => {
        getData()
    }, [])


    const getData = useCallback(async (name = null) => {
        let extra_query = '' + query
        if (name) {
            if (query !== '') {
                extra_query += '&name=' + name
            } else {
                extra_query = '?name=' + name
            }
        }
        const initialData = await get('/api/v1/taxdigits/files/' + extra_query);
        if (response.ok) {
            setFileData(initialData)
        } else if (response.status === 403) {
            notify.show("Fehler: " + initialData.detail, "error", 3000);
        }
    }, [get, response])

    async function submitForm(data) {
        setShowSaveModal(false)
        const upload_data = new FormData()
        if (file) {
            upload_data.append('file', file)
        }
        upload_data.append('name', name)
        upload_data.append('month', data.month +'-01')
        const form_response = await post('/api/v1/taxdigits/files/' + query, upload_data);
        if (response.ok) {
            reset()
            setFile(null)
            setFileInputText(defaultInputText)
            getData()
            notify.show("Daten erfolgreich gespeichert", "success", 3000);
        } else if (response.status === 400) {
            notify.show(form_response[Object.keys(form_response)[0]][0], "error", 3000);
        }
    }

    const deleteData = async (id) => {
        setShowDeleteModal(false)
        const deleteData = await del('/api/v1/taxdigits/files/' + id + '/' + query)
        if (response.ok) {
            getData()
            notify.show("Daten erfolgreich entfernt.", "success", 3000);
        } else {
            notify.show("Beim löschen ist ein Fehler aufgetreten.", "error", 3000);
        }
    }

    const downloadFile = async (id, name) => {
        const file = await get('/api/v1/taxdigits/files/' + id + '/' + query)
        if (response.ok) {
            //workaround for ajax download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name)
            document.body.appendChild(link);
            link.click();
        } else {
            notify.show("Beim download ist ein Fehler aufgetreten.", "error", 30000000);
        }
    }
    const setFilter = (name) => {
        setFilterName(name)
        if (name !== 'None') {
            getData(name)
        } else {
            getData()
        }
    }
    const addFile = async () => {
        if (!addFileData) {
            notify.show("Keine Datei ausgewählt.", "error", 3000);
            return
        }
        const upload_data = new FormData()
        upload_data.append('file', addFileData)
        const form_response = await put('/api/v1/taxdigits/files/' + addFileId + '/' + query, upload_data);
        if (response.ok) {
            setAddFileData(null)
            setAddInputText(defaultInputText)
            getData()
            setShowFileModal(false)
            notify.show("Datei erfolgreich hinzugefügt.", "success", 3000);
        } else {
            notify.show(form_response, "error", 3000);
        }
    }

    const name_choices = {
        ebay: 'ebay',
        mango: 'Bookingkit-Mango',
        bk_stripe: 'Bookingkit-Stripe',
        bk_reach: 'Bookingkit-Reach',
        avocado: 'Avocadostore',
        viator: 'Viator',
        gyg: 'Get your Guide',

    }

    const old_name_choices = {
        paypal: 'PayPal',
        klarna: 'Klarna',
        stripe: 'Stripe',
        shopify: 'Shopify',
        ebay: 'ebay',
        amazon: 'Amazon',
        mango: 'Bookingkit-Mango',
        bk_stripe: 'Bookingkit-Stripe',
        bk_reach: 'Bookingkit-Reach',
        avocado: 'Avocadostore',
        n26: 'N26',
        viator: 'Viator',
        gyg: 'Get your Guide',
        n26_converter: 'N26 Kontoauszug-Konverter',
    }

    const date_time_cell = (cell) => {
        let date = new Date(cell.value)
        const day = ('0' + (date.getDate())).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const hours = ('0' + (date.getHours())).slice(-2);
        const minutes = ('0' + (date.getMinutes())).slice(-2);
        return <span>{day}.{month}.{date.getFullYear()} - {hours}:{minutes}</span>
    }
    const date_cell = (cell) => {
        let date = new Date(cell.value)
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        return <span>{month}.{date.getFullYear()}</span>
    }
    const choice_cell = (cell) => {
        return <span>{old_name_choices[cell.value]}</span>
    }
    const delete_cell = (cell) => {
        return <button onClick={() => {
            setShowDeleteModal(true)
            setDeleteId(cell.row.values['id'])
        }} className='btn btn-danger btn-sm'>Eintrag löschen</button>
    }

    const download_cell = (cell) => {
        if (cell.row.values['state']) {
            return <button
                onClick={() => {
                    downloadFile(cell.row.values['id'], cell.row.values['file_name'])
                }} className='cell-btn btn btn-secondary btn-sm'>Datei downloaden
            </button>
        } else {
            return <button
                onClick={() => {
                    setAddFileId(cell.row.values['id'])
                    setShowFileModal(true)
                    if (cell.row.values['name'] === 'n26_converter') {
                        setFileType('.pdf')
                    } else {
                        setFileType('.csv')
                    }
                }} className='cell-btn btn btn-primary btn-sm'>Datei hinzufügen
            </button>
        }
    }


    const columns = [
        {Header: 'Bezeichnung', accessor: 'name', Cell: choice_cell},
        {Header: 'Zeitraum/Monat', accessor: 'month', Cell: date_cell},
        {Header: 'Letzte Änderung', accessor: 'last_change', Cell: date_time_cell},
        {
            Header: 'Status', accessor: 'file_name', Cell: (cell) => {
                if (cell.value) {
                    return <span>{cell.row.values['file_name']}</span>
                }
                return <span>Fehlt</span>
            }
        },
        {
            Header: 'Download', accessor: 'id', Cell: download_cell
        },
        {
            Header: 'Löschen', accessor: 'state', Cell: delete_cell
        },
    ]
    return <div className="page-wrapper" style={{display: "block"}}>
        {showDeleteModal &&
        <ConfirmationModal message={"Möchten Sie den Eintrag wirklich löschen?"}
                           header={'Löschen'}
                           hide={setShowDeleteModal}
                           confirm="Löschen"
                           submitFunction={deleteData}
                           data={deleteId}

        />}
        <div className="container-fluid">
            {showFileModal &&
            <div className="file-modal confirmation-modal modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header d-flex align-items-center">
                        <h4 className="modal-title" id="myLargeModalLabel">Datei hinzufügen</h4>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="input-group col-md-12 mb-3">
                                <div className="custom-file">
                                    <input ref={register()} name="upload_file" type="file"
                                           className="custom-file-input" accept={fileType}
                                           onChange={(e) => {
                                               setAddInputText(e.target.files[0].name)
                                               setAddFileData(e.target.files[0])
                                           }}
                                    />
                                    <label className="custom-file-label">
                                        {addInputText}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button"
                                className="btn-modal btn btn-success ml-3 text font-weight-medium waves-effect text-start"
                                data-bs-dismiss="modal" onClick={addFile}>Speichern
                        </button>
                        <button type="button"
                                className="btn-modal btn btn-danger ml-3 text font-weight-medium waves-effect text-start"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setAddFileData(null)
                                    setAddInputText(defaultInputText)
                                    setShowFileModal(false)
                                }}
                        >Abbrechen
                        </button>
                    </div>
                </div>
            </div>
            }
            <div className="reverse-mode">
                <div className="dashboard-part" style={{height: "100%"}}>
                    <div className="row page-titles">
                        <div className="col-md-5 col-12 align-self-center">
                            <h3 className="text-themecolor mb-0">CSV-Upload</h3>
                        </div>
                        <div className="col-md-7 col-12 align-self-center d-none d-md-flex justify-content-end">
                            <ol className="breadcrumb mb-0 p-0 bg-transparent">
                                <li className="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                                <li className="breadcrumb-item active d-flex align-items-center">CSV-Upload</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="border-bottom title-part-padding">
                                    <h4 className="card-title mb-0">CSV-Upload: Payment</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="mb-3 col-md-12">
                                            <label
                                                className="control-label">Filter:</label>
                                            <select className="form-control form-select"
                                                    data-placeholder="Choose a Category" tabIndex="1"
                                                    value={filterName}
                                                    onChange={e => setFilter(e.currentTarget.value)}
                                                    name="name">
                                                <option hidden disabled value={"choose"}>Filter wählen...
                                                </option>
                                                <option value={"None"}>Kein Filter</option>
                                                {Object.keys(name_choices).map(function (key) {
                                                    return <option
                                                        value={key}>{name_choices[key]}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="repeater-default mt-4">
                                        {file_data !== null &&
                                        <div data-repeater-list="">
                                            <div data-repeater-item="">
                                                <div className="row">
                                                    <div className="col-sm-12 table-responsive">
                                                        <Table
                                                            columns={columns}
                                                            data={file_data}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        <hr/>
                                        <h3>Upload:</h3>
                                        <form onSubmit={handleSubmit(submitForm)}
                                              onKeyPress={(e) => {
                                                  e.key === 'Enter' && e.preventDefault();
                                              }}>
                                            {showSaveModal &&
                                            <ConfirmationModal message={"Möchten Sie die Daten speichern?"}
                                                               header={'Speichern'}
                                                               hide={setShowSaveModal}
                                                               confirm="Speichern"

                                            />}
                                            <div className="row">
                                                <div className="mb-3 col-md-6">
                                                    <label
                                                        className="control-label">Name</label>
                                                    <select ref={register("name")}
                                                            className="form-control form-select"
                                                            data-placeholder="Choose a Category" tabIndex="1"
                                                            value={name} required
                                                            onChange={e => {
                                                                setName(e.currentTarget.value)
                                                                if (e.currentTarget.value === "n26_converter") {
                                                                    setFileType(".pdf")
                                                                    return;
                                                                }
                                                                setFileType(".csv")
                                                            }}
                                                            name="name">
                                                        <option hidden disabled value={""}>Bitte wählen...
                                                        </option>
                                                        {Object.keys(name_choices).map(function (key) {
                                                            return <option
                                                                value={key}>{name_choices[key]}</option>
                                                        })}
                                                    </select>
                                                    {errors.name && <div style={{display: "block"}}
                                                                         className="invalid-feedback">
                                                        {errors.name.message}
                                                    </div>}
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label>Zeitraum/Monat</label>
                                                    <input ref={register()} name="month" type="month" required
                                                           className="form-control" placeholder=""
                                                    />
                                                </div>
                                                <div className="input-group col-md-12 mb-3">
                                                    <div className="custom-file">
                                                        <input ref={register()} name="upload_file" type="file"
                                                               className="custom-file-input" accept={fileType}
                                                               onChange={(e) => {
                                                                   if (e.target.files[0]) {
                                                                       setFile(e.target.files[0])
                                                                       setFileInputText(e.target.files[0].name)
                                                                   }
                                                               }}
                                                        />
                                                        <label className="custom-file-label">
                                                            {fileInputText}
                                                        </label>
                                                    </div>
                                                    <div className="input-group-append">
                                                        <button className="btn btn-secondary"
                                                                type="button"
                                                                hidden={file === null}
                                                                onClick={(e) => {
                                                                    setFile(null)
                                                                    setFileInputText(defaultInputText)
                                                                }}>Entfernen
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <button type="button"
                                                            className="btn rounded-pill px-4 btn-success font-weight-medium waves-effect waves-light"
                                                            onClick={(e) => {
                                                                setShowSaveModal(true)
                                                            }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                             height="24" viewBox="0 0 24 24" fill="none"
                                                             stroke="currentColor" strokeWidth="2"
                                                             strokeLinecap="round" strokeLinejoin="round"
                                                             className="feather feather-send feather-sm ms-2 fill-white">
                                                            <line x1="22" y1="2" x2="11" y2="13"/>
                                                            <polygon points="22 2 15 22 11 13 2 9 22 2"/>
                                                        </svg>
                                                        Speichern
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        ;
}

export default CSVUpload;