import React, {useState} from "react"
import {useForm} from "react-hook-form";
import {useFetch} from "use-http";
import logo from '../../images/taxdigits_logo_web.png';
import AGB from './agb'
import privacy_pdf from './Datenschutzerklärung taxdigits.de.pdf'
import {NavLink} from "react-router-dom";

const RegistrationConfirmedPage = (props) => {
    return <div className="auth-wrapper d-flex no-block justify-content-center align-items-center">
        <div className="auth-box p-4 bg-white rounded">
            <div>
                <div className="logo text-center">
                    <NavLink to="/login">
                        <span className="db img-responsive"><img src={logo} href={process.env.REACT_APP_API_URL}
                                                                 alt="logo"/></span>
                    </NavLink>
                </div>
                <div className="row mt-4">
                    <div className="col-12">
                        <p className="text-center">
                            Vielen Dank für die Registrierung bei taxdigits. Sie erhalten in Kürze eine E-Mail zur
                            Freischaltung Ihres Kundenkontos.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
        ;
}

const RegistrationPage = (props) => {
    const {register, handleSubmit, setError, errors} = useForm();
    const [confirmed, setConfirmed] = useState(false);
    const {post, response, loading, error} = useFetch({cachePolicy: 'no-cache'});
    const [showAGB, setShowAGB] = useState(false)

    async function onRegistrationSubmit(data) {
        data.username = data.email;
        data.is_tax_user = data.is_tax_user == "true";
        const login = await post('/api/v1/accounts/register/', data);
        if (response.ok) {
            setConfirmed(true);
        } else {
            if (login.password) {
                setError("password", {
                    type: "manual",
                    message: login.password[0]
                });
            }
            if (login.username) {
                setError("email", {
                    type: "manual",
                    message: login.username[0]
                });
            }
        }
    }

    if (confirmed) {
        return RegistrationConfirmedPage(props);
    }

    return <div className="login-background  auth-wrapper d-flex no-block justify-content-center align-items-center">
        {showAGB &&
        <AGB setShow={setShowAGB}/>
        }
        <div className="auth-box p-4 bg-white rounded">
            <div>
                <div className="logo text-center">
            <span className="db img-responsive">
            <NavLink to="/login">
            <img src={logo} alt="logo"/>
            </NavLink>
            </span>
                    <hr/>
                    <h3 className="font-weight-medium mb-3 mt-1">Registrieren</h3>
                </div>
                <div className="row mt-4">
                    <div className="col-12">
                        <form className="form-horizontal" onSubmit={handleSubmit(onRegistrationSubmit)}>
                            <div className="form-floating mb-3">
                                <label htmlFor="tb-remail">E-Mail</label>
                                <input type="email" name="email" className="form-control form-input-bg"
                                       id="tb-remail" placeholder=""
                                       required="required" ref={register({required: true})}/>
                                {errors.email && <div style={{display: "block"}} className="invalid-feedback">
                                    {errors.email.message}
                                </div>}
                            </div>
                            <div className="form-floating mb-3">
                                <label htmlFor="tb-rfname">Vorname</label>
                                <input type="text" name="first_name" className="form-control form-input-bg"
                                       id="tb-rfname" placeholder=""
                                       required="required" ref={register({required: false})}/>
                                {errors.first_name && <div style={{display: "block"}} className="invalid-feedback">
                                    {errors.first_name.message}
                                </div>}
                            </div>
                            <div className="form-floating mb-3">
                                <label htmlFor="tb-rfname">Nachname</label>
                                <input type="text" name="last_name" className="form-control form-input-bg"
                                       id="tb-rfname" placeholder=""
                                       required="required" ref={register({required: false})}/>
                                {errors.last_name && <div style={{display: "block"}} className="invalid-feedback">
                                    {errors.last_name.message}
                                </div>}
                            </div>
                            <div className="form-floating mb-3">
                                <label htmlFor="text-rpassword">Passwort</label>
                                <input type="password" name="password" className="form-control form-input-bg"
                                       id="text-rpassword"
                                       placeholder="" required="required" ref={register({required: true})}/>
                                {errors.password && <div style={{display: "block"}} className="invalid-feedback">
                                    {errors.password.message}
                                </div>}
                            </div>
                            <div className="form-floating mb-3">
                                <label htmlFor="consultant">Kontotyp</label>
                                <select className="form-control" id="consultant" name="is_tax_user"
                                        ref={register({required: true})}>
                                    <option value="false">Mandant</option>
                                    <option value="true" selected>Steuerberater</option>
                                </select>
                            </div>
                            <div className="checkbox checkbox-primary mb-3">
                                <input id="checkbox-signup" required="required" name="tos" type="checkbox"
                                       className="chk-col-indigo material-inputs" ref={register({required: true})}/>
                                <label className="agb-label" htmlFor="checkbox-signup">
                                    Ich stimme den <a
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setShowAGB(true);
                                    }}>AGB</a> und der <a target="_blank" href={privacy_pdf}
                                >Datenschutzerklärung</a> zu.
                                </label>
                                {errors.tos && <div className="invalid-feedback">
                                    {errors.tos.message}
                                </div>}
                            </div>
                            <div className="d-flex align-items-stretch">
                                <button type="submit" className="btn btn-info d-block w-100">Registrieren</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
        ;
}

export default RegistrationPage;