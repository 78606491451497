import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useFetch} from "use-http";
import Notifications, {notify} from "react-notify-toast";
import Spinner from "../../components/Spinner";
import ConfirmationModal from "../../components/cofirnmation_modal";

const UserCard = (props) => {
    const {register, handleSubmit, setError, errors, reset} = useForm();
    const {put, post, del, response, loading, error} = useFetch({cachePolicy: 'no-cache'});
    const [permissions, setPermissions] = useState()
    const [role, setRole] = useState()
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    useEffect(() => {
        if (props.data) {
            reset()
            setRole(props.data.role)
            setPermissions(props.data.permissions)
        }
    }, [props.data])

    async function submitForm(data) {
        setShowSaveModal(false)
        let formResponse = null
        data.role = role
        data.permissions = permissions
        if (props.data) {
            formResponse = await put('/api/v1/taxdigits/tax_user/' + props.data.id + '/', data);
        } else {
            formResponse = await post('/api/v1/taxdigits/tax_user/', data);
        }
        if (response.ok) {
            notify.show("Daten erfolgreich gespeichert", "success", 3000);
            props.setSelectedUser(null)
            props.reload()
            reset()
        } else if (response.status === 403) {
            notify.show(formResponse.detail, "error", 3000);
        } else {
            for (const [key, value] of Object.entries(formResponse)) {
                setError(key, {
                    type: "manual",
                    message: value[0]
                })
            }
            notify.show("Fehler: Die Daten konnten nicht gespeichert werden.", "error", 3000);
        }
    }

    async function deleteUser() {
        setShowDeleteModal(false)
        let formResponse = null
        if (props.data.id) {
            formResponse = await del('/api/v1/taxdigits/tax_user/' + props.data.id + '/');
        }
        if (response.ok) {
            notify.show("Benutzer erfolgreich entfernt", "success", 3000);
            props.setSelectedUser(null)
            props.reload()
            reset()
        } else if (response.status === 403) {
            notify.show("Fehler: " + formResponse.detail, "error", 3000);
        } else {
            notify.show("Beim löschen des Nutzers ist ein Fehler aufgetreten.", "error", 3000);
        }
    }

    return <div className="card">
        {(props.data === null && !props.newUser) &&
        <div className="disabled-overlay">
            <h3 className=" ">Kein Benutzer ausgewählt.</h3>
        </div>}
        {(loading) && <Spinner/>}
        <Notifications options={{zIndex: 45, top: '70px', animationDuration: 800}}/>
        <div className="border-bottom title-part-padding">
            <h4 className="card-title mb-0">Benutzer: {(props.data || {username: 'Neuer Nutzer'}).username}
            </h4>
        </div>
        <div className="card-body">
            <div className="repeater-default mt-4">
                <div data-repeater-list="">
                    <div data-repeater-item="">
                        <form onSubmit={handleSubmit(submitForm)}
                              autoComplete="off">
                            {showSaveModal &&
                            <ConfirmationModal message={"Möchten Sie die Daten speichern?"}
                                               header={'Speichern'}
                                               hide={setShowSaveModal}
                                               confirm="Speichern"

                            />}
                            {showDeleteModal &&
                            <ConfirmationModal message={"Möchten Sie den Benutzer löschen?"}
                                               header={'Löschen'}
                                               hide={setShowDeleteModal}
                                               confirm="Löschen"
                                               submitFunction={deleteUser}

                            />}
                            <div className="row">
                                <div className="mb-3 col-md-3">
                                    <label htmlFor="salutation">Anrede</label>
                                    <input ref={register()} type="text" className="form-control"
                                           name="salutation" defaultValue={(props.data || {salutation: ''}).salutation}
                                           required="required"
                                           placeholder=""/>
                                    {errors.salutation && <div style={{display: "block"}} className="invalid-feedback">
                                        {errors.salutation.message}
                                    </div>}

                                </div>
                                <div className="mb-3 col-md-3">
                                    <label htmlFor="first_name">Vorname</label>
                                    <input ref={register()} type="text" className="form-control"
                                           name="first_name" defaultValue={(props.data || {first_name: ''}).first_name}
                                           required="required"
                                           placeholder=""/>
                                    {errors.first_name && <div style={{display: "block"}} className="invalid-feedback">
                                        {errors.first_name.message}
                                    </div>}
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="last_name">Name</label>
                                    <input ref={register()} type="text" className="form-control"
                                           name="last_name" defaultValue={(props.data || {last_name: ''}).last_name}
                                           required="required" placeholder=""/>
                                    {errors.last_name && <div style={{display: "block"}} className="invalid-feedback">
                                        {errors.last_name.message}
                                    </div>}
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="phone">Telefon</label>
                                    <input ref={register()} type="number" className="form-control"
                                           name="phone" defaultValue={(props.data || {phone: ''}).phone}
                                           required="required"
                                           placeholder=""/>
                                    {errors.phone && <div style={{display: "block"}} className="invalid-feedback">
                                        {errors.phone.message}
                                    </div>}
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="email">E-Mail</label>
                                    <input ref={register()} type="email" className="form-control"
                                           name="email" defaultValue={(props.data || {email: ''}).email}
                                           required="required"
                                           placeholder=""/>
                                    {errors.email && <div style={{display: "block"}} className="invalid-feedback">
                                        {errors.email.message}
                                    </div>}
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label
                                        className="control-label">Rechte</label>
                                    <select ref={register("permissions")}
                                            className="form-control form-select"
                                            data-placeholder="Choose a Category" tabIndex="1"
                                            value={permissions}
                                            disabled={(props.data || {id: null}).id ===props.profileUser.id}
                                            onChange={e => setPermissions(e.currentTarget.value)}
                                            name="permissions">
                                        <option value="admin">Admin</option>
                                        <option value="settings">Einstellungen</option>
                                        <option value="read">Ansicht und Datenabruf</option>
                                    </select>
                                    {errors.permissions && <div style={{display: "block"}} className="invalid-feedback">
                                        {errors.permissions.message}
                                    </div>}
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label
                                        className="control-label">Funktion</label>
                                    <select ref={register("role")}
                                            className="form-control form-select"
                                            data-placeholder="Choose a Category" tabIndex="1"
                                            value={role}
                                            onChange={e => setRole(e.currentTarget.value)}
                                            name="role">
                                        <option value="employee">Mitarbeiter</option>
                                        <option value="consultant_inland">Steuerberater (Inland)</option>
                                        <option value="consultant_foreign_countries">Steuerberater (Ausland)</option>
                                    </select>
                                    {errors.role && <div style={{display: "block"}} className="invalid-feedback">
                                        {errors.role.message}
                                    </div>}
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="username">Benutzername</label>
                                    <input ref={register()} type="text" className="form-control"
                                           name="username" defaultValue={(props.data || {username: ''}).username}
                                           required="required"
                                           placeholder=""/>
                                    {errors.username && <div style={{display: "block"}} className="invalid-feedback">
                                        {errors.username.message}
                                    </div>}
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="user_pw">Passwort</label>
                                    <input ref={register()} type="password" className="form-control"
                                           name="password" defaultValue="" autoComplete="new-password"
                                           placeholder=""/>
                                    {errors.password && <div style={{display: "block"}} className="invalid-feedback">
                                        {errors.password.message}
                                    </div>}
                                </div>
                                <div className="mb-3 col-md-6 ">
                                    <button
                                        className="btn rounded-pill px-4 btn-success font-weight-medium waves-effect waves-light"
                                        type="button" onClick={() => setShowSaveModal(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             width="24"
                                             height="24" viewBox="0 0 24 24" fill="none"
                                             stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round"
                                             strokeLinejoin="round"
                                             className="feather feather-send feather-sm ms-2 fill-white">
                                            <line x1="22" y1="2" x2="11" y2="13"/>
                                            <polygon
                                                points="22 2 15 22 11 13 2 9 22 2"/>
                                        </svg>
                                        Speichern
                                    </button>
                                    {props.data !== null &&
                                    <button data-repeater-delete="" onClick={() => setShowDeleteModal(true)}
                                            className="btn rounded-pill ml-1 px-4 btn-danger font-weight-medium waves-effect waves-light m-l-10"
                                            type="button">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                             height="24" viewBox="0 0 24 24" fill="none"
                                             stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="feather feather-trash-2 feather-sm ms-2 fill-white">
                                            <polyline points="3 6 5 6 21 6"/>
                                            <path
                                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/>
                                            <line x1="10" y1="11" x2="10" y2="17"/>
                                            <line x1="14" y1="11" x2="14" y2="17"/>
                                        </svg>
                                        Löschen
                                    </button>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

}

export default UserCard;