import React, {useCallback, useEffect, useState,} from "react";
import RequestCard from "./request_card";
import {useFetch} from "use-http";
import Notifications, {notify} from "react-notify-toast";

const MandantenAnfragen = (props) => {
    const [userRequests, setUserRequests] = useState([]);
    const {get, post, response, loading, error} = useFetch({cachePolicy: 'no-cache'});


    useEffect(() => {
        getRequestData()
        let params = (new URL(document.location)).searchParams;
        if (params.get("bookit")) {
            const bookit_data = {"vendor_id": params.get("bookit")}
            getBookitRequest(bookit_data)
        }
    }, [])

    const getRequestData = useCallback(async () => {
        const data = await get('/api/v1/taxdigits/client_requests/');
        if (response.ok) {
            setUserRequests(data)
        } else if (response.status === 403) {
            notify.show("Fehler: " + data.detail, "error", 3000);
        }
    }, [get, response])

    async function getBookitRequest(bookit_data){
        const data = await post('/api/v1/bookit/connect/', bookit_data);
        if (response.ok){
            if (data === 'reload') {
                window.location.reload(true);
            }
        }
    }


    const showRequestToast = () => {
        notify.show("Fehler: Etwas ist schief gelaufen.", "error", 3000);
    }


    return <div className="page-wrapper" style={{display: "block"}}>
        <Notifications options={{zIndex: 45, top: '70px', animationDuration: 800}}/>
        <div className="container-fluid">
            <div className="reverse-mode">
                <div className="dashboard-part" style={{height: "100%"}}>
                    <div className="row page-titles">
                        <div className="col-md-5 col-12 align-self-center">
                            <h3 className="text-themecolor mb-0">Mandantenanfragen</h3>
                        </div>
                        <div className="col-md-7 col-12 align-self-center d-none d-md-flex justify-content-end">
                            <ol className="breadcrumb mb-0 p-0 bg-transparent">
                                <li className="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                                <li className="breadcrumb-item active d-flex align-items-center">Mandantenanfragen</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="user-wrapper">
                            {userRequests.length !== 0 &&
                            <div className="card">
                                <div className="border-bottom title-part-padding">
                                    <h4 className="card-title mb-0">Mandantenanfragen</h4>
                                </div>
                                <div className="card-body">
                                    <div data-repeater-list="">
                                        <div className="requests-container">
                                            {userRequests.map((item, idx) => {
                                                return <RequestCard data={item} showRequestToast={showRequestToast}
                                                                    reloadClient={getRequestData}/>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            {userRequests.length === 0 &&
                            <h4 className="d-flex justify-content-center">Keine offenen Anfragen</h4>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default MandantenAnfragen;