import React, {useCallback, useContext, useEffect, useState} from "react";
import ShopsystemCard from './shopsystem_card'
import PaymentsystemCard from "./paymentsystem_card";
import {useFetch} from "use-http";
import {notify} from "react-notify-toast";
import Table from '../../components/selection_table'
import {TaxDigitsContext} from "../../context";
import MulitpleShopSystemsModal from "./multiple_shop_systems_modal";

const ShopPaymentSysteme = (props) => {
    const [shopSystems, setShopSystems] = useState([]);
    const [paymentSystems, setPaymentSystems] = useState([]);
    const userContext = useContext(TaxDigitsContext);
    const {get, response, loading, error} = useFetch({cachePolicy: 'no-cache'});
    const [selectedShopSystem, setSelectedShopSystem] = useState(null)
    const [selectedPaymentSystem, setSelectedPaymentSystem] = useState(null)
    const [newShopSystem, setNewShopSystem] = useState(false)
    const [newPaymentSystem, setNewPaymentSystem] = useState(false)
    const isClient = !userContext.profileUser.is_tax_user
    const [showMultipleShopSystemsModal, setShowMultipleShopSystemsModal] = useState(false)
    let shop_systems_url = ''
    let payment_systems_url = ''
    let url_query = ''
    if (isClient) {
        shop_systems_url = '/api/v1/taxdigits/shop_systems/'
        payment_systems_url = '/api/v1/taxdigits/payment_systems/'
        url_query = ''
    } else {
        shop_systems_url = '/api/v1/taxdigits/tax_office/shop_systems/'
        payment_systems_url = '/api/v1/taxdigits/tax_office/payment_systems/'
        url_query = '?selected_company=' + userContext.selectedClient.id
    }

    const date_cell = (cell) => {
        if (!cell.value) {
            return <span> - </span>
        }
        let date = new Date(cell.value)
        const day = ('0' + (date.getDate())).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        return <span>{day}.{month}.{date.getFullYear()}</span>
    }

    const countries = {
        de: 'DE',
        fr: 'FR',
        it: 'IT',
        es: 'ES',
        be: 'BE',
        lu: 'LU',
        nl: 'NL',
        se: 'SE',
        at: 'AT',
        gr: 'GR',
        dk: 'DK',
        pt: 'PT',
        ie: 'IE',
        fi: 'FI',
        cy: 'CY',
        bg: 'BG',
        cz: 'CZ',
        ee: 'EE',
        sk: 'SK',
        si: 'SI',
        hu: 'HU',
        ro: 'RO',
        pl: 'PL',
        lt: 'LT',
        lv: 'LV',
        mt: 'MT',
        hr: 'HR',
        uk: 'UK',
        ch: 'CH',
        us: 'US',
    }
    const shop_names = {
        amazon: "Amazon",
        shopify: "Shopify",
        woo_commerce: "Woo-Commerce",
        ebay: "eBay",
        avocado: "Avocadostore",
        bookingkit: "Bookingkit",
        billbee: "Billbee",
        easybill: "Easybill",
    }

    const shopSystemsCell = (value) => {
        if (value.value) {
            return shop_names[value.value]
        }
        return ''
    }
    const countriesCell = (value) => {
        if (value.value) {
            return countries[value.value]
        }
        return ''
    }
    const dropshippinhCell = (value) => {
        const dropshipping = {
            true: "Ja",
            false: "Nein",
            null: ""
        }
        return dropshipping[value.value]
    }
    const payment_names = {
        paypal: 'PayPal',
        klarna: 'Klarna',
        stripe: 'Stripe',
        shopify: 'Shopify',
        ebay: 'ebay',
        amazon: 'Amazon',
        mango: 'Bookingkit-Mango',
        bk_stripe: 'Bookingkit-Stripe',
        bk_reach: 'Bookingkit-Reach',
        avocado: 'Avocadostore',
        mollie: 'Mollie',
        viator: 'Viator',
        gyg: 'Get Your Guide',

    }

    const shop_columns = [
        {Header: 'Shopsystem', accessor: 'name', Cell: shopSystemsCell},
        {Header: 'Name des Shops', accessor: 'title'},
        {Header: 'Aktiv seit', accessor: 'active_from', Cell: date_cell},
        {Header: 'Aktiv bis', accessor: 'active_to', Cell: date_cell},
        {Header: 'Dropshipping', accessor: 'dropshipping', Cell: dropshippinhCell},
        {Header: 'Ursprungsland', accessor: 'origin_country', Cell: countriesCell},
    ]
    const paymentSystemsCell = (value) => {
        if (value.value) {
            return payment_names[value.value]
        }
        return ''
    }
    const payment_columns = [
        {Header: 'Paymentsystem', accessor: 'name', Cell: paymentSystemsCell},
        {Header: 'Aktiv seit', accessor: 'active_from', Cell: date_cell},
        {Header: 'Aktiv bis', accessor: 'active_to', Cell: date_cell},
    ]

    const reloadData = () => {
        getShopSystems()
        getPaymentSystems()
        setSelectedShopSystem(null)
        setSelectedPaymentSystem(null)
    }

    useEffect(() => {
        reloadData()
    }, [])


    const getShopSystems = useCallback(async () => {
        setNewShopSystem(false)
        const initialData = await get(shop_systems_url + url_query);
        if (response.ok) {
            setShopSystems(initialData)
        } else if (response.status === 403) {
            notify.show("Fehler: " + initialData.detail, "error", 3000);
        }
    }, [get, response])

    const getPaymentSystems = useCallback(async () => {
        setNewPaymentSystem(false)
        const initialData = await get(payment_systems_url + url_query);
        if (response.ok) {
            setPaymentSystems(initialData)
        } else if (response.status === 403) {
            notify.show("Fehler: " + initialData.detail, "error", 3000);
        }
    }, [get, response])

    const addShopCard = () => {
        setSelectedShopSystem(null)
        setNewShopSystem(true)
    }

    const addPaymentCard = () => {
        setSelectedPaymentSystem(null)
        setNewPaymentSystem(true)
    }
    return <div className="page-wrapper" style={{display: "block"}}>
        <MulitpleShopSystemsModal showModal={setShowMultipleShopSystemsModal} hidden={!showMultipleShopSystemsModal}/>
        <div className="container-fluid">
            <div className="reverse-mode">
                <div className="dashboard-part" style={{height: "100%"}}>
                    <div className="row page-titles">
                        <div className="col-md-5 col-12 align-self-center">
                            <h3 className="text-themecolor mb-0">Shopsysteme, Bookingsysteme und Plattformen</h3>
                        </div>
                        <div className="col-md-7 col-12 align-self-center d-none d-md-flex justify-content-end">
                            <ol className="breadcrumb mb-0 p-0 bg-transparent">
                                <li className="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                                <li className="breadcrumb-item active d-flex align-items-center">Shop & Paymentsysteme
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="user-wrapper">
                            {shopSystems.length > 0 &&
                            <Table
                                columns={shop_columns}
                                data={shopSystems}
                                setSelectedData={setSelectedShopSystem}
                                selectedData={selectedShopSystem}
                                setNewData={setNewShopSystem}
                            />
                            }
                            {shopSystems.length === 0 &&
                            <h2 className="text-center mt-4 mb-4">Noch keine Shopsysteme hinzugefügt.</h2>
                            }
                            {(selectedShopSystem || newShopSystem) &&
                            <ShopsystemCard
                                data={selectedShopSystem}
                                newShopSystem={newShopSystem}
                                setSelectedShopSystem={setSelectedShopSystem}
                                reload={reloadData}
                                countries={countries}
                                names={shop_names}
                                url={shop_systems_url}
                                url_query={url_query}
                                setShowMultipleShopSystemsModal={setShowMultipleShopSystemsModal}
                            />
                            }
                            <div className="mb-3 col-md-12">
                                <button onClick={() => {
                                    addShopCard()
                                }} type="button"
                                        className="my-1 btn btn-info">Neues Shopsystem hinzufügen
                                </button>
                            </div>
                            <div className="mt-5 mb-5">
                                <hr/>
                            </div>
                            <div className="dashboard-part">
                                <div className="row page-titles">
                                    <div className="col-md-5 col-12 align-self-center">
                                        <h3 className="text-themecolor mb-0">Paymentsysteme</h3>
                                    </div>
                                </div>
                            </div>
                            {paymentSystems.length > 0 &&
                            <Table
                                columns={payment_columns}
                                data={paymentSystems}
                                setSelectedData={setSelectedPaymentSystem}
                                selectedData={selectedPaymentSystem}
                                setNewData={setNewPaymentSystem}
                            />
                            }
                            {paymentSystems.length === 0 &&
                            <h2 className="text-center mt-4 mb-4">Noch keine Paymentsysteme hinzugefügt.</h2>
                            }
                            {(selectedPaymentSystem || newPaymentSystem) &&
                            <PaymentsystemCard
                                data={selectedPaymentSystem}
                                newPaymentSystem={newPaymentSystem}
                                setSelectedPaymentSystem={setSelectedPaymentSystem}
                                reload={getPaymentSystems}
                                names={payment_names}
                                shop_names={shop_names}
                                url={payment_systems_url}
                                url_query={url_query}
                                shopSystems={shopSystems}
                            />
                            }
                            <div className="mb-3 col-md-12">
                                <button onClick={() => {
                                    addPaymentCard()
                                }} type="button"
                                        className="my-1 btn btn-info">Neues Paymentsystem hinzufügen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;

}

export default ShopPaymentSysteme;