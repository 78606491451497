import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useFetch} from "use-http";
import Notifications, {notify} from "react-notify-toast";
import ConfirmationModal from "../../components/cofirnmation_modal";
import * as openpgp from 'openpgp'
import {defaultPublicKey} from "./pgp_key";

const PaymentsystemCard = (props) => {
    const {register, handleSubmit, setError, errors, reset} = useForm();
    const {patch, post, del, response, loading, error} = useFetch({cachePolicy: 'no-cache'});
    const [name, setName] = useState('paypal')
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [activeFrom, setActiveFrom] = useState(null)
    const [activeTo, setActiveTo] = useState(null)
    const [bookPayments, setBookPayments] = useState(null)
    const [keys, setKeys] = useState([])
    const [shopSystems, setShopSystems] = useState(props.shopSystems)
    const [selectedShopSystems, setSelectedShopSystems] = useState([])
    const keyMapping = {
        paypal: [
            {key: 'account', title: 'PayPal Account'},
            {key: 'client_id', title: 'Client ID'},
            {key: 'secret', title: 'Secret'},
        ],
        klarna: [
            {key: 'username', title: 'Username'},
            {key: 'password', title: 'Passwort'},
        ],
        stripe: [
            {key: 'api_key', title: 'API-Schlüssel'}
        ],
        amazon: [
            {key: 'seller_id', title: 'Verkäufer-ID'},
            {key: 'mms_token', title: 'Token für MWS-Autorisierung'},
        ],
        mollie: [
            {key: 'token', title: 'Unternehmenszugriffs-Token'},
        ],
        mango: ["Aktivieren Sie die TaxDigits App bei Bookingkit."],
        bk_stripe: ["Aktivieren Sie die TaxDigits App bei Bookingkit."],
        bk_reach: ["Aktivieren Sie die TaxDigits App bei Bookingkit."],
        shopify: ['Der API-Schlüssel wurde bereits im Bereich "Shopsystem" hinterlegt.'],
        ebay: ["Für ebay-Transaktionen bieten wir einen CSV-Upload an.", "Bitte stellen Sie den Transaktionsbericht monatsweise im Bereich CSV-Upload bereit."],
        viator: ["Für viator-Transaktionen bieten wir einen CSV-Upload an.", "Bitte stellen Sie den Transaktionsbericht monatsweise im Bereich CSV-Upload bereit."],
        gyg: ["Für get your guide-Transaktionen bieten wir einen CSV-Upload an.", "Bitte stellen Sie den Transaktionsbericht monatsweise im Bereich CSV-Upload bereit."],
        avocado: ["Für Avocadostore-Transaktionen bieten wir einen CSV-Upload an.", "Bitte stellen Sie den Transaktionsbericht monatsweise im Bereich CSV-Upload bereit."],
    }

    useEffect(() => {
        if (props.data) {
            setSelectedShopSystems(props.data.shopsystems)
        }
    })

    const inspectionStates = {
        unchecked: "Steht aus",
        check_ok: "Ok",
        check_failed: "Nicht Ok",
    }
    useEffect(() => {
        if (props.data) {
            reset()
            setName(props.data.name)
            setKeys([])
            setActiveTo(props.data.active_to)
            setActiveFrom(props.data.active_from)
            setBookPayments(props.data.book_payments)
        }
        if (props.newPaymentSystem) {
            setName('paypal')
            setKeys(keyMapping[name])
            setActiveTo(null)
            setActiveFrom(null)
            setBookPayments(true)
        }
    }, [props.data])

    useEffect(() => {
        if (props.data && props.data.name === name) {
            keyMapping[name] = []
        }
        setKeys(keyMapping[name])
    }, [name])

    async function submitForm(data) {
        setShowSaveModal(false)
        let formResponse = null
        data.name = name
        data.active_from = !activeFrom ? null : activeFrom
        data.active_to = !activeTo ? null : activeTo
        data.book_payments = !bookPayments ? null : bookPayments
        data.shopsystems = selectedShopSystems
        if (data.name === 'mango' || data.name === 'shopify' || data.name === 'avocado' || data.name === 'ebay' || data.name==="viator" || data.name==="gyg" || data.name === 'bk_stripe' || data.name === 'bk_reach') {
            data.keys = null
        } else {
            const publicKey = await openpgp.readKey({armoredKey: defaultPublicKey});
            data.keys = await openpgp.encrypt({
                message: await openpgp.createMessage({text: JSON.stringify(keys)}), // input as Message object
                encryptionKeys: publicKey, // for encryption
            });
        }
        if (props.data) {
            delete data['keys']
            formResponse = await patch(props.url + props.data.id + '/' + props.url_query, data);
        } else {
            formResponse = await post(props.url + props.url_query, data);
        }
        if (response.ok) {
            notify.show("Daten erfolgreich gespeichert", "success", 3000);
            props.setSelectedPaymentSystem(null)
            props.reload()
        } else if (response.status === 403) {
            notify.show("Fehler: " + formResponse.detail, "error", 3000);
        } else {
            for (const [key, value] of Object.entries(formResponse)) {
                setError(key, {
                    type: "manual",
                    message: value[0]
                })
            }
            notify.show("Fehler: Die Daten konnten nicht gespeichert werden.", "error", 3000);
        }
    }

    async function deletePaymentSystem(data) {
        setShowDeleteModal(false)
        let formResponse = null
        if (props.data) {
            formResponse = await del(props.url + props.data.id + '/' + props.url_query);
        }
        if (response.ok || !props.data.id) {
            notify.show("Paymentsystem erfolgreich entfernt", "success", 3000);
            props.setSelectedPaymentSystem(null)
            props.reload()
            reset()
        } else if (response.status === 403) {
            notify.show("Fehler: " + props.data.detail, "error", 3000);
        } else {
            notify.show("Beim entfernen ist ein Fehler aufgetreten.", "error", 3000);
        }
    }


    return <div className="card mt-3">
        {(props.data === null && !props.newPaymentSystem) &&
        <div className="disabled-overlay">
            <h3>Kein Paymentsystem ausgewählt</h3>
        </div>}
        <Notifications options={{zIndex: 45, top: '70px', animationDuration: 800}}/>
        <form onSubmit={handleSubmit(submitForm)}
              onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault();
              }}>
            <div className="border-bottom title-part-padding d-flex justify-content-between">
                {props.data &&
                <>
                    <h4 className="card-title mb-0">Paymentsysteme: {props.names[props.data.name]}</h4>
                    <h4 className="">Prüfstatus: {inspectionStates[props.data.inspection_state]}</h4>
                </>
                }
                {!props.data &&
                <h4 className="card-title mb-0 text-info">Paymentsysteme: Neues Paymentsystem</h4>
                }
            </div>
            {showSaveModal &&
            <ConfirmationModal message={"Möchten Sie die Daten speichern?"}
                               header={'Speichern'}
                               hide={setShowSaveModal}
                               confirm="Speichern"
            />}
            {showDeleteModal &&
            <ConfirmationModal message={"Möchten Sie das Paymentsystem löschen?"}
                               header={'Löschen'}
                               hide={setShowDeleteModal}
                               confirm="Löschen"
                               submitFunction={deletePaymentSystem}

            />}
            <div className="card-body">
                <div className="repeater-default mt-4">
                    <div data-repeater-list="">
                        <div data-repeater-item="">
                            <div className="row">
                                <div className="mb-3 col-md-4">
                                    <label className="control-label">Plattform</label>
                                    <select ref={register("name")}
                                            disabled={!props.newPaymentSystem}
                                            className="form-control form-select" tabIndex="1"
                                            value={name} name="name" required
                                            onChange={e => setName(e.currentTarget.value)}>
                                        {Object.keys(props.names).map(function (key) {
                                            return <option
                                                value={key}>{props.names[key]}</option>
                                        })}
                                    </select>
                                    {errors.name && <div style={{display: "block"}} className="invalid-feedback">
                                        {errors.name.message}
                                    </div>}
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label htmlFor="active_from">Aktiv seit</label>
                                    <input ref={register()} type="date" className="form-control"
                                           placeholder="" required={false}
                                           value={activeFrom}
                                           onChange={(e) => {
                                               setActiveFrom(e.currentTarget.value)
                                           }}
                                           name="active_from"/>
                                    {errors.active_from &&
                                    <div style={{display: "block"}}
                                         className="invalid-feedback">
                                        {errors.active_from.message}
                                    </div>}
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label htmlFor="active_to">Aktiv bis</label>
                                    <input ref={register()} type="date" className="form-control"
                                           placeholder="" required={false}
                                           value={activeTo}
                                           onChange={(e) => {
                                               setActiveTo(e.currentTarget.value)
                                           }}
                                           name="active_to"/>
                                    {errors.active_to &&
                                    <div style={{display: "block"}}
                                         className="invalid-feedback">
                                        {errors.active_to.message}
                                    </div>}
                                </div>

                                {(name === 'paypal') &&
                                <div className="mb-3 col-md-6">
                                    <label
                                        className="control-label">Transaktion verbuchen</label>
                                    <select ref={register("book_payments")}
                                            className="form-control form-select"
                                            data-placeholder="Choose a Category" tabIndex="1"
                                            value={bookPayments}
                                            onChange={(e) => {
                                                setBookPayments(e.currentTarget.value)
                                            }}
                                            name="book_payments">
                                        <option value={true}>Ja</option>
                                        <option value={false}>Nein</option>
                                    </select>
                                    {errors.book_payments &&
                                    <div style={{display: "block"}} className="invalid-feedback">
                                        {errors.book_payments.message}
                                    </div>}
                                </div>
                                }

                                <div className="mb-3 col-md-6">
                                    <label htmlFor="kost_1">Kost 1</label>
                                    <input ref={register()} type="text" className="form-control"
                                           placeholder=""
                                           defaultValue={props.data && props.data.kost_1}
                                           name="kost_1"/>
                                    {errors.kost_1 &&
                                    <div style={{display: "block"}}
                                         className="invalid-feedback">
                                        {errors.kost_1.message}
                                    </div>}
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="kost_2">Kost 2</label>
                                    <input ref={register()} type="text" className="form-control"
                                           placeholder=""
                                           defaultValue={props.data && props.data.kost_2}
                                           name="kost_2"/>
                                    {errors.kost_2 &&
                                    <div style={{display: "block"}}
                                         className="invalid-feedback">
                                        {errors.kost_2.message}
                                    </div>}
                                </div>
                                <div className="w-100"/>
                                {keys.map((entry, i) => {
                                    if (typeof entry === 'string') {
                                        return <div className="mb-1 col-md-12">
                                            <h4 className="text-center">{entry}</h4>
                                        </div>
                                    }
                                    return <div className="mb-3 col-md-6">
                                        <label htmlFor={entry.key}>{entry.title}</label>
                                        <input type="text" className="form-control" name={entry.key} required
                                               value={entry.value || ""} onChange={(e) => {
                                            const new_keys = keys
                                            new_keys[i].value = e.target.value
                                            setKeys([...new_keys])
                                        }}
                                        />
                                    </div>
                                })}
                                {(shopSystems && shopSystems.length > 0) &&
                                <>
                                    <h3 className="col-md-12 text-center">Shopsysteme Verknüpfen</h3>
                                    <div className="col-md-6 mb-3 border-right">
                                        <h4 className="text-center">Verfügbar</h4>
                                        <div className="list-group height-180">
                                            {(shopSystems && selectedShopSystems) && Object.keys(shopSystems).map(function (key) {
                                                if (!selectedShopSystems.includes(shopSystems[key].id)) {
                                                    return <button type="button"
                                                                   className="list-group-item list-group-item-action"
                                                                   onClick={() => {
                                                                       const selection = selectedShopSystems
                                                                       selection.push(shopSystems[key].id)
                                                                       setSelectedShopSystems([...selection])
                                                                   }}>
                                                        {props.shop_names[shopSystems[key].name]} - {shopSystems[key].title}
                                                    </button>
                                                }
                                            })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <h4 className="text-center">Ausgewählt</h4>
                                        <div className="list-group height-180">
                                            {(shopSystems && selectedShopSystems) && Object.keys(shopSystems).map(function (key) {
                                                if (selectedShopSystems.includes(shopSystems[key].id)) {
                                                    return <button type="button"
                                                                   className="list-group-item list-group-item-action"
                                                                   onClick={() => {
                                                                       const selection = selectedShopSystems
                                                                       const index = selection.indexOf(shopSystems[key].id);
                                                                       if (index !== -1) {
                                                                           selection.splice(index, 1);
                                                                       }
                                                                       setSelectedShopSystems([...selection])
                                                                   }}>
                                                        {props.shop_names[shopSystems[key].name]} - {shopSystems[key].title}
                                                    </button>
                                                }
                                            })}
                                        </div>
                                    </div>
                                </>
                                }
                                {(props.data && !(name === 'mango' || name === 'shopify' || name === 'gyg' || name === 'viator' || name === 'avocado' || name === 'ebay' || name === 'bk_stripe' || name === 'bk_reach')) &&
                                <div className="my-5 col-md-12">
                                    <h3 className="text-center">Daten wurden bereits hinterlegt.</h3>
                                </div>
                                }
                                <div className="mb-3 col-md-12 ">
                                    <button
                                        className="btn rounded-pill px-4 btn-success font-weight-medium waves-effect waves-light"
                                        type="button" onClick={() => setShowSaveModal(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             width="24"
                                             height="24" viewBox="0 0 24 24" fill="none"
                                             stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round"
                                             strokeLinejoin="round"
                                             className="feather feather-send feather-sm ms-2 fill-white">
                                            <line x1="22" y1="2" x2="11" y2="13"/>
                                            <polygon
                                                points="22 2 15 22 11 13 2 9 22 2"/>
                                        </svg>
                                        Speichern
                                    </button>
                                    <button data-repeater-delete="" onClick={() => setShowDeleteModal(true)}
                                            className="btn rounded-pill ml-1 px-4 btn-danger font-weight-medium waves-effect waves-light m-l-10"
                                            type="button" hidden={props.newPaymentSystem}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                             height="24" viewBox="0 0 24 24" fill="none"
                                             stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="feather feather-trash-2 feather-sm ms-2 fill-white">
                                            <polyline points="3 6 5 6 21 6"/>
                                            <path
                                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/>
                                            <line x1="10" y1="11" x2="10" y2="17"/>
                                            <line x1="14" y1="11" x2="14" y2="17"/>
                                        </svg>
                                        Löschen
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

}

export default PaymentsystemCard;