import ConnectedCell from "../kanzleistandard/connected_cell";

export const columns_revenue = [
    {
        Header: 'Erlöse', accessor: 'title', width: 1500, Cell: (cell) => {
            return <span>{cell.value}</span>
        }

    },
    {
        Header: 'Warenbewegung', accessor: 'goods_movement', width: 1500, Cell: (cell) => {
            return <span>{cell.value}</span>
        }

    },
    {
        Header: 'B2B/B2C', accessor: 'business_type', width: 1500, Cell: (cell) => {
            return <span>{cell.value}</span>
        }

    },
    {
        Header: 'OSS', accessor: 'oss', width: 1500, Cell: (cell) => {
            if (cell.value === true) {
                return <span>Ja</span>
            }
            if (cell.value === false) {
                return <span>Nein</span>
            }
            return <span>{cell.value}</span>
        }

    },
    {Header: 'BU Zahlung', accessor: 'bu_key_payments'},
    {Header: 'BU Erlös', accessor: 'bu_key_revenues'},
    {Header: 'SKR', accessor: 'skr'},
]
export const columns_voucher = [
    {
        Header: 'Gutscheine', accessor: 'title', Cell: (cell) => {
            return <span>{cell.value}</span>
        }
    },
    {Header: 'BU Zahlung', accessor: 'bu_key_payments'},
    {Header: 'BU Erlös', accessor: 'bu_key_revenues'},
    {Header: 'SKR', accessor: 'skr'},
]

export const columns_expense = [
    {
        accessor: 'title', Header: 'Amazon-Spezifische Aufwandskonten', Cell: (cell) => {
            return <span>{cell.value}</span>
        }
    },
    {Header: 'BU Zahlung', accessor: 'bu_key_payments'},
    {Header: 'BU Erlös', accessor: 'bu_key_revenues'},
    {Header: 'SKR', accessor: 'skr'},

]

export const columns_inventory = [
    {
        accessor: 'title', Header: 'Vermischter Warenbestand _ Amazon', Cell: (cell) => {
            return <span>{cell.value}</span>
        }
    },
    {Header: 'BU Zahlung', accessor: 'bu_key_payments'},
    {Header: 'BU Erlös', accessor: 'bu_key_revenues'},
    {Header: 'SKR', accessor: 'skr'},

]
export const colums_shipment = [
    {
        accessor: 'title', Header: 'Warenverbringungen (Amazon CEE/PAN-EU)', Cell: (cell) => {
            return <span>{cell.value}</span>
        }
    },
    {Header: 'BU Zahlung', accessor: 'bu_key_payments'},
    {Header: 'BU Erlös', accessor: 'bu_key_revenues'},
    {Header: 'SKR', accessor: 'skr'},

]
