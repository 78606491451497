import {usePagination, useRowSelect, useTable} from 'react-table'
import React, {useContext, useEffect} from 'react'
import {TaxDigitsContext} from "../context";


const IndeterminateCheckbox = React.forwardRef(
    ({indeterminate, ...rest}, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input className="selection-column" type="radio" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

function Table({columns, data, setSelectedData, selectedData, setNewData}) {
    const contextData = useContext(TaxDigitsContext);
    // Use the state and functions returned from useTable to build your UI
    useEffect(() => {
        if (!selectedData) {
            toggleAllRowsSelected(false);
        }
    }, [selectedData])

    const selectData = (row) => {
        setSelectedData(row.original)
    }
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        selectedFlatRows,
        toggleAllRowsSelected,
        toggleRowSelected,
        setPageSize,
    } = useTable(
        {
            columns,
            data,
            autoResetSelectedRows: false,
        },
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({row}) => (
                        <div>
                            <IndeterminateCheckbox
                                {...row.getToggleRowSelectedProps()}
                                onClick={() => {
                                    toggleAllRowsSelected(false);
                                    toggleRowSelected(row.id, true);
                                    setNewData(false)
                                    selectData(row);
                                }}
                            />
                        </div>
                    ),
                },
                ...columns,
            ])
        }
    )

    useEffect(() => {
        toggleAllRowsSelected(false);
        setPageSize(data.length)
    }, [data])

    // Render the UI for your table
    return (
        <div className="user-table">
            <table {...getTableProps()} className='table-bordered table-striped table table-hover'>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody className="user-table-body" {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}

export default Table