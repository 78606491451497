import React, {useContext} from "react";
import {NavLink} from "react-router-dom";
import {TaxDigitsContext} from "../context";

const TaxConsultantSidebar = (props) => {
    const userContext = props.userContext
    if (userContext.profileUser.is_tax_user) {
        return <div>
            <li key="Meine Kanzlei" className="nav-small-cap">
                <i className="mdi mdi-dots-horizontal"/>
                <span className="hide-menu">Meine Kanzlei</span>
            </li>
            <li key="Kanzleistammdaten" className="sidebar-item">
                <NavLink className="sidebar-link waves-effect waves-dark sidebar-link" activeClassName="active"
                         to="/kanzleistammdaten">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                         strokeLinejoin="round" className="feather feather-feather feather-icon">
                        <path d="M20.24 12.24a6 6 0 0 0-8.49-8.49L5 10.5V19h8.5z"/>
                        <line x1="16" y1="8" x2="2" y2="22"/>
                        <line x1="17.5" y1="15" x2="9" y2="15"/>
                    </svg>
                    <span className="hide-menu">Kanzleistammdaten</span>
                </NavLink>
            </li>
            {(userContext.profileUser.is_tax_user === true && userContext.profileUser.approved === true) &&
            <div>
                {userContext.profileUser.permissions === 'admin' &&
                <li key="Benutzerverwaltung" className="sidebar-item">
                    <NavLink className="sidebar-link waves-effect waves-dark sidebar-link" activeClassName="active"
                             to="/benutzerverwaltung">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                             strokeLinejoin="round" className="feather feather-users feather-icon">
                            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"/>
                            <circle cx="9" cy="7" r="4"/>
                            <path d="M23 21v-2a4 4 0 0 0-3-3.87"/>
                            <path d="M16 3.13a4 4 0 0 1 0 7.75"/>
                        </svg>
                        <span className="hide-menu">Benutzerverwaltung</span>
                    </NavLink>
                </li>
                }
                <li key="Kanzleistandard" className="sidebar-item">
                    <NavLink className="sidebar-link waves-effect waves-dark sidebar-link" activeClassName="active"
                             to="/kanzleistandard">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                             strokeLinejoin="round" className="feather feather-pie-chart feather-icon">
                            <path d="M21.21 15.89A10 10 0 1 1 8 2.83"/>
                            <path d="M22 12A10 10 0 0 0 12 2v10z"/>
                        </svg>
                        <span className="hide-menu">Kanzleistandard<br/>DATEV-Schnittstelle</span>
                    </NavLink>
                </li>
            </div>
            }
        </div>
    }
    return <div/>
}
const Sidebar = (props) => {
    const userContext = useContext(TaxDigitsContext);
    return <aside className="left-sidebar" data-sidebarbg="skin5">
        <div className="scroll-sidebar ps-container ps-theme-default ps-active-y sidebar-scroller"
             data-ps-id="8cc07af1-dc9a-5ccd-7cf1-dafa5ed69cae">
            <nav className="sidebar-nav">
                <ul id="sidebarnav" className="in">
                    <TaxConsultantSidebar userContext={userContext}/>
                    {((userContext.profileUser.is_tax_user === true && userContext.profileUser.approved === true && userContext.selectedClient) || userContext.profileUser.is_tax_user === false) &&
                    <div>
                        <li key="Meine Mandanten" className="nav-small-cap"><i className="mdi mdi-dots-horizontal"/>
                            {!userContext.profileUser.is_tax_user &&
                            <span className="hide-menu">Mandanten-Dashboard</span> ||
                            userContext.profileUser.is_tax_user && <span className="hide-menu">Meine Mandanten</span>}
                        </li>
                        <li key="Mandantenstammdaten" className="sidebar-item">
                            <NavLink className="sidebar-link waves-effect waves-dark sidebar-link"
                                     activeClassName="active"
                                     to="/mandantenstammdaten">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round" className="feather feather-book-open feather-icon">
                                    <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"/>
                                    <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"/>
                                </svg>
                                <span className="hide-menu">Mandantenstammdaten</span>
                            </NavLink>
                        </li>
                        {((userContext.profileUser.is_tax_user === false && userContext.profileUser.approved === true || (userContext.profileUser.is_tax_user === true && userContext.profileUser.approved === true))) &&
                        <div>
                            <li key="Paymentsysteme" className="sidebar-item">
                                <NavLink className="sidebar-link waves-effect waves-dark sidebar-link"
                                         activeClassName="active"
                                         to="/shop_payment_systeme">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-lock feather-icon">
                                        <rect x="3" y="11" width="18" height="11" rx="2" ry="2"/>
                                        <path d="M7 11V7a5 5 0 0 1 10 0v4"/>
                                    </svg>
                                    <span className="hide-menu">Shop &<br/> Paymentsysteme</span>
                                </NavLink>
                            </li>
                            {userContext.profileUser.is_tax_user === true &&
                            <li key="Mandant Schnittstelle" className="sidebar-item">
                                <NavLink className="sidebar-link waves-effect waves-dark sidebar-link"
                                         activeClassName="active"
                                         to="/mandant_schnittstelle">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                         viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-user-x feather-icon">
                                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"/>
                                        <circle cx="8.5" cy="7" r="4"/>
                                        <line x1="18" y1="8" x2="23" y2="13"/>
                                        <line x1="23" y1="8" x2="18" y2="13"/>
                                    </svg>
                                    <span className="hide-menu">Mandant<br/>DATEV-Schnittstelle</span>
                                </NavLink>
                            </li>
                            }
                            {false &&
                            <li key="Steuersätze" className="sidebar-item">
                                <NavLink className="sidebar-link waves-effect waves-dark sidebar-link"
                                         activeClassName="active"
                                         to="/artikel_leistungen">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-edit-3 feather-icon">
                                        <path d="M12 20h9"/>
                                        <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"/>
                                    </svg>
                                    <span className="hide-menu">Artikel & Leistungen<br/>Steuersätze</span>
                                </NavLink>
                            </li>
                            }
                            {false &&
                            <li key="EKS" className="sidebar-item">
                                <NavLink className="sidebar-link waves-effect waves-dark sidebar-link"
                                         activeClassName="active"
                                         to="/artikel_eks">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-trello feather-icon">
                                        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"/>
                                        <rect x="7" y="7" width="3" height="9"/>
                                        <rect x="14" y="7" width="3" height="5"/>
                                    </svg>
                                    <span className="hide-menu">Artikel & Leistungen<br/>Einkaufspreise</span>
                                </NavLink>
                            </li>
                            }
                            <li key="CSV" className="sidebar-item">
                                <NavLink className="sidebar-link waves-effect waves-dark sidebar-link"
                                         activeClassName="active"
                                         to="/csv_upload">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-paperclip feather-icon">
                                        <path
                                            d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"/>
                                    </svg>
                                    <span className="hide-menu">CSV-Upload</span>
                                </NavLink>
                            </li>
                            <li key="Umsatzkonten-csv" className="sidebar-item">
                                <NavLink className="sidebar-link waves-effect waves-dark sidebar-link"
                                         activeClassName="active"
                                         to="/umsatzkonten_upload">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-paperclip feather-icon">
                                        <path
                                            d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"/>
                                    </svg>
                                    <span className="hide-menu">Umsatzkonten-Upload</span>
                                </NavLink>
                            </li>
                            <li key="Bank-Converter" className="sidebar-item">
                                <NavLink className="sidebar-link waves-effect waves-dark sidebar-link"
                                         activeClassName="active"
                                         to="/bank_konverter">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-paperclip feather-icon">
                                        <path
                                            d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"/>
                                    </svg>
                                    <span className="hide-menu">Bank-Konverter</span>
                                </NavLink>
                            </li>
                            {userContext.profileUser.is_tax_user === true &&
                            <p>
                                <li key="Download" className="sidebar-item">
                                    <NavLink className="sidebar-link waves-effect waves-dark sidebar-link"
                                             activeClassName="active"
                                             to="/download">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24"
                                             fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                             strokeLinejoin="round" className="feather feather-file-text feather-icon">
                                            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"/>
                                            <polyline points="14 2 14 8 20 8"/>
                                            <line x1="16" y1="13" x2="8" y2="13"/>
                                            <line x1="16" y1="17" x2="8" y2="17"/>
                                            <polyline points="10 9 9 9 8 9"/>
                                        </svg>
                                        <span className="hide-menu">Download</span>
                                    </NavLink>
                                </li>
                                <li key="Rechnungen" className="sidebar-item">
                                    <NavLink className="sidebar-link waves-effect waves-dark sidebar-link"
                                             activeClassName="active"
                                             to="/rechnungen">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24"
                                             fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                             strokeLinejoin="round" className="feather feather-file-text feather-icon">
                                            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"/>
                                            <polyline points="14 2 14 8 20 8"/>
                                            <line x1="16" y1="13" x2="8" y2="13"/>
                                            <line x1="16" y1="17" x2="8" y2="17"/>
                                            <polyline points="10 9 9 9 8 9"/>
                                        </svg>
                                        <span className="hide-menu">Rechnungen</span>
                                    </NavLink>
                                </li>
                            </p>
                            }
                        </div>}
                    </div>
                    }
                </ul>
            </nav>
        </div>
    </aside>;
}

export default Sidebar;