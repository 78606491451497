import React, {useCallback, useState, useEffect, useContext} from "react";
import Table from "../../components/table"
import {notify} from "react-notify-toast";
import {useFetch} from "use-http";
import {TaxDigitsContext} from "../../context";
import jsPDF from "jspdf";
import "jspdf-autotable";

const Rechnungen = (props) => {
    const userContext = useContext(TaxDigitsContext);
    const [billsData, setBillsData] = useState(null)
    const {get, response} = useFetch({cachePolicy: 'no-cache'}, opts => {
        delete opts.headers['Content-Type']
        return opts
    });
    const [searchValue, setSearchValue] = useState('')
    const [activeSearch, setActiveSearch] = useState('')
    let base_query = ''
    if (userContext.selectedClient) {
        base_query = '?company_id=' + userContext.selectedClient.id
    }


    useEffect(() => {
        getData()
    }, [])


    const getData = useCallback(async (searchQuery = '') => {
        const initialData = await get('/api/v1/taxdigits/company_bills/' + base_query + searchQuery);
        if (response.ok) {
            setBillsData(initialData)
        } else if (response.status === 403) {
            notify.show("Fehler: " + initialData.detail, "error", 3000);
        }
    }, [get, response])


    const downloadFile = async (id, name) => {
        const file = await get('/api/v1/taxdigits/company_bills/' + id + '/' + base_query)
        if (response.ok) {
            const blob = await response.blob()
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name)
            document.body.appendChild(link);
            link.click();
        } else {
            notify.show("Beim download ist ein Fehler aufgetreten.", "error", 30000000);
        }
    }

    const exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);
        const date = new Date()
        const title = `Rechnungen Übersicht ${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
        const headers = [["Kunde", "Rechnungsnummer", "Rechnungsdatum", "Betrag", "Gesendet an", "Gesendet am"]];

        const data = billsData.map(data => [
            data.customer,
            data.number,
            date_converter(data.date),
            data.amount,
            data.sent_to,
            date_converter(data.sent_on)
        ]);

        let content = {
            startY: 90,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        if (activeSearch) {
            doc.text('Aktiver Suchbegriff: "' + activeSearch + '"', marginLeft, 80);
        }
        doc.autoTable(content);
        doc.save(`Rechnungen_${date.getDate()}_${date.getMonth() + 1}_${date.getFullYear()}.pdf`)
    }

    const date_converter = (value) => {
        let date = new Date(value)
        const day = ('0' + (date.getDate())).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        return `${day}.${month}.${date.getFullYear()}`
    }

    const date_cell = (cell) => {
        let date = new Date(cell.value)
        const day = ('0' + (date.getDate())).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        return <span>{day}.{month}.{date.getFullYear()}</span>
    }

    const download_cell = (cell) => {
        return <button
            onClick={() => {
                const file_name = cell.row.original.file
                downloadFile(cell.row.values['id'], file_name)
            }}
            className={'cell-btn btn btn-primary btn-sm'}>
            Download
        </button>
    }

    const handleSearch = (e) => {
        e.preventDefault()
        setActiveSearch(searchValue)
        if (searchValue) {
            if (base_query !== '') {
                return getData('&search=' + searchValue)
            }
            return getData('?search=' + searchValue)
        }
        return getData('')
    }

    const columns = [
        {
            Header: 'Kunde', accessor: 'customer', Cell: (cell) => {
                return <span>{cell.value}</span>
            }
        },
        {
            Header: 'Rechnungsnummer', accessor: 'number', Cell: (cell) => {
                return <span>{cell.value}</span>
            }
        },
        {Header: 'Rechnungsdatum', accessor: 'date', Cell: date_cell},
        {
            Header: 'Betrag', accessor: 'amount', Cell: (cell) => {
                return <span>{cell.value}</span>
            }
        },
        {
            Header: 'Gesendet an', accessor: 'sent_to', Cell: (cell) => {
                return <span>{cell.value}</span>
            }
        },
        {Header: 'Gesendet am', accessor: 'sent_on', Cell: date_cell},
        {
            Header: 'Download', accessor: 'id', Cell: download_cell
        },
    ]
    return <div className="page-wrapper" style={{display: "block"}}>
        <div className="container-fluid">
            <div className="reverse-mode">
                <div className="dashboard-part" style={{height: "100%"}}>
                    <div className="row page-titles">
                        <div className="col-md-5 col-12 align-self-center">
                            <h3 className="text-themecolor mb-0">Rechnungen</h3>
                        </div>
                        <div className="col-md-7 col-12 align-self-center d-none d-md-flex justify-content-end">
                            <ol className="breadcrumb mb-0 p-0 bg-transparent">
                                <li className="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                                <li className="breadcrumb-item active d-flex align-items-center">Rechnungen</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <form onSubmit={handleSearch}>
                                        <div className="input-group mb-0">
                                            <input type="text" className="form-control" placeholder="Suche..."
                                                   name="search-input" id="search-input" aria-label=""
                                                   aria-describedby="basic-addon1" value={searchValue} onChange={
                                                (e) => {
                                                    setSearchValue(e.target.value)
                                                }
                                            }/>
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="button">Suche
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    {activeSearch &&
                                    <div className="mt-0">
                                        Aktiver Suchbegriff: "{activeSearch}"
                                    </div>
                                    }
                                    <div className="repeater-default mt-4">
                                        {billsData !== null &&
                                        <div data-repeater-list="">
                                            <div data-repeater-item="">
                                                <div className="row">
                                                    <div className="col-sm-12 table-responsive">
                                                        <Table
                                                            columns={columns}
                                                            data={billsData}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    <div>
                                        <button className="btn btn-primary" onClick={() => exportPDF()}>Generiere
                                            Übersicht als PDF
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        ;
}

export default Rechnungen;