import React, {useCallback, useState, useEffect, useContext} from "react";
import Table from "../../components/table"
import {notify} from "react-notify-toast";
import {useFetch} from "use-http";
import {useForm} from "react-hook-form";
import ConfirmationModal from "../../components/cofirnmation_modal";
import {TaxDigitsContext} from "../../context";

const UmsatzkontenUpload = (props) => {
    const {register, handleSubmit, watch, reset, errors, clearErrors} = useForm();
    const userContext = useContext(TaxDigitsContext);
    const [file_data, setFileData] = useState(null)
    const [sales_data, setSalesData] = useState(null)
    const [addFileData, setAddFileData] = useState(null)
    const [addFileId, setAddFileId] = useState()
    const {post, put, del, get, response, loading, error} = useFetch({cachePolicy: 'no-cache'}, opts => {
        delete opts.headers['Content-Type']
        return opts
    });
    const [name, setName] = useState('ebay')
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showFileModal, setShowFileModal] = useState(false)
    const [file, setFile] = useState(null)
    const [deleteId, setDeleteId] = useState()
    const defaultInputText = '(Optional) Klicke hier um eine Datei auszuwählen.'
    const [addInputText, setAddInputText] = useState(defaultInputText)
    const [fileInputText, setFileInputText] = useState(defaultInputText)
    const [fileType, setFileType] = useState('.csv')
    let query = ''
    if (userContext.selectedClient) {
        query = '?company_id=' + userContext.selectedClient.id
    }


    useEffect(() => {
        getData()
        getDataSales()
    }, [])


    const getData = useCallback(async (name = null) => {
        const initialData = await get('/api/v1/taxdigits/salesaccount_files/'+query);
        if (response.ok) {
            setFileData(initialData)
        } else if (response.status === 403) {
            notify.show("Fehler: " + initialData.detail, "error", 3000);
        }
    }, [get, response])

    const getDataSales = useCallback(async (name = null) => {
        const initialData = await get('/api/v1/taxdigits/salesaccount_data/'+query);
        if (response.ok) {
            setSalesData(initialData)
        } else if (response.status === 403) {
            notify.show("Fehler: " + initialData.detail, "error", 3000);
        }
    }, [get, response])

    async function submitForm(data) {
        setShowSaveModal(false)
        const upload_data = new FormData()
        if (file) {
            upload_data.append('file', file)
        }
        upload_data.append('name', name)
        const form_response = await post('/api/v1/taxdigits/salesaccount_files/' + query, upload_data);
        if (response.ok) {
            reset()
            setFile(null)
            setFileInputText(defaultInputText)
            getData()
            getDataSales()
            notify.show("Daten erfolgreich gespeichert", "success", 3000);
        } else if (response.status === 400) {
            notify.show(form_response, "error", 3000);
        }
    }

    const deleteData = async (id) => {
        setShowDeleteModal(false)
        const deleteData = await del('/api/v1/taxdigits/salesaccount_files/' + id + '/' + query)
        if (response.ok) {
            getData()
            getDataSales()
            notify.show("Daten erfolgreich entfernt.", "success", 3000);
        } else {
            notify.show("Beim löschen ist ein Fehler aufgetreten.", "error", 3000);
        }
    }

    const downloadFile = async (id, name) => {
        const file = await get('/api/v1/taxdigits/salesaccount_files/' + id + '/' + query)
        if (response.ok) {
            //workaround for ajax download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name)
            document.body.appendChild(link);
            link.click();
        } else {
            notify.show("Beim download ist ein Fehler aufgetreten.", "error", 30000000);
        }
    }
    const addFile = async () => {
        if (!addFileData) {
            notify.show("Keine Datei ausgewählt.", "error", 3000);
            return
        }
        const upload_data = new FormData()
        upload_data.append('file', addFileData)
        const form_response = await put('/api/v1/taxdigits/salesaccount_files/' + addFileId + '/' + query, upload_data);
        if (response.ok) {
            setAddFileData(null)
            setAddInputText(defaultInputText)
            getData()
            getDataSales()
            setShowFileModal(false)
            notify.show("Datei erfolgreich hinzugefügt.", "success", 3000);
        } else {
            notify.show(form_response, "error", 3000);
        }
    }

    const date_time_cell2 = (cell) => {
        let date = new Date(cell.value)
        const day = ('0' + (date.getDate())).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const hours = ('0' + (date.getHours())).slice(-2);
        const minutes = ('0' + (date.getMinutes())).slice(-2);
        return <span>{day}.{month}.{date.getFullYear()} - {hours}:{minutes}</span>
    }
    const delete_cell = (cell) => {
        return <button onClick={() => {
            setShowDeleteModal(true)
            setDeleteId(cell.row.values['id'])
        }} className='btn btn-danger btn-sm'>Eintrag löschen</button>
    }

    const download_cell = (cell) => {
        return <button
            onClick={() => {
                downloadFile(cell.row.values['id'], cell.row.values['file_name'])
            }} className='cell-btn btn btn-secondary btn-sm'>Datei downloaden
        </button>
    }


    const columns = [
        {
            Header: 'Dateiname', accessor: 'file_name', Cell: (cell) => {
                if (cell.value) {
                    return <span>{cell.row.values['file_name']}</span>
                }
                return <span>Fehlt</span>
            }
        },

        {Header: 'Letzte Änderung', accessor: 'created_date', Cell: date_time_cell2},
        {
            Header: 'Download', accessor: 'id', Cell: download_cell
        },
        {
            Header: 'Löschen', accessor: 'state', Cell: delete_cell
        },
    ]
    const columns_sales = [
        {
            Header: 'Title', accessor: 'title', Cell: (cell) => {
                if (cell.value) {
                    return <span>{cell.row.values['title']}</span>
                }
                return <span>Fehlt</span>
            }
        },

        {
            Header: 'Article', accessor: 'article', Cell: (cell) => {
                if (cell.value) {
                    return <span>{cell.row.values['article']}</span>
                }
                return <span>Fehlt</span>
            }
        },
        {
            Header: 'Umsatz 19%', accessor: 'sales_tax_19', Cell: (cell) => {
                if (cell.value) {
                    return <span>{cell.value}</span>
                }
                return <span>Fehlt</span>
            }
        },
        {
            Header: 'Umsatz 7%', accessor: 'sales_tax_7', Cell: (cell) => {
                if (cell.value) {
                    return <span>{cell.value}</span>
                }
                return <span>Fehlt</span>
            }
        },
        {
            Header: 'Umsatz 0%', accessor: 'sales_tax_0', Cell: (cell) => {
                if (cell.value) {
                    return <span>{cell.value}</span>
                }
                return <span>Fehlt</span>
            }
        },
        {
            Header: 'Kost 1', accessor: 'cost1', Cell: (cell) => {
                if (cell.value) {
                    return <span>{cell.value}</span>
                }
                return <span>Fehlt</span>
            }
        },
        {
            Header: 'Kost 2', accessor: 'cost2', Cell: (cell) => {
                if (cell.value) {
                    return <span>{cell.value}</span>
                }
                return <span>Fehlt</span>
            }
        },


    ]

    return <div className="page-wrapper" style={{display: "block"}}>
        {showDeleteModal &&
        <ConfirmationModal message={"Möchten Sie den Eintrag wirklich löschen?"}
                           header={'Löschen'}
                           hide={setShowDeleteModal}
                           confirm="Löschen"
                           submitFunction={deleteData}
                           data={deleteId}

        />}
        <div className="container-fluid">
            {showFileModal &&
            <div className="file-modal confirmation-modal modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header d-flex align-items-center">
                        <h4 className="modal-title" id="myLargeModalLabel">Datei hinzufügen</h4>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="input-group col-md-12 mb-3">
                                <div className="custom-file">
                                    <input ref={register()} name="upload_file" type="file"
                                           className="custom-file-input" accept={fileType}
                                           onChange={(e) => {
                                               setAddInputText(e.target.files[0].name)
                                               setAddFileData(e.target.files[0])
                                           }}
                                    />
                                    <label className="custom-file-label">
                                        {addInputText}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button"
                                className="btn-modal btn btn-success ml-3 text font-weight-medium waves-effect text-start"
                                data-bs-dismiss="modal" onClick={addFile}>Speichern
                        </button>
                        <button type="button"
                                className="btn-modal btn btn-danger ml-3 text font-weight-medium waves-effect text-start"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setAddFileData(null)
                                    setAddInputText(defaultInputText)
                                    setShowFileModal(false)
                                }}
                        >Abbrechen
                        </button>
                    </div>
                </div>
            </div>
            }
            <div className="reverse-mode">
                <div className="dashboard-part" style={{height: "100%"}}>
                    <div className="row page-titles">
                        <div className="col-md-5 col-12 align-self-center">
                            <h3 className="text-themecolor mb-0">Umsatzkonten-Upload</h3>
                        </div>
                        <div className="col-md-7 col-12 align-self-center d-none d-md-flex justify-content-end">
                            <ol className="breadcrumb mb-0 p-0 bg-transparent">
                                <li className="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                                <li className="breadcrumb-item active d-flex align-items-center">Umsatzkonten-Upload</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h3>Upload:</h3>
                                    <hr/>
                                    <form onSubmit={handleSubmit(submitForm)}
                                              onKeyPress={(e) => {
                                                  e.key === 'Enter' && e.preventDefault();
                                              }}>
                                            {showSaveModal &&
                                            <ConfirmationModal message={"Möchten Sie die Daten speichern?"}
                                                               header={'Speichern'}
                                                               hide={setShowSaveModal}
                                                               confirm="Speichern"

                                            />}
                                            <div className="row">
                                                <div className="input-group col-md-12 mb-3">
                                                    <div className="custom-file">
                                                        <input ref={register()} name="upload_file" type="file"
                                                               className="custom-file-input" accept={fileType}
                                                               onChange={(e) => {
                                                                   if (e.target.files[0]) {
                                                                       setFile(e.target.files[0])
                                                                       setFileInputText(e.target.files[0].name)
                                                                   }
                                                               }}
                                                        />
                                                        <label className="custom-file-label">
                                                            {fileInputText}
                                                        </label>
                                                    </div>
                                                    <div className="input-group-append">
                                                        <button className="btn btn-secondary"
                                                                type="button"
                                                                hidden={file === null}
                                                                onClick={(e) => {
                                                                    setFile(null)
                                                                    setFileInputText(defaultInputText)
                                                                }}>Entfernen
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <button type="button"
                                                            className="btn rounded-pill px-4 btn-success font-weight-medium waves-effect waves-light"
                                                            onClick={(e) => {
                                                                setShowSaveModal(true)
                                                            }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                             height="24" viewBox="0 0 24 24" fill="none"
                                                             stroke="currentColor" strokeWidth="2"
                                                             strokeLinecap="round" strokeLinejoin="round"
                                                             className="feather feather-send feather-sm ms-2 fill-white">
                                                            <line x1="22" y1="2" x2="11" y2="13"/>
                                                            <polygon points="22 2 15 22 11 13 2 9 22 2"/>
                                                        </svg>
                                                        Speichern
                                                    </button>
                                                </div>
                                            </div>
                                    </form>
                                    <br/>
                                    <h3>Datei:</h3>
                                    <div className="repeater-default mt-4">
                                        {file_data !== null &&
                                        <div data-repeater-list="">
                                            <div data-repeater-item="">
                                                <div className="row">
                                                    <div className="col-sm-12 table-responsive">
                                                        <Table
                                                            columns={columns}
                                                            data={file_data}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    <br/>
                                    <h3>Daten:</h3>
                                    <div className="repeater-default mt-4">
                                        {sales_data !== null &&
                                        <div data-repeater-list="">
                                            <div data-repeater-item="">
                                                <div className="row">
                                                    <div className="col-sm-12 table-responsive">
                                                        <Table
                                                            columns={columns_sales}
                                                            data={sales_data}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        ;
}

export default UmsatzkontenUpload;