import React, {useCallback, useEffect, useState} from "react"
import UserCard from './user_card'
import {useFetch} from "use-http";
import Table from "../../components/selection_table";
import {notify} from "react-notify-toast";

const Benutzerverwaltung = (props) => {
    const [selectedUser, setSelectedUser] = useState(null)
    const [userData, setUserData] = useState([]);
    const {get, response, loading, error} = useFetch({cachePolicy: 'no-cache'});
    const [newUser, setNewUser] = useState(false)
    const profileUser = JSON.parse(localStorage.getItem("profileUser"))

    useEffect(() => {
        getInitialData()
    }, [])
    const permissionOptions = () => {
    }
    const permissionsCell = (value) => {
        const permissions = {
            admin: "Admin",
            settings: "Einstellungen",
            read: "Ansicht und Datenabruf",
        }
        return permissions[value.value]
    }
    const columns = [
        {Header: 'Benutzername', accessor: 'username'},
        {Header: 'Anrede', accessor: 'salutation'},
        {Header: 'Vorname', accessor: 'first_name'},
        {Header: 'Nachname', accessor: 'last_name'},
        {Header: 'E-Mail', accessor: 'email'},
        {Header: 'Rechte', accessor: 'permissions', Cell: permissionsCell},
    ]

    const getInitialData = useCallback(async () => {
        setNewUser(false)
        const initialData = await get('/api/v1/taxdigits/tax_user/');
        if (response.ok) {
            setUserData(initialData)
        } else if (response.status === 403) {
            notify.show("Fehler: " + initialData.detail, "error", 3000);
        }
    }, [get, response])

    const addUser = () => {
        setSelectedUser(null)
        setNewUser(true)
    }

    return <div className="page-wrapper" style={{display: "block"}}>
        <div className="container-fluid">
            <div className="reverse-mode">
                <div className="dashboard-part" style={{height: "100%"}}>
                    <div className="row page-titles">
                        <div className="col-md-5 col-12 align-self-center">
                            <h3 className="text-themecolor mb-0">Benutzerverwaltung</h3>
                        </div>
                        <div className="col-md-7 col-12 align-self-center d-none d-md-flex justify-content-end">
                            <ol className="breadcrumb mb-0 p-0 bg-transparent">
                                <li className="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                                <li className="breadcrumb-item active d-flex align-items-center">Benutzerverwaltung</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" id="user-wrapper">
                            {userData.length > 0 &&
                            <Table
                                columns={columns}
                                data={userData}
                                setSelectedData={setSelectedUser}
                                selectedData={selectedUser}
                                setNewData={setNewUser}
                            />
                            }
                            <div className="mb-3 mt-3 col-md-12">
                                <button onClick={() => {
                                    addUser()
                                }} type="button"
                                        className="mr-2 my-1 btn btn-info">Neuen Benutzer hinzufügen
                                </button>
                            </div>
                            {(selectedUser || newUser) &&
                            <UserCard
                                data={selectedUser}
                                newUser={newUser}
                                setSelectedUser={setSelectedUser}
                                reload={getInitialData}
                                profileUser={profileUser}
                            />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;

}

export default Benutzerverwaltung;