import React, {useEffect, useContext, useState} from "react"
import logo from '../../images/taxdigits_logo_web.png';
import {useForm} from "react-hook-form";
import {useFetch} from "use-http";
import {TaxDigitsContext} from "../../context";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";

const LoginPage = (props) => {
    const {register, handleSubmit, setError, errors} = useForm();
    let SUCCESS_REDIRECT_URL = "/kanzleistammdaten";
    const history = useHistory();
    const userContext = useContext(TaxDigitsContext);
    const {post, response, loading, error} = useFetch({cachePolicy: 'no-cache'});
    const bookitQuery = localStorage.getItem("bookitQuery")

    async function onLoginSubmit(data) {
        if (bookitQuery) {
            data.bookit_key = bookitQuery.replace("?bookit=", "")
        }
        const login = await post('/api/v1/accounts/login/', data);
        if (response.ok) {
            localStorage.setItem('profileUser', JSON.stringify(login));
            SUCCESS_REDIRECT_URL = login.is_tax_user ? SUCCESS_REDIRECT_URL : '/mandantenstammdaten'
            if(bookitQuery && login.is_tax_user){
                SUCCESS_REDIRECT_URL = '/kanzleistammdaten'
            }
            userContext.setProfileUser(login);
            localStorage.setItem('bookitQuery', null);
            history.push(SUCCESS_REDIRECT_URL);
        } else {
            setError("password", {
                type: "manual",
                message: login.detail
            });
        }
    }


    return <div className="login-background auth-wrapper d-flex no-block justify-content-center align-items-center">
        <div className="auth-box p-4 bg-white rounded">
            <div>
                <div className="logo text-center">
                <span className="db img-responsive">
                    <img src={logo} alt="logo"/>
                </span>
                    <hr/>
                    <h3 className="font-weight-medium mb-3 mt-1">Anmelden</h3>
                </div>
                <div className="row mt-4">
                    <div className="col-12">
                        <form className="form-horizontal" onSubmit={handleSubmit(onLoginSubmit)}>
                            <div className="form-floating mb-3">
                                <label htmlFor="tb-remail">E-Mail</label>
                                <input type="email" name="username" className="form-control form-input-bg"
                                       id="tb-remail" placeholder=""
                                       required="required" ref={register({required: true})}/>
                                <div className="invalid-feedback">
                                    E-Mail ist ein Pflichtfeld
                                </div>
                            </div>
                            <div className="form-floating mb-3">
                                <label htmlFor="text-rpassword">Passwort</label>
                                <input type="password" name="password" className="form-control form-input-bg"
                                       id="text-rpassword"
                                       placeholder="" required="required" ref={register({required: true})}/>
                                {errors.password && <div style={{display: "block"}} className="invalid-feedback">
                                    {errors.password.message}
                                </div>}
                            </div>
                            <div className="d-flex align-items-stretch">
                                <button type="submit" className="btn btn-info d-block w-100">Anmelden</button>
                            </div>
                            <p className="text-center">
                                Noch kein Kundenkonto bei taxdigits? Klicken Sie <Link to="/register">hier</Link>, um
                                sich zu registrieren.
                            </p>
                            <hr/>
                            <p className="text-center">
                                <Link to="/reset_password">Passwort vergessen?</Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default LoginPage;