import React from "react";

const MulitpleShopSystemsModal = (props) => {

    return <div hidden={props.hidden} className="confirmation-modal modal-dialog modal-dialog-centered">
        <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                <h4 className="modal-title" id="myLargeModalLabel">Shopsysteme des gleichen Typs erkannt</h4>
            </div>
            <div className="modal-body">
                <p className="">
                    Sie haben einen Shop angelegt, dessen Shopsystem bereits bei einem zuvor angelegten Shop verwendet
                    wird. Damit wir die Bestellnummern den einzelnen Shops zuordnen können, ist es erforderlich, dass
                    den Bestellnummern in Ihrem Shopsystem sog. "Präfixe" vorangestellt werden.
                </p>
                <p>Hiermit wird bestätigt, dass Präfixe verwendet werden.</p>
            </div>
            <div className="modal-footer">
                <button type="submit"
                        onClick={() => {
                            props.showModal(false)
                        }}
                        className="btn-modal btn btn-success mr-3  font-weight-medium waves-effect text-start"
                        data-bs-dismiss="modal">Bestätigen
                </button>
            </div>
        </div>
    </div>
}
export default MulitpleShopSystemsModal;