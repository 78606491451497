import React from "react"

const AGB = (props) => {
    return <div className="agb-modal modal-dialog modal-dialog-centered">
        <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                <h3 className="modal-title" id="myLargeModalLabel"> AGB</h3>
            </div>
            <div className="modal-body">
                <h3 className="">AGB</h3>
                <ol className='agb-list'>
                    <li>Geltungsbereich und Anbieter
                        <ol>
                            <li>Die Allgemeinen Geschäftsbedingungen (nachfolgend „AGB" genannt) regeln das
                                Vertragsverhältnis zwischen der Taxdigits GmbH, Kreuzstr. 11a 4469 Neuss (nachfolgend
                                Anbieter) und Ihnen (nachfolgend Nutzer), in ihrer zum Zeitpunkt des Vertragsabschlusses
                                gültigen Fassung.
                            </li>
                            <li>Abweichende AGB des Nutzer werden zurückgewiesen.</li>
                            <li>Das Angebot richtet sich ausschließlich an Unternehmer und Unternehmen gem § 14 BGB mit
                                Sitz in der EU.
                            </li>
                            <li>Der Anbieter beählt es sich vor, die AGB zukünftig einseitig, aufgrund zusätzlicher
                                Funktionen
                                der Anwendung zu ergänzen bzw. anzupassen. Der Nutzer wird darüber per E-Mail
                                informiert.
                                Die geänderten AGB gelten als vom Nutzer angenommen, wenn dieser nicht innerhalb einer
                                Frist von 14 Tagen nach Bekanntwerden der AGB-Änderung widerspricht.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Zustandekommen des Vertrages
                        <ol>
                            <li>Die Nutzung der Leistung des Anbieters setzt eine Anmeldung des Nutzers voraus.</li>
                            <li>Mit der Anmeldung erkennt der Nutzer die vorliegenden AGB an. Mit der Anmeldung entsteht
                                ein Vertragsverhältnis zwischen dem Anbieter und dem angemeldeten Nutzer, das sich nach
                                den Regelungen dieser AGB richtet.
                            </li>
                            <li>Die Präsentation der Dienstleistung auf der Website stellt kein rechtlich wirksames
                                Angebot
                                dar. Durch die Präsentation der Dienstleistung wird der Nutzer lediglich dazu
                                aufgefordert ein
                                Angebot zu machen.
                            </li>
                            <li>Mit Bestellung eines kostenpflichtigen Dienstes geht der angemeldete Nutzer ein
                                weiteres,
                                von der Anmeldung getrenntes Vertragsverhältnis mit dem Anbieter ein. Der Nutzer wird
                                vor
                                Abschluss dieses Vertragsverhältnisses über den jeweiligen kostenpflichtigen Dienst und
                                die
                                Zahlungsbedingungen informiert. Das Vertragsverhältnis entsteht indem der Nutzer die
                                Bestellung und Zahlungsverpflichtung durch das Anklicken des Buttons „zahlungspflichtig
                                bestellen" bestätigt.
                            </li>
                            <li>Der Nutzer stimmt zu, dass Sie Rechnungen elektronisch erhält. Elektronische Rechnungen
                                werden per E-Mail oder in dem Nutzerkonto der Webseite zur Verfügung gestellt.
                            </li>
                        </ol>
                    </li>
                    <li> Beschreibung des Leistungsumfanges
                        <ol>
                            <li>Der Anbieter stellt dem Nutzer eine über das Internet erreichbare Online-Anwendung
                                (SaaS) <a href="https://taxdigits.de">https://taxdigits.de</a> bereit.
                            </li>
                            <li>Als Nutzer sind sowohl Mandant (Onlinehändler) und Steuerberater zu verstehen. Den
                                Nutzergruppen (Mandanten und Steuerberater) werden vom Anbieter jeweils individuelle
                                Dashboards zur Verfügung gestellt.
                            </li>
                            <li>Der Anbieter erbringt eine Auftragsdatenverarbeitung. <br/>
                                Über elektronische Schnittstellen zu den auf der Webseite des Anbieters aufgeführten
                                Handelsplattformen, Shopsystemen und Paymentanbietern bzw. per CSV-Upload, können
                                Umsatz, Artikel-, Käufer-, und Zahlungsdaten in taxdigits.de importiert werden.
                            </li>
                            <li>Die aus den Shopsystemen abgerufenen Umsatzdaten werden um die jeweils in der EU
                                geltenden Standard-Umsatz-Steuersätze ergänzt. Sofern reduzierte Umsatz-Steuersätze
                                berücksichtigt werden sollen, müssen diese vom Nutzer in taxdigits.de hinterlegt werden.
                                Hierzu muss der Nutzer zusnächst seine im Shopsystem angelegten Produkte/Leistungen in
                                taxdigits.de importieren. Der Import der Produkte/Leistungen erfolgt per CSV-Upload oder
                                per
                                API-Abruf. Der Nutzer ordnet jeder Artikelnummer den im jeweiligen EU-Land geltenden
                                reduzierten Steuersatz zu. Für Artikelnummern, denen kein Steuersatz zugeordnet wurde,
                                werden automatisch die jeweiligen im EU Land geldenden Standard-Umsatzsteuersätze
                                berücksichtigt. Die Informationen zu den Steuersätzen bezieht der Anbieter über die
                                Website
                                der Europäischen Kommission:
                                https://ec.europa.eu/taxation_customs/tedb/vatSearchForm.html. Für die Richtigkeit
                                dieser
                                Umsatzsteuersätze übernimmt der Anbieter weder Gewähr noch Haftung.
                            </li>
                            <li>
                                Die Daten werden vom Anbieter für folgende Zwecke gespeichert und verarbeitet:
                                <ul>
                                    <li>Würdigung jeder einzelnen Transaktion in der Weise, dass Leistungsort,
                                        Leistungszeitpunkt, USt-Bemessungsgrundlage und Umsatzsteuersatz bestimmt
                                        werden
                                    </li>
                                    <li>Erstellung von Buchungsstapeln im DATEV-Format (Erlösbuchungen und
                                        Zahlungsbuchungen) unter Berücksichtigung der individuellen
                                        Schnittstellenvorgaben
                                        des Nutzers.
                                    </li>
                                    <li>Für ausgewählte Systeme: Erstellung von Rechnungen und Stornorechnungen und
                                        Weiterleitung per E-Mail (eine manuelle Erstellung von Rechnungen und
                                        Stornorechnungen ist nicht möglich). Die Kontrolle über den ordnungsgemäßen
                                        Versand von Rechnungen und Stornorechnungen obliegt dem Nutzer.
                                    </li>
                                    <li>
                                        Erstellung von Verbringungslisten für den innergemeinschaftlichen Warenverkehr
                                        bei Nutzung von Amazon PAN-EU
                                    </li>
                                    <li>Erstellung von Transaktionslisten mit umfangreichen Informationen zu den
                                        einzelnen
                                        Transaktionen
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                    <li>
                        Pflichten des Nutzer
                        <ol>
                            <li>Der Nutzer hat die ihm bzw. den Nutzern zugeordneten Zugangsberechtigungen
                                sowie Identifikations- und Authentifikationsinformationen vor dem Zugriff durch
                                Dritte zu schützen und nicht an Unberechtigte weiterzugeben.
                            </li>
                            <li>Der Nutzer stellt dem Anbieter die zum Abruf der Daten aus den jeweiligen
                                Shop- und Paymentsystemen erforderlichen API-Schlüssel zur Verfügung
                            </li>
                            <li>Sofern die Daten per CSV-Upload bereitgestellt werden, ist dies durch den
                                Nutzer bis zum 3. Werktag des Folgemonats zu durchzuführen.
                            </li>
                            <li> Der Nutzer ist verpflichtet, den Anbieter von sämtlichen Ansprüchen Dritter aufgrund
                                von Rechtsverletzungen freizustellen, die auf einer rechtswidrigen Verwendung des
                                Leistungsgegenstands durch ihn beruhen oder mit seiner Billigung erfolgen. Erkennt
                                der Nutzer oder muss er erkennen, dass ein solcher Verstoß droht besteht die Pflicht
                                zur unverzüglichen Unterrichtung des Anbieters.
                            </li>
                            <li>Dem Nutzer ist bekannt, dass die Leistung des Anbieters nicht darin besteht
                                steuerlichen Aufbewahrungsfristen zu erfüllen. Der Nutzer ist daher verpflichtet in
                                regelmäßigen Abständen mindestens jedoch einmal jährlich den Datenbestand inklusive
                                aller Auswertungen eigenständig in seinem originären Verantwortungsbereich zu
                                sichern.
                            </li>
                        </ol>
                    </li>
                    <li>Gebühren und Kosten
                        <ol>
                            <li>Zur Nutzung von taxdigits.de ist zunächst eine Registrierung notwendig.</li>
                            <li>Um die Dienstleistungen der Website erwerben zu können, muss der Nutzer sich
                                registrieren
                                und ein Benutzerkonto erstellen.
                            </li>
                            <li>Die Nutzung innerhalb des 30 tägigen Testzeitraumes ist kostenlos</li>
                            <li>Sofern der Nutzer nach Ablauf des Testzeitraumes den Dienst in Anspruch nehmen möchte,
                                ist Dienst kostenpflichtig zu bestellen. Auf die Kostenpflicht wird der Nutzer
                                hingewiesen. So
                                werden ihm insbesondere der jeweilige zusätzliche Leistungsumfang, die anfallenden
                                Kosten
                                und die Zahlungsweise aufgeführt.
                            </li>
                            <li>Der Anbieter behält sich das Recht vor, für verschiedene Buchungszeitpunkte und
                                Nutzergruppen und insbesondere für verschiedene Nutzungszeiträume unterschiedliche
                                Entgeltmodelle zu berechnen, wie auch verschiedene Leistungsumfänge anzubieten
                            </li>
                            <li>Die Preise richten sich nach einer separaten Preisliste, die auf der Website
                                https://taxdigits.de/gebühren abgerufen werden.
                            </li>
                        </ol>
                    </li>
                    <li>Haftung und Gewährleistung des Anbieters
                        <ol>
                            <li>Während des kostenfreien Testzeitraumes übernimmt der Anbieter keine Gewährleistung. Die
                                Haftung richtet sich innerhalb der kostenlosten Testphase nach den gesetzlichen
                                Bestimmungen.
                            </li>
                            <li>Hinsichtlich der Gewährleistung bei entgeltlicher Leistungserbrigung gelten die
                                folgenden Punkte.
                                <ol>
                                    <li>Es ist nach dem heutigen Stand der Technik nicht möglich, Fehler in der
                                        Datenverarbeitung durch Softwarefehler unter allen Anwendungsbedingungen
                                        auszuschließen.
                                    </li>
                                    <li>Der Nutzer ist verpflichtet, dem Anbieter für ihn erkennbare Störungen und
                                        Datenverarbeitungsfehler unverzüglich mitzuteilen.
                                    </li>
                                    <li>Der Anbieter wird nach seiner Wahl den Mangel beseitigen um Nacherfüllung zu
                                        leisten. Hierzu hat der Nutzer dem Anbieter eine angemessene Nachfrist zu
                                        setzen. Im Falle des Fehlschlages der Nacherfüllung, hat der Nutzer Anspruch auf
                                        Ersatz des ihm entstandenen Schadens im Rahmen von Punkt 6.3
                                    </li>
                                    <li>Ein Gewährleistungsanspruch ist ausgeschlossen, wenn die Anwendung nicht
                                        vertragsgemäß eingesetzt wird.
                                    </li>
                                </ol>
                            </li>
                            <li>Hinsichtlich der Haftung bei entgeltlicher Leistungserbrigung gelten die folgenden
                                Punkte.
                                <ol>
                                    <li>Der Anbieter haftet dem Nutzer sets
                                        <ul>
                                            <li>für die von seinen gesetzlichen Vertretern und Erfüllungsgehilfen
                                                vorsätzlich und grob fahrkässig verursachten Schäden,
                                            </li>
                                            <li>nach dem Produkthaftungsgesetz,</li>
                                            <li>für Schäden aus der verletzung des Labens, des Körpers, der Gesundheit,
                                                die der Anbieter, seine gesetzlichen Vertreter oder Erfüllungsgehilfen
                                                zu vertreten habe.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Der Anbieter haftet bei leichter Fahlässigkeit nicht, außer soweit er eine
                                        wesentliche Vertragspflicht verletzt hat, deren Erfüllung die ordnungsgemäße
                                        Durchführung des Vertrages erst ermöglicht und deren Verletzung die Errichung
                                        des Vetragszewcks gefährdet. Und auf deren Einhaltung der Kunde regelmäßig
                                        vertrauen darf.
                                    </li>
                                    <li>
                                        Die Haftung nach 6.3.2 ist bei Sach- und Vermögensschäden auf den
                                        vertragstyoischen und vorhersehbaren Schaden beschränkt. Die Haftung für
                                        sonstige entfernte Folgeschäden ist ausgeschlossen.
                                    </li>
                                    <li>
                                        Soweit Anbieter nach dem vorstehendendem Regelungen nach für einen Datenverlust
                                        haftet, ist diese Haftung beschränkt auf die Kosten, für die Wiederherstellung
                                        von Daten die angefallen wären, wenn der Kunde seiner Pflicht zur Datensicherung
                                        vollumfänglich nachgekommen wäre.
                                    </li>
                                    <li>Haftungsbeschränkungen gelten auch zugunsten der gesetzlichen Vertreter,
                                        Angestellten und Erüllungsgehilfen des Anbieters.
                                    </li>
                                    <li>Der Anbieter haftet nicht für die Einhaltung der handelsrechtlichen und
                                        steuerlich geltenden Aufbewahrungspflicht von Rechnungen und Stornorechnungen im
                                        Sinne der §§ 238, 257 HGB und 417 AO.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>Datenschutz
                        <ol>
                            <li>Der Nutzer ist verpflichtet gemäß DSGVO, seine Kunden vor Abschluss eines Kaufvertrages
                                darüber zu informieren, dass er personenbezogene Daten im Rahmen der externen
                                AuftragsDatenverarbeitung an den Anbieter übermittelt.
                            </li>
                            <li>Soweit der Anbieter auf personenbezogene Daten des Nutzer oder aus dessen
                                Bereich zugreifen kann, wird er ausschließlich als Auftragsverarbeiter tätig und diese
                                Daten nur zur Vertragsdurchführung verarbeiten und nutzen. Der Anbieter wird
                                Weisungen des Nutzer für den Umgang mit diesen Daten beachten. Der Nutzer trägt
                                etwaige nachteilige Folgen solcher Weisungen für die Vertragsdurchführung. Der
                                Nutzer wird mit dem Anbieter die Details für den Umgang des Anbieters mit den
                                Daten des Nutzer nach den datenschutzrechtlichen Anforderungen vereinbaren.
                            </li>
                            <li>Der Nutzer bleibt sowohl allgemein im Auftragsverhältnis als auch im
                                datenschutzrechtlichen Sinne der Verantwortliche. Verarbeitet der Nutzer im
                                Zusammenhang mit dem Vertrag personenbezogene Daten (einschließlich
                                Erhebung und Nutzung), so steht er dafür ein, dass er dazu nach den anwendbaren,
                                insbesondere datenschutzrechtlichen Bestimmungen berechtigt ist und stellt im
                                Falle eines Verstoßes den Anbieter von Ansprüchen Dritter frei.
                            </li>
                            <li>Für das Verhältnis zwischen Anbieter und Nutzer gilt: Gegenüber der betroffenen
                                Person trägt die Verantwortung für die Verarbeitung (einschließlich Erhebung und
                                Nutzung) personenbezogener Daten der Nutzer, außer soweit der Anbieter
                                etwaige Ansprüche der betroffenen Person wegen einer ihm zuzurechnenden
                                Pflichtverletzung zu vertreten hat. Der Nutzer wird etwaige Anfragen, Anträge und
                                Ansprüche der betroffenen Person verantwortlich prüfen, bearbeiten und
                                beantworten. Das gilt auch bei einer Inanspruchnahme des Anbieters durch die
                                betroffene Person. Der Anbieter wird den Nutzer im Rahmenseiner Pflichten
                                unterstützen.
                            </li>
                            <li>
                                Der Anbieter gewährleistet, dass Daten des Nutzer ausschließlich im Gebiet der
                                Bundesrepublik Deutschland, in einem Mitgliedsstaat der Europäischen Union
                                oder in einem anderen Vertragsstaat des Abkommens über den Europäischen
                                Wirtschaftsraum gespeichert werden, soweit nichts anderes vereinbart ist.
                            </li>
                        </ol>
                    </li>
                    <li>Vertragswidrige Nutzung, Schadensersatz
                        <ol>
                            <li>Für jeden Fall, in dem im Verantwortungsbereich des Nutzer unberechtigt eine
                                vertragsgegenständliche Leistung in Anspruch genommen wird, hat der Nutzer
                                jeweils Schadensersatz in Höhe derjenigen Vergütung zu leisten, die für die
                                vertragsgemäße Nutzung im Rahmen der für diese Leistung geltenden
                                Mindestvertragsdauer angefallen wäre. Der Nachweis, dass der Nutzer die
                                unberechtigte Nutzung nicht zu vertreten hat oder kein oder ein wesentlich
                                geringerer Schaden vorliegt, bleibt dem Nutzer vorbehalten. Der Anbieter bleibt
                                berechtigt, einen weitergehenden Schaden geltend zu machen.
                            </li>
                        </ol>
                    </li>
                    <li>Vertragslaufzeit und Vertragsbeendigung
                        <ol>
                            <li>Die Erbringung der vertraglich vereinbarten Leistungen erfolgt für unbestimmte
                                Dauer, soweit das gewählte Leistungspaket keine feste Laufzeit vorgesehen ist.
                            </li>
                            <li>Leistungspakete ohne feste Laufzeiten können mit einer Frist von einem Monaten
                                gekündigt werden.
                            </li>
                            <li>Leistungspakete mit fester Laufzeit werden automatisch um die jeweils gleiche Laufzeit
                                verlängert, wenn nicht einer der Parteien vier Wochen vor Ende der festen Laufzeit
                                kündigt.
                            </li>
                            <li>Das Recht jedes Vertragspartners zur außerordentlichen Kündigung aus wichtigem Grund
                                bleibt unberührt.
                            </li>
                            <li>Jede Kündigungserklärung bedarf zu ihrer Wirksamkeit der Schriftform.</li>
                            <li>Der Nutzer wird rechtzeitig vor Beendigung des Vertrages seine Datenbestände
                                eigenverantwortlich sichern (etwa durch Download). Auf Wunsch wird der Anbieter den
                                Nutzer dabei unterstützen. Eine Zugriffmöglichkeit des Nutzer auf diese Datenbestände
                                wird nach Beendigung des Vertrages schon aus datenschutzrechtlichen Gründen regelmäßig
                                nicht mehr gegeben sein.
                            </li>
                        </ol>
                    </li>
                    <li>Änderungen, Schlussbestimmung
                        <ol>
                            <li>
                                Änderungen oder Ergänzungen dieser AGB werden dem Nutzer per E-Mail bekannt gegeben.
                                Die Änderungen oder Ergänzungen der AGB werden wirksam und gelten vom Nutzer als
                                angenommen, wenn er nicht innerhalb von 14 Tagen nach Übermittlung der Änderung oder
                                Ergänzung widerspricht. Der Widerspruch hat schriftlich zu erfolgen.
                            </li>
                            <li>Sollten einzelne Bestimmungen dieser AGB ungültig sein oder werden, bleibt die
                                Gültigkeit aller anderen Punkte bestehen.
                            </li>
                            <li>Es gilt das Recht der Bundesrepublik Deutschland. Das UN-Kaufrecht findet keine
                                Anwendung.
                            </li>
                            <li>Gerichtsstand ist Neuss.</li>
                        </ol>
                    </li>
                </ol>
            </div>
            <div className="modal-footer">
                <button type="button" onClick={() => {
                    props.setShow(false)
                }}
                        className="btn-modal btn btn-danger ml-3 text font-weight-medium waves-effect text-start"
                        data-bs-dismiss="modal">Schließen
                </button>
            </div>
        </div>
    </div>
}

export default AGB