import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import LoginPage from "./pages/login/login";
import KanzleistammdatenPage from "./pages/kanzleistammdaten/index";
import MandantenstammdatenPage from "./pages/mandantenstammdaten/index";
import BenutzerverwaltungPage from "./pages/benutzerverwaltung/index";
import KanzleistandardPage from "./pages/kanzleistandard/index";
import MandantSchnittstellePage from "./pages/mandant_schnittstelle/index";
import ShopPaymentSystemePage from "./pages/shop_payment_systeme";
import MandantenAnfragenPage from "./pages/mandantenanfragen";
import KanzleianfragePage from "./pages/kanzleinafrage";
import CSVUploadPage from "./pages/csv_upload";
import DownloadPage from "./pages/download";
import './styles/style.css';
import './styles/custom.css';
import {TaxDigitsContext} from "./context";
import React, {useState} from "react";
import RegistrationPage from "./pages/registration/registration";
import LogoutPage from "./pages/logout/logout";
import {Provider} from "use-http";
import Notifications from "react-notify-toast";
import ResetPassword from "./pages/reset_password/reset_password";
import BankKonverterPage from "./pages/bank_konverter";
import RechnungenPage from "./pages/rechnungen";
import UmsatzkontenUploadPage from "./pages/umsatzkonten_upload";

class ProtectedRoute extends React.Component {
    render() {
        const Component = this.props.component;
        const isAuthenticated = localStorage.getItem("profileUser") != null;
        if (window.location.search.includes("bookit")) {
            localStorage.setItem("bookitQuery", window.location.search)
        }

        return isAuthenticated ? (
            <Component/>
        ) : (
            <Redirect to={{pathname: '/login'}}/>
        );
    }
}

function App() {
    const [profileUser, setProfileUser] = useState(JSON.parse(localStorage.getItem("profileUser")));
    const [selectedClient, setSelectedClient] = useState(JSON.parse(localStorage.getItem("selectedClient")));
    const [account_length, setAccount_length] = useState(null)
    const globalOptions = {

        interceptors: {
            request: ({options}) => {
                if (localStorage.getItem("profileUser")) {
                    const json = JSON.parse(localStorage.getItem("profileUser"));
                    if (options.body instanceof FormData) {
                        options.headers = {
                            Authorization: 'Token ' + json.token,
                        }
                    } else {
                        options.headers = {
                            Authorization: 'Token ' + json.token,
                            'Content-Type': 'application/json',
                        }
                    }

                }
                return options
            },
            response: ({response}) => {
                return response
            }
        }
    }

    return (
        <TaxDigitsContext.Provider
            value={{
                profileUser,
                setProfileUser,
                selectedClient,
                setSelectedClient,
                account_length,
                setAccount_length,
            }}>
            <Notifications options={{zIndex: 45, top: '70px', animationDuration: 800}}/>
            <Provider url={process.env.REACT_APP_API_URL} options={globalOptions}>
                <Router basename="/ui">
                    <Switch>
                        <Route path="/login"><LoginPage/></Route>
                        <Route exact path="/logout"><LogoutPage/></Route>
                        <Route exact path="/register"><RegistrationPage/></Route>
                        <Route exact path="/reset_password"><ResetPassword/></Route>
                        <ProtectedRoute exact path="/kanzleistammdaten" component={KanzleistammdatenPage}/>
                        <ProtectedRoute exact path="/mandantenstammdaten" component={MandantenstammdatenPage}/>
                        <ProtectedRoute exact path="/benutzerverwaltung" component={BenutzerverwaltungPage}/>
                        <ProtectedRoute exact path="/kanzleistandard" component={KanzleistandardPage}/>
                        <ProtectedRoute exact path="/bank_konverter" component={BankKonverterPage}/>
                        <ProtectedRoute exact path="/rechnungen" component={RechnungenPage}/>
                        <ProtectedRoute exact path="/mandant_schnittstelle" component={MandantSchnittstellePage}/>
                        <ProtectedRoute exact path="/shop_payment_systeme" component={ShopPaymentSystemePage}/>
                        <ProtectedRoute exact path="/mandantenanfragen" component={MandantenAnfragenPage}/>
                        <ProtectedRoute exact path="/csv_upload" component={CSVUploadPage}/>
                        <ProtectedRoute exact path="/umsatzkonten_upload" component={UmsatzkontenUploadPage}/>
                        <ProtectedRoute exact path="/kanzleianfrage" component={KanzleianfragePage}/>
                        <ProtectedRoute exact path="/download" component={DownloadPage}/>
                    </Switch>
                </Router>
            </Provider>
        </TaxDigitsContext.Provider>
    );
}

export default App;
