import React, {useState} from "react"
import {useForm} from "react-hook-form";
import {useFetch} from "use-http";
import logo from '../../images/taxdigits_logo_web.png';
import {NavLink} from "react-router-dom";
import Spinner from "../../components/Spinner";

const ResetPasswordConfirmPage = (props) => {
    return <div className="auth-wrapper d-flex no-block justify-content-center align-items-center">
        <div className="auth-box p-4 bg-white rounded">
            <div>
                <div className="logo text-center">
                    <NavLink to="/login">
                        <span className="db img-responsive"><img src={logo} href={process.env.REACT_APP_API_URL}
                                                                 alt="logo"/></span>
                    </NavLink>
                </div>
                <div className="row mt-4">
                    <div className="col-12">
                        <p className="text-center">Vielen Dank</p>
                        <p className="text-center">
                            Sie erhalten in Kürze eine E-Mail, mit der Sie Ihr Passwort zurücksetzen können.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
        ;
}

const ResetPassword = (props) => {
    const {register, handleSubmit, setError, errors} = useForm();
    const [confirmed, setConfirmed] = useState(false);
    const {post, response, loading, error} = useFetch({cachePolicy: 'no-cache'});

    async function onRegistrationSubmit(data) {
        const login = await post('/api/v1/accounts/reset-password/', data);
        if (response.ok || response.status === 404) {
            setConfirmed(true);
        } else {
            if (login.password) {
                setError("password", {
                    type: "manual",
                    message: login.password[0]
                });
            }
            if (login.username) {
                setError("email", {
                    type: "manual",
                    message: login.username[0]
                });
            }
        }
    }

    if (confirmed) {
        return ResetPasswordConfirmPage(props);
    }

    return <div className="login-background  auth-wrapper d-flex no-block justify-content-center align-items-center">
        <div className="auth-box p-4 bg-white rounded">
            <div>
                <div className="logo text-center">
            <span className="db img-responsive">
            <NavLink to="/login">
            <img src={logo} alt="logo"/>
            </NavLink>
            </span>
                    <hr/>
                </div>
                <div className="row mt-4">
                    <div className="col-12">
                        <p className="text-center">
                            Geben Sie die von Ihnen verwendete E-Mail-Adresse ein, um das Passwort zurückzusetzen.
                        </p>
                    </div>
                    <div className="col-12">
                        <form className="form-horizontal" onSubmit={handleSubmit(onRegistrationSubmit)}>
                            <div className="form-floating mb-3">
                                <input type="email" name="email" className="form-control form-input-bg"
                                       id="tb-remail" placeholder="E-Mail"
                                       required="required" ref={register({required: true})}/>
                                {errors.email && <div style={{display: "block"}} className="invalid-feedback">
                                    {errors.email.message}
                                </div>}
                            </div>
                            <div className="d-flex align-items-stretch">
                                <button type="submit" className="btn btn-info d-block w-100">Passwort zurücksetzen
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
        ;
}

export default ResetPassword;