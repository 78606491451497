import React from "react";

const ConfirmationModal = (props) => {
    return <div className="confirmation-modal modal-dialog modal-dialog-centered">
        <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                <h4 className="modal-title" id="myLargeModalLabel"> {props.header}</h4>
            </div>
            <div className="modal-body">
                <h4 className="text-center">{props.message}</h4>
            </div>
            <div className="modal-footer">
                {!props.submitFunction &&
                <button type="submit"
                        className="btn-modal btn btn-success mr-3  font-weight-medium waves-effect text-start"
                        data-bs-dismiss="modal">{props.confirm}
                </button>
                }
                {props.submitFunction &&
                <button type="submit"
                        className="btn-modal btn btn-success mr-3  font-weight-medium waves-effect text-start"
                        data-bs-dismiss="modal" onClick={() => {
                    if (props.data) {
                        props.submitFunction(props.data)
                    } else {
                        props.submitFunction()
                    }
                }}>{props.confirm}
                </button>
                }
                <button type="button" onClick={() => {
                    props.hide(false)
                }}
                        className="btn-modal btn btn-danger ml-3 text font-weight-medium waves-effect text-start"
                        data-bs-dismiss="modal">Abbrechen
                </button>
            </div>
        </div>
    </div>
}

export default ConfirmationModal