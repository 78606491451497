import React, {useCallback, useContext, useEffect, useState} from "react";
import logo from '../images/logo_left.png';
import logoRight from '../images/logo_right.png';
import profile_picture from '../images/icons/profile.svg'
import profile_picture_white from '../images/icons/profile_white.svg'
import alarm_icon from '../images/icons/message.svg'
import {Link, NavLink, useLocation} from "react-router-dom";
import {TaxDigitsContext} from "../context";
import {useFetch} from "use-http";

const Nav = (props) => {
    const [dropDown, setDropDown] = useState(null)
    const [requests, setRequests] = useState([])
    const [clients, setClients] = useState([])
    const [selectedClient, setSelectedClient] = useState("choose")
    const {get, response, loading, error} = useFetch({cachePolicy: 'no-cache'});
    const userContext = useContext(TaxDigitsContext);
    let location = useLocation()
    const toggleDropDown = () => {
        if (!dropDown) {
            setDropDown('show')
        } else {
            setDropDown(null)
        }
    }
    const getRequestData = useCallback(async () => {
        const data = await get('/api/v1/taxdigits/client_requests/');
        if (response.ok) {
            setRequests(data)
        }
    }, [get, response])

    const getClients = useCallback(async () => {
        const data = await get('/api/v1/taxdigits/client/')
        if (response.ok) {
            setClients(data)
        }
    })

    const changeClient = (client) => {
        setSelectedClient(client)
        clients.forEach((e) => {
            if (parseInt(client) === e.id) {
                localStorage.setItem('selectedClient', JSON.stringify(e));
                userContext.setSelectedClient(e)
                window.location.reload();
                window.scrollTo(0,0)
            }
        })
    }
    useEffect(() => {
        if (userContext.profileUser.is_tax_user === true) {
            getRequestData()
            getClients()
        }
    }, [location])

    return <nav className="navbar top-navbar navbar-expand-md navbar-light">
        <div className="navbar-header" data-logobg="skin6">
            <a className="nav-toggler waves-effect waves-light d-block d-md-none" href="javascript:void(0)"><i
                className="ti-menu ti-close"/></a>
            <a className="navbar-brand" href="">
                <b className="logo-icon">
                    <img src={logo} alt="homepage" className="img-responsive dark-logo"/>
                    <img src={logo} alt="homepage" className="img-responsive light-logo"/>
                </b>
                <span className="logo-text">
                    <img src={logoRight} alt="homepage" className="img-responsive dark-logo"/>
                        <img src={logoRight} className="img-responsive light-logo" alt="homepage"/>
                        </span>
            </a>
            <a className="topbartoggler d-block d-md-none waves-effect waves-light" href="javascript:void(0)"
               data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
               aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><i
                className="ti-more"/>
            </a>
        </div>
        <div className="navbar-collapse collapse" id="navbarSupportedContent" data-navbarbg="skin6">
            {(userContext.profileUser.is_tax_user && clients) &&
            <div className="mt-3 mb-3 col-md-4">
                <select className="form-control form-select"
                        data-placeholder="Choose a Category" tabIndex="1"
                        value={selectedClient}
                        onChange={e => changeClient(e.currentTarget.value)}
                        name="role">
                    <option hidden disabled value="choose">Klient wählen...</option>
                    {
                        clients.map((e) => {
                            if (userContext.selectedClient && e.id === userContext.selectedClient.id && selectedClient !== e.id) {
                                setSelectedClient(e.id)
                            }
                            return <option
                                value={e.id}>{e.datev_id} - {e.name}</option>
                        })
                    }
                </select>
            </div>
            }
            <ul className="navbar-nav justify-content-end">
                {requests.length > 0 &&
                <li className="nav-item message-animation">
                    <NavLink to="/mandantenanfragen" className="nav-link">
                        <img alt="alarm" src={alarm_icon} width="30"/>
                        <div className="alert-point"/>
                    </NavLink>
                </li>
                }
                <li key="dropdown" className="nav-item dropdown" onClick={toggleDropDown}>
                    <a className="nav-link dropdown-toggle waves-effect waves-dark" href="#" data-bs-toggle="dropdown"
                       aria-haspopup="true" aria-expanded="false">
                        <img src={profile_picture} alt="user" width="30"
                             className="profile-pic rounded-circle"/>
                    </a>
                    <div className={"dropdown-menu dropdown-menu-end user-dd animated flipInY " + dropDown}>
                        <div className="d-flex no-block align-items-center p-3 bg-primary text-white mb-2">
                            <div className=""><img src={profile_picture_white} alt="user"
                                                   className="rounded-circle" width="60"/></div>
                            <div className="ms-2 ml-3">
                                <h4 className="mb-0 text-white">{userContext.profileUser.first_name + " " + userContext.profileUser.last_name} </h4>
                                <p className=" mb-0 ">{userContext.profileUser.email}
                                </p>
                            </div>
                        </div>
                        {(userContext.profileUser.is_tax_user === false && userContext.profileUser.approved) &&
                        <Link className="dropdown-item" to="/kanzleianfrage">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round"
                                 className="feather feather-credit-card feather-sm text-primary mr-1 me-1 ms-1">
                                <rect x="1" y="4" width="22" height="16" rx="2" ry="2"/>
                                <line x1="1" y1="10" x2="23" y2="10"/>
                            </svg>
                            Kanzlei
                        </Link>
                        }
                        <div className="dropdown-divider"/>
                        <Link className="dropdown-item" to="/logout">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round"
                                 className="feather feather-log-out mr-1 feather-sm text-danger me-1 ms-1">
                                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"/>
                                <polyline points="16 17 21 12 16 7"/>
                                <line x1="21" y1="12" x2="9" y2="12"/>
                            </svg>
                            Abmelden</Link>
                    </div>
                </li>
            </ul>
        </div>
    </nav>;
}

export default Nav;