import React, {useCallback, useReducer, useContext, useEffect, useState} from "react";
import Table from "./editable_table"
import {
    columns_expense,
    columns_inventory,
    columns_revenue,
    columns_voucher,
    colums_shipment,
} from './columns'
import {useFetch} from "use-http";
import {notify} from "react-notify-toast";
import ConnectedCell from "./connected_cell";
import {TaxDigitsContext} from "../../context";
import Spinner from "../../components/Spinner";
import ConfirmationModal from "../../components/cofirnmation_modal";

const Kanzleistandard = (props) => {
    const {put, del, get, response, loading, error} = useFetch({cachePolicy: 'no-cache'});
    const [standard, setStandard] = useState(null)

    const [revenues, setRevenues] = useState(null)
    const [revenues_other, setRevenuesOther] = useState(null)
    const [voucher, setVoucher] = useState(null)
    const [expense, setExpense] = useState(null)
    const [inventory, setInventory] = useState(null)
    const [shipment, setShipment] = useState(null)
    const [fee, setFee] = useState(null)
    const [customer_payment_type, setCustomer_payment_type] = useState(null)
    const [payment_providers, setPayment_providers] = useState(null)
    const [country_code_target, setCountry_code_target] = useState(null)
    const [country_code_is, setCountry_code_is] = useState(null)

    const [triggerFee, setTriggerFee] = useState(null)
    const [triggerCustomer_payment_type, setTriggerCustomer_payment_type] = useState(null)
    const [triggerPaymentProviders, setTriggerPaymentProviders] = useState(null)
    const [triggerCountryCodeTarget, setTriggerCountryCodeTarget] = useState(null)
    const [triggerCountryCodeIs, setTriggerCountryCodeIs] = useState(null)

    const [hideRevenues, setHideRevenues] = useState(true)
    const [hideRevenuesOther, setHideRevenuesOther] = useState(true)
    const [hideVoucher, setHideVoucher] = useState(true)
    const [hideExpense, setHideExpense] = useState(true)
    const [hideInventory, setHideInventory] = useState(true)
    const [hideShipment, setHideShipment] = useState(true)
    const [hideFee, setHideFee] = useState(true)
    const [hideCustomerPaymentType, setHideCustomerPaymentType] = useState(true)
    const [hideCustomerPaymentProvider, setHideCustomerPaymentProvider] = useState(true)
    const [hideCountryCodeIs, setHideCountryCodeIs] = useState(true)
    const [hideCountryCodeTarget, setHideCountryCodeTarget] = useState(true)

    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showLengthModal, setShowLengthModal] = useState(false)
    const [showSetStandardModal, setShowSetStandardModal] = useState(false)
    const [showSetUpdateStandardModal, setUpdateStandardModal] = useState(false)

    const [newLength, setNewLength] = useState(null)

    const userContext = useContext(TaxDigitsContext);

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        // todo if perfomance problems check for smaller update
        updateFee()
        updateCustomerPaymentType()
        updatePaymentProvider()
        updateCountryCodeTarget()
        updateCountryCodeIs()
    }, [standard])


    const updateCountryCodeTarget = () => {
        if (country_code_target) {
            const new_country_code_target = country_code_target
            new_country_code_target.forEach((entry) => {
                entry.income_de_eu_lowrate_skr_four = standard.income_de_eu_lowrate_skr_four_target + entry.account_ending
                entry.income_de_eu_lowrate_skr_three = standard.income_de_eu_lowrate_skr_three_target + entry.account_ending
                entry.income_de_eu_highrate_skr_four = standard.income_de_eu_highrate_skr_four_target + entry.account_ending
                entry.income_de_eu_highrate_skr_three = standard.income_de_eu_highrate_skr_three_target + entry.account_ending
                entry.intra_community_one_skr_four = standard.intra_community_one_skr_four_target + entry.account_ending
                entry.intra_community_one_skr_three = standard.intra_community_one_skr_three_target + entry.account_ending
                entry.intra_community_two_skr_four = standard.intra_community_two_skr_four_target + entry.account_ending
                entry.intra_community_two_skr_three = standard.intra_community_two_skr_three_target + entry.account_ending
                entry.intra_community_three_skr_four = standard.intra_community_three_skr_four_target + entry.account_ending
                entry.intra_community_three_skr_three = standard.intra_community_three_skr_three_target + entry.account_ending
                entry.intra_community_four_skr_four = standard.intra_community_four_skr_four_target + entry.account_ending
                entry.intra_community_four_skr_three = standard.intra_community_four_skr_three_target + entry.account_ending
                entry.intra_community_five_skr_four = standard.intra_community_five_skr_four_target + entry.account_ending
                entry.intra_community_five_skr_three = standard.intra_community_five_skr_three_target + entry.account_ending
                entry.domestic_sale_skr_four = standard.domestic_sale_skr_four_target + entry.account_ending
                entry.domestic_sale_skr_three = standard.domestic_sale_skr_three_target + entry.account_ending
            })
            setCountry_code_target([...new_country_code_target])
        }
    }

    const updateCountryCodeIs = () => {
        if (country_code_is) {
            const new_country_code_is = country_code_is
            new_country_code_is.forEach((entry) => {
                entry.income_de_eu_lowrate_skr_four = standard.income_de_eu_lowrate_skr_four_is + entry.account_ending
                entry.income_de_eu_lowrate_skr_three = standard.income_de_eu_lowrate_skr_three_is + entry.account_ending
                entry.income_de_eu_highrate_skr_four = standard.income_de_eu_highrate_skr_four_is + entry.account_ending
                entry.income_de_eu_highrate_skr_three = standard.income_de_eu_highrate_skr_three_is + entry.account_ending
                entry.intra_community_one_skr_four = standard.intra_community_one_skr_four_is + entry.account_ending
                entry.intra_community_one_skr_three = standard.intra_community_one_skr_three_is + entry.account_ending
                entry.intra_community_two_skr_four = standard.intra_community_two_skr_four_is + entry.account_ending
                entry.intra_community_two_skr_three = standard.intra_community_two_skr_three_is + entry.account_ending
                entry.intra_community_three_skr_four = standard.intra_community_three_skr_four_is + entry.account_ending
                entry.intra_community_three_skr_three = standard.intra_community_three_skr_three_is + entry.account_ending
                entry.intra_community_four_skr_four = standard.intra_community_four_skr_four_is + entry.account_ending
                entry.intra_community_four_skr_three = standard.intra_community_four_skr_three_is + entry.account_ending
                entry.intra_community_five_skr_four = standard.intra_community_five_skr_four_is + entry.account_ending
                entry.intra_community_five_skr_three = standard.intra_community_five_skr_three_is + entry.account_ending
                entry.domestic_sale_skr_four = standard.domestic_sale_skr_four_is + entry.account_ending
                entry.domestic_sale_skr_three = standard.domestic_sale_skr_three_is + entry.account_ending
            })
            setCountry_code_is([...new_country_code_is])
        }
    }

    const updatePaymentProvider = () => {
        if (payment_providers) {
            const new_payment_providers = payment_providers
            payment_providers.forEach((entry) => {
                entry.money_transfer_skr_four = standard.payment_provider_money_transfer_skr_four + entry.account_ending
                entry.money_transfer_skr_three = standard.payment_provider_money_transfer_skr_three + entry.account_ending
                entry.fee_skr_four = standard.payment_provider_fee_skr_four + entry.account_ending
                entry.fee_skr_three = standard.payment_provider_fee_skr_three + entry.account_ending
                entry.bankaccount_skr_four = standard.payment_service_bankaccount_skr_four + entry.account_ending
                entry.bankaccount_skr_three = standard.payment_service_bankaccount_skr_three + entry.account_ending
            })
            setPayment_providers([...new_payment_providers])
        }
    }

    const updateCustomerPaymentType = () => {
        if (customer_payment_type) {
            const new_customer_payment_type = customer_payment_type
            new_customer_payment_type.forEach((entry) => {
                entry.skr = standard.debitor_skr + entry.account_ending
            })
            setCustomer_payment_type([...new_customer_payment_type])
        }
    }

    const updateFee = () => {
        if (fee) {
            const new_fee = fee
            new_fee.forEach((entry) => {
                entry.skr_four = standard.payment_fee_skr_four + entry.account_ending
                entry.skr_three = standard.payment_fee_skr_three + entry.account_ending
            })
            setFee([...new_fee])
        }
    }

    // set fee connected cells
    useEffect(() => {
        updateFee()
    }, [triggerFee])

    // set customer payment type connected cells
    useEffect(() => {
        updateCustomerPaymentType()
    }, [triggerCustomer_payment_type])

    // set customer payment type connected cells
    useEffect(() => {
        updatePaymentProvider()
    }, [triggerPaymentProviders])

    useEffect(() => {
        updateCountryCodeTarget()
    }, [triggerCountryCodeTarget])

    useEffect(() => {
        updateCountryCodeIs()
    }, [triggerCountryCodeIs])

    const getData = useCallback(async () => {
        const data = await get('/api/v1/taxdigits/office_datev_standard/' + userContext.profileUser.tax_office + '/')
        if (response.ok) {
            setStandard(data)
            userContext.setAccount_length(data.account_length)
            setRevenues(data.revenues_details)
            setRevenuesOther(data.revenues_other_details)
            setVoucher(data.voucher_details)
            setExpense(data.amazon_expense_account_details)
            setInventory(data.amazon_inventory_details)
            setShipment(data.amazon_shipment_of_goods_details)
            setFee(data.payment_fees_details)
            setCustomer_payment_type(data.customer_payment_type_details)
            setPayment_providers(data.payment_providers_details)
            setCountry_code_target(data.country_code_target_details)
            setCountry_code_is(data.country_code_is_details)
        }
    })

    const saveData = async () => {
        const data = standard
        data.account_length = userContext.account_length
        data.revenues_details = revenues
        data.revenues_other_details = revenues_other
        data.voucher_details = voucher
        data.amazon_expense_account_details = expense
        data.amazon_inventory_details = inventory
        data.amazon_shipment_of_goods_details = shipment
        data.payment_fees_details = fee
        data.customer_payment_type_details = customer_payment_type
        data.payment_providers_details = payment_providers
        data.country_code_target_details = country_code_target
        data.country_code_is_details = country_code_is
        setShowSaveModal(false)
        const sendRevenues = await put('/api/v1/taxdigits/office_datev_standard/' + userContext.profileUser.tax_office + '/', data)
        if (response.ok) {
            notify.show("Daten erfolgreich gespeichert.", "success", 3000);
        } else {
            notify.show("Fehler: " + sendRevenues.detail, "error", 3000);
        }
    }

    const deleteData = async () => {
        const deleteData = await del('/api/v1/taxdigits/office_datev_standard/' + userContext.profileUser.tax_office + '/')
        setShowDeleteModal(false)
        if (response.ok) {
            getData()
            notify.show("Daten erfolgreich entfernt.", "success", 3000);
        } else {
            notify.show("Fehler: " + deleteData.detail, "error", 3000);
        }
    }

    const restoreTaxDigitsStandard = async () => {
        const data = {account_length: parseInt(userContext.account_length)}
        const restoreData = await put('/api/v1/taxdigits/office_datev_standard/' + userContext.profileUser.tax_office + '/restore/', data)
        setShowSetStandardModal(false)
        if (response.ok) {
            getData()
            notify.show("TaxDigits Standard erfolgreich wiederhergestellt.", "success", 3000);
        } else {
            notify.show("Fehler: " + restoreData.detail, "error", 3000);
        }
    }
    const updateTaxDigitsStandard = async () => {
        const data = {account_length: parseInt(userContext.account_length)}
        const restoreData = await put('/api/v1/taxdigits/office_datev_standard/' + userContext.profileUser.tax_office + '/update/', data)
        setUpdateStandardModal(false)
        if (response.ok) {
            getData()
            notify.show("TaxDigits Standard erfolgreich geupdated.", "success", 3000);
        } else {
            notify.show("Fehler: " + restoreData.detail, "error", 3000);
        }
    }
    const changeLength = (data) => {
        userContext.setAccount_length(data)
        setShowLengthModal(false)
    }

    const onBlur = (value, accessor) => {
        const new_standard = standard
        new_standard[accessor] = value
        setStandard({...new_standard})
    }

    const fee_footer = [
        {value: 'Hauptkonto'},
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.payment_fee_skr_four),
            accessor: 'payment_fee_skr_four',
            editable: true,
            blurFunction: onBlur
        },
        {
            value: (standard && standard.payment_fee_skr_three),
            accessor: 'payment_fee_skr_three',
            editable: true,
            blurFunction: onBlur
        },
    ]

    const columns_fee = [
        {
            accessor: 'title', Header: 'Paymentgebühren', Footer: '', Cell: (cell) => {
                return <span>{cell.value}</span>
            }
        },
        {
            accessor: 'account_ending', Header: 'Kontoendung', Footer: '', Cell: (cell) => {
                return <ConnectedCell account_length={userContext.account_length} cell={cell} data={fee}
                                      setData={setFee}
                                      setTrigger={setTriggerFee}
                />
            }
        },
        {Header: 'BU', accessor: 'bu'},

        {
            Header: 'SKR 04', accessor: 'skr_four', Cell: (cell) => {
                return <span>{cell.value}</span>
            },
        },
        {
            Header: 'SKR 03', accessor: 'skr_three', Cell: (cell) => {
                return <span>{cell.value}</span>
            },
        },
    ]
    const customer_payment_type_footer = [
        {value: 'Hauptkonto'},
        {value: ''},
        {
            value: (standard && standard.debitor_skr),
            accessor: 'debitor_skr',
            editable: true,
            blurFunction: onBlur
        },
    ]
    const columns_customer_payment_type = [
        {
            Header: "Debitoren nach Paymentype", accessor: "title", Cell: (cell) => {
                return <span>{cell.value}</span>
            }
        },
        {
            Header: "Kontoendung", accessor: "account_ending", Cell: (cell) => {
                return <ConnectedCell account_length={userContext.account_length} cell={cell}
                                      data={customer_payment_type}
                                      setData={setCustomer_payment_type}
                                      setTrigger={setTriggerCustomer_payment_type}
                />
            }
        },
        {
            Header: "SKR04/SKR03", accessor: "skr", Cell: (cell) => {
                return <span>{cell.value}</span>
            }
        },
    ]

    const payment_providers_footer = [
        {value: 'Hauptkonto'},
        {value: ''},
        {
            value: (standard && standard.payment_provider_money_transfer_skr_four),
            editable: true,
            blurFunction: onBlur,
            accessor: 'payment_provider_money_transfer_skr_four'
        },
        {
            value: (standard && standard.payment_provider_money_transfer_skr_three),
            editable: true,
            blurFunction: onBlur,
            accessor: 'payment_provider_money_transfer_skr_three'
        },
        {
            value: (standard && standard.payment_provider_fee_skr_four),
            editable: true,
            blurFunction: onBlur,
            accessor: 'payment_provider_fee_skr_four'
        },
        {
            value: (standard && standard.payment_provider_fee_skr_three),
            editable: true,
            blurFunction: onBlur,
            accessor: 'payment_provider_fee_skr_three'
        },
        {
            value: (standard && standard.payment_service_bankaccount_skr_four),
            editable: true,
            blurFunction: onBlur,
            accessor: 'payment_service_bankaccount_skr_four',
        },
        {
            value: (standard && standard.payment_service_bankaccount_skr_three),
            editable: true,
            blurFunction: onBlur,
            accessor: 'payment_service_bankaccount_skr_three'
        },
    ]
    const columns_payment_providers = [
        {
            accessor: 'title', Header: 'Payment-Anbieter', Cell: (cell) => {
                return <span>{cell.value}</span>
            }
        },
        {
            accessor: 'account_ending', Header: 'Kontoendung', Cell: (cell) => {
                return <ConnectedCell account_length={userContext.account_length} cell={cell} data={payment_providers}
                                      setData={setPayment_providers} setTrigger={setTriggerPaymentProviders}
                />
            }
        },
        {
            Header: 'Geldtransit', columns: [
                {
                    Header: 'SKR 04', accessor: 'money_transfer_skr_four', Cell: (cell) => {
                        return <span>{cell.value}</span>
                    }
                },
                {
                    Header: 'SKR 03', accessor: 'money_transfer_skr_three', Cell: (cell) => {
                        return <span>{cell.value}</span>
                    }
                },
            ]
        }, {
            Header: 'Sicherheitseinbehalte', columns: [
                {
                    Header: 'SKR 04', accessor: 'fee_skr_four', Cell: (cell) => {
                        return <span>{cell.value}</span>
                    }
                },
                {
                    Header: 'SKR 03', accessor: 'fee_skr_three', Cell: (cell) => {
                        return <span>{cell.value}</span>
                    }
                },
            ]
        }, {
            Header: 'Bankkonto', columns: [
                {
                    Header: 'SKR 04', accessor: 'bankaccount_skr_four', Cell: (cell) => {
                        return <span>{cell.value}</span>
                    }
                },
                {
                    Header: 'SKR 03', accessor: 'bankaccount_skr_three', Cell: (cell) => {
                        return <span>{cell.value}</span>
                    }
                },
            ]
        },
    ]

    const country_code_target_footer = [
        {value: 'Hauptkonto'},
        {value: ''},
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.income_de_eu_lowrate_skr_four_target),
            editable: true,
            blurFunction: onBlur,
            accessor: 'income_de_eu_lowrate_skr_four_target'
        },
        {
            value: (standard && standard.income_de_eu_lowrate_skr_three_target),
            editable: true,
            blurFunction: onBlur,
            accessor: 'income_de_eu_lowrate_skr_three_target'
        },
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.income_de_eu_highrate_skr_four_target),
            editable: true,
            blurFunction: onBlur,
            accessor: 'income_de_eu_highrate_skr_four_target',
        },
        {
            value: (standard && standard.income_de_eu_highrate_skr_three_target),
            editable: true,
            blurFunction: onBlur,
            accessor: 'income_de_eu_highrate_skr_three_target'
        },
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.intra_community_one_skr_four_target),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_one_skr_four_target',
        },
        {
            value: (standard && standard.intra_community_one_skr_three_target),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_one_skr_three_target'
        },
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.intra_community_two_skr_four_target),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_two_skr_four_target',
        },
        {
            value: (standard && standard.intra_community_two_skr_three_target),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_two_skr_three_target'
        },
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.intra_community_three_skr_four_target),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_three_skr_four_target',
        },
        {
            value: (standard && standard.intra_community_three_skr_three_target),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_three_skr_three_target'
        },
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.intra_community_four_skr_four_target),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_four_skr_four_target',
        },
        {
            value: (standard && standard.intra_community_four_skr_three_target),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_four_skr_three_target'
        },
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.intra_community_five_skr_four_target),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_five_skr_four_target',
        },
        {
            value: (standard && standard.intra_community_five_skr_three_target),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_five_skr_three_target'
        },
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.domestic_sale_skr_four_target),
            editable: true,
            blurFunction: onBlur,
            accessor: 'domestic_sale_skr_four_target',
        },
        {
            value: (standard && standard.domestic_sale_skr_three_target),
            editable: true,
            blurFunction: onBlur,
            accessor: 'domestic_sale_skr_three_target'
        },
    ]

    const columns_country_code_target = [
        {
            accessor: 'title', Header: 'Ländercode', Cell: (cell) => {
                return <span>{cell.value}</span>
            }
        },
        {
            accessor: 'account_ending', Header: 'Kontoendung', Cell: (cell) => {
                return <ConnectedCell account_length={userContext.account_length} cell={cell} data={country_code_target}
                                      setData={setCountry_code_target}
                                      setTrigger={setTriggerCountryCodeTarget}
                />
            }
        },
        {
            Header: 'Erlöse aus im Inland stpfl. EU-Lieferungen 7 % USt', columns: [
                {
                    Header: "DE-EU   B2C   kein OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'income_de_eu_lowrate_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'income_de_eu_lowrate_bu_payment'},
                        {
                            Header: 'SKR 04', accessor: 'income_de_eu_lowrate_skr_four', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                        {
                            Header: 'SKR 03', accessor: 'income_de_eu_lowrate_skr_three', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                    ]
                }
            ]
        },
        {
            Header: 'Erlöse aus im Inland stpfl.  EU-Lieferungen 19 % USt', columns: [
                {
                    Header: " DE-EU   B2C   kein OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'income_de_eu_highrate_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'income_de_eu_highrate_bu_payment'},
                        {
                            Header: 'SKR 04', accessor: 'income_de_eu_highrate_skr_four', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                        {
                            Header: 'SKR 03', accessor: 'income_de_eu_highrate_skr_three', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },]
                }
            ]
        }, {
            Header: 'Innergemeinschaftlicher Fernverkauf 1', columns: [
                {
                    Header: "EU-DE   B2C   OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'intra_community_one_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'intra_community_one_bu_payment'},
                        {
                            Header: 'SKR 04', accessor: 'intra_community_one_skr_four', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                        {
                            Header: 'SKR 03', accessor: 'intra_community_one_skr_three', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                    ]
                }
            ]
        }, {
            Header: 'Innergemeinschaftlicher Fernverkauf 2', columns: [
                {
                    Header: "EU-DE   B2C   kein OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'intra_community_two_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'intra_community_two_bu_payment'},
                        {
                            Header: 'SKR 04', accessor: 'intra_community_two_skr_four', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                        {
                            Header: 'SKR 03', accessor: 'intra_community_two_skr_three', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                    ]
                }
            ]
        }, {
            Header: 'Innergemeinschaftlicher Fernverkauf 3', columns: [
                {
                    Header: "DE-EU   B2C   OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'intra_community_three_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'intra_community_three_bu_payment'},
                        {
                            Header: 'SKR 04', accessor: 'intra_community_three_skr_four', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                        {
                            Header: 'SKR 03', accessor: 'intra_community_three_skr_three', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },]
                }
            ]
        }, {
            Header: 'Innergemeinschaftlicher Fernverkauf 4', columns: [
                {
                    Header: "EU1-EU2   B2C   OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'intra_community_four_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'intra_community_four_bu_payment'},
                        {
                            Header: 'SKR 04', accessor: 'intra_community_four_skr_four', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                        {
                            Header: 'SKR 03', accessor: 'intra_community_four_skr_three', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                    ]
                }
            ]
        }, {
            Header: 'Innergemeinschaftlicher Fernverkauf 5', columns: [
                {
                    Header: "EU1-EU2   B2C   kein OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'intra_community_five_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'intra_community_five_bu_payment'},
                        {
                            Header: 'SKR 04', accessor: 'intra_community_five_skr_four', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                        {
                            Header: 'SKR 03', accessor: 'intra_community_five_skr_three', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },]
                }
            ]
        }, {
            Header: 'Erlöse innerhalb eines Mitgliedstaates', columns: [
                {
                    Header: "EU1-EU1   B2C/B2B   kein OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'domestic_sale_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'domestic_sale_bu_payment'},
                        {
                            Header: 'SKR 04', accessor: 'domestic_sale_skr_four', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                        {
                            Header: 'SKR 03', accessor: 'domestic_sale_skr_three', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },]
                }
            ]
        },
    ]

    const country_code_is_footer = [
        {value: 'Hauptkonto'},
        {value: ''},
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.income_de_eu_lowrate_skr_four_is),
            editable: true,
            blurFunction: onBlur,
            accessor: 'income_de_eu_lowrate_skr_four_is'
        },
        {
            value: (standard && standard.income_de_eu_lowrate_skr_three_is),
            editable: true,
            blurFunction: onBlur,
            accessor: 'income_de_eu_lowrate_skr_three_is'
        },
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.income_de_eu_highrate_skr_four_is),
            editable: true,
            blurFunction: onBlur,
            accessor: 'income_de_eu_highrate_skr_four_is',
        },
        {
            value: (standard && standard.income_de_eu_highrate_skr_three_is),
            editable: true,
            blurFunction: onBlur,
            accessor: 'income_de_eu_highrate_skr_three_is'
        },
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.intra_community_one_skr_four_is),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_one_skr_four_is',
        },
        {
            value: (standard && standard.intra_community_one_skr_three_is),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_one_skr_three_is'
        },
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.intra_community_two_skr_four_is),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_two_skr_four_is',
        },
        {
            value: (standard && standard.intra_community_two_skr_three_is),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_two_skr_three_is'
        },
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.intra_community_three_skr_four_is),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_three_skr_four_is',
        },
        {
            value: (standard && standard.intra_community_three_skr_three_is),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_three_skr_three_is'
        },
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.intra_community_four_skr_four_is),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_four_skr_four_is',
        },
        {
            value: (standard && standard.intra_community_four_skr_three_is),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_four_skr_three_is'
        },
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.intra_community_five_skr_four_is),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_five_skr_four_is',
        },
        {
            value: (standard && standard.intra_community_five_skr_three_is),
            editable: true,
            blurFunction: onBlur,
            accessor: 'intra_community_five_skr_three_is'
        },
        {value: ''},
        {value: ''},
        {
            value: (standard && standard.domestic_sale_skr_four_is),
            editable: true,
            blurFunction: onBlur,
            accessor: 'domestic_sale_skr_four_is',
        },
        {
            value: (standard && standard.domestic_sale_skr_three_is),
            editable: true,
            blurFunction: onBlur,
            accessor: 'domestic_sale_skr_three_is'
        },
    ]

    const columns_country_code_is = [
        {
            accessor: 'title', Header: 'Ländercode', Cell: (cell) => {
                return <span>{cell.value}</span>
            }
        },
        {
            accessor: 'account_ending', Header: 'Kontoendung', Cell: (cell) => {
                return <ConnectedCell account_length={userContext.account_length} cell={cell} data={country_code_is}
                                      setData={setCountry_code_is}
                                      setTrigger={setTriggerCountryCodeIs}
                />
            }
        },
        {
            Header: 'Erlöse aus im Inland stpfl. EU-Lieferungen 7 % USt', columns: [
                {
                    Header: "DE-EU   B2C   kein OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'income_de_eu_lowrate_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'income_de_eu_lowrate_bu_payment'},
                        {
                            Header: 'SKR 04', accessor: 'income_de_eu_lowrate_skr_four', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                        {
                            Header: 'SKR 03', accessor: 'income_de_eu_lowrate_skr_three', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                    ]
                },
            ]
        },
        {
            Header: 'Erlöse aus im Inland stpfl.  EU-Lieferungen 19 % USt', columns: [
                {
                    Header: " DE-EU   B2C   kein OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'income_de_eu_highrate_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'income_de_eu_highrate_bu_payment'},
                        {
                            Header: 'SKR 04', accessor: 'income_de_eu_highrate_skr_four', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                        {
                            Header: 'SKR 03', accessor: 'income_de_eu_highrate_skr_three', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                    ]
                }
            ]
        }, {
            Header: 'Innergemeinschaftlicher Fernverkauf 1', columns: [
                {
                    Header: "EU-DE   B2C   OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'intra_community_one_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'intra_community_one_bu_payment'},
                        {
                            Header: 'SKR 04', accessor: 'intra_community_one_skr_four', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                        {
                            Header: 'SKR 03', accessor: 'intra_community_one_skr_three', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                    ]
                }
            ]
        }, {
            Header: 'Innergemeinschaftlicher Fernverkauf 2', columns: [
                {
                    Header: "EU-DE   B2C   kein OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'intra_community_two_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'intra_community_two_bu_payment'},
                        {
                            Header: 'SKR 04', accessor: 'intra_community_two_skr_four', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                        {
                            Header: 'SKR 03', accessor: 'intra_community_two_skr_three', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                    ]
                }]
        }, {
            Header: 'Innergemeinschaftlicher Fernverkauf 3', columns: [
                {
                    Header: "DE-EU   B2C   OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'intra_community_three_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'intra_community_three_bu_payment'},
                        {
                            Header: 'SKR 04', accessor: 'intra_community_three_skr_four', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                        {
                            Header: 'SKR 03', accessor: 'intra_community_three_skr_three', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                    ]
                }]
        }, {
            Header: 'Innergemeinschaftlicher Fernverkauf 4', columns: [
                {
                    Header: "EU1-EU2   B2C   OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'intra_community_four_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'intra_community_four_bu_payment'},
                        {
                            Header: 'SKR 04', accessor: 'intra_community_four_skr_four', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                        {
                            Header: 'SKR 03', accessor: 'intra_community_four_skr_three', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                    ]
                }]
        }, {
            Header: 'Innergemeinschaftlicher Fernverkauf 5', columns: [
                {
                    Header: "EU1-EU2   B2C   kein OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'intra_community_five_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'intra_community_five_bu_payment'},
                        {
                            Header: 'SKR 04', accessor: 'intra_community_five_skr_four', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                        {
                            Header: 'SKR 03', accessor: 'intra_community_five_skr_three', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                    ]
                }]
        }, {
            Header: 'Erlöse innerhalb eines Mitgliedstaates', columns: [
                {
                    Header: "EU1-EU1   B2C/B2B   kein OSS", columns: [
                        {Header: 'BU Erlös', accessor: 'domestic_sale_bu_revenues'},
                        {Header: 'BU Zahlung', accessor: 'domestic_sale_bu_payment'},
                        {
                            Header: 'SKR 04', accessor: 'domestic_sale_skr_four', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                        {
                            Header: 'SKR 03', accessor: 'domestic_sale_skr_three', Cell: (cell) => {
                                return <span>{cell.value}</span>
                            },
                        },
                    ]
                }]
        },
    ]


    return <div className="page-wrapper" style={{display: "block"}}>
        {(loading) && <Spinner/>}
        <div className="container-fluid">
            <div className="reverse-mode">
                <div className="dashboard-part" style={{height: "100%"}}>
                    <div className="row page-titles">
                        <div className="col-md-5 col-12 align-self-center">
                            <h3 className="text-themecolor mb-0">Kanzleistandard</h3>
                        </div>
                        <div className="col-md-7 col-12 align-self-center d-none d-md-flex justify-content-end">
                            <ol className="breadcrumb mb-0 p-0 bg-transparent">
                                <li className="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                                <li className="breadcrumb-item active d-flex align-items-center">Kanzleistandard</li>
                            </ol>
                        </div>
                    </div>
                    {showSaveModal &&
                    <ConfirmationModal message={"Möchten Sie die Daten speichern?"}
                                       header={'Speichern'}
                                       hide={setShowSaveModal}
                                       confirm="Speichern"
                                       submitFunction={saveData}

                    />}
                    {showDeleteModal &&
                    <ConfirmationModal message={"Möchten Sie die Daten löschen?"}
                                       header={'Löschen'}
                                       hide={setShowDeleteModal}
                                       confirm="Löschen"
                                       submitFunction={deleteData}

                    />}
                    {showLengthModal &&
                    <ConfirmationModal
                        message={"Achtung, wenn Sie die Kontenlänge ändern, müssen Sie die Daten in den Tabellen anpassen oder neu importieren."}
                        header={'Achtung'}
                        hide={setShowLengthModal}
                        confirm="Ändern"
                        submitFunction={changeLength}
                        data={newLength}
                    />}
                    {showSetStandardModal &&
                    <ConfirmationModal
                        message={<div>Achtung, alle Daten werden auf den TaxDigits Standard mit Kontenlänge <span
                            style={{fontWeight: "bold"}}>{userContext.account_length}</span> zurückgesetzt.</div>}
                        header={'Achtung'}
                        hide={setShowSetStandardModal}
                        confirm="Zurücksetzen"
                        submitFunction={restoreTaxDigitsStandard}
                    />}
                    {showSetUpdateStandardModal &&
                    <ConfirmationModal
                        message={<div>Achtung, der Taxdigits Standard wird geupdated mit Kontenlänge <span
                            style={{fontWeight: "bold"}}>{userContext.account_length}</span>. Das heisst alle nicht befüllten Standards werden leer initialisiert und müssen manuell befüllt werden.</div>}
                        header={'Achtung'}
                        hide={setUpdateStandardModal}
                        confirm="Zurücksetzen"
                        submitFunction={updateTaxDigitsStandard}
                    />}


                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="border-bottom title-part-padding">
                                    <h4 className="card-title mb-0">Kanzleistandard DATEV-Schnittstelle</h4>
                                </div>
                                <div className="card-body">
                                    <label htmlFor={"lengthInput"} className="control-label">Sachkontenlänge</label>
                                    <div className="row ">
                                        <div className="input-group mb-3 col-md-12">
                                            <select className="custom-select"
                                                    id={"lengthInput"}
                                                    data-placeholder="Choose a Category" tabIndex="1"
                                                    value={userContext.account_length}
                                                    onChange={(e) => {
                                                        setNewLength(e.target.value)
                                                        setShowLengthModal(true)
                                                    }}
                                                    name="account_length">
                                                <option value={6}>6</option>
                                                <option value={7}>7</option>
                                            </select>
                                            <div className="input-group-append">
                                                <button type="button" className="btn btn-warning"
                                                        onClick={() => {
                                                            setShowSetStandardModal(true)
                                                        }}>
                                                    Taxdigits-Standard importieren
                                                </button>
                                                <button type="button" className="btn btn-warning btn-border-left"
                                                        onClick={() => {
                                                            setUpdateStandardModal(true)
                                                        }}>
                                                    Taxdigits-Standard updaten
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <h3 className="col-auto mr-auto">Erlöse aus Warenlieferungen</h3>
                                        <button title="Ausklappen/Einklappen"
                                                className="col-auto btn btn-secondary hide-btn mr-3"
                                                onClick={() => {
                                                    setHideRevenues(!hideRevenues)
                                                }
                                                }
                                                type="button">{!hideRevenues && '-' || '+'}
                                        </button>
                                    </div>
                                    {(revenues !== null && !hideRevenues) &&
                                    <div className="repeater-default mt-4">
                                        <div data-repeater-list="">
                                            <div data-repeater-item="">
                                                <div className="row">
                                                    <div className="col-sm-12 table-responsive">
                                                        <Table
                                                            columns={columns_revenue}
                                                            data={revenues}
                                                            setData={setRevenues}
                                                            name="Erlöse aus Warenlieferungen"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <hr/>
                                    <div className="row">
                                        <h3 className="col-auto mr-auto">Erlöse aus sonstigen Leistungen</h3>
                                        <button title="Ausklappen/Einklappen"
                                                className="col-auto btn btn-secondary hide-btn mr-3"
                                                onClick={() => {
                                                    setHideRevenuesOther(!hideRevenuesOther)
                                                }
                                                }
                                                type="button">{!hideRevenuesOther && '-' || '+'}
                                        </button>
                                    </div>
                                    {(revenues_other !== null && !hideRevenuesOther) &&
                                    <div className="repeater-default mt-4">
                                        <div data-repeater-list="">
                                            <div data-repeater-item="">
                                                <div className="row">
                                                    <div className="col-sm-12 table-responsive">
                                                        <Table
                                                            columns={columns_revenue}
                                                            data={revenues_other}
                                                            setData={setRevenuesOther}
                                                            name="Erlöse aus sonstigen Leistungen"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <hr/>
                                    <div className="row">
                                        <h3 className="col-auto mr-auto">Gutscheine</h3>
                                        <button title="Ausklappen/Einklappen"
                                                className="col-auto btn btn-secondary hide-btn mr-3"
                                                onClick={() => {
                                                    setHideVoucher(!hideVoucher)
                                                }
                                                }
                                                type="button">{!hideVoucher && '-' || '+'}
                                        </button>
                                    </div>
                                    {(voucher !== null && !hideVoucher) &&
                                    <div className="repeater-default mt-4">
                                        <div data-repeater-list="">
                                            <div data-repeater-item="">
                                                <div className="row">
                                                    <div className="col-sm-12 table-responsive">
                                                        <Table
                                                            columns={columns_voucher}
                                                            data={voucher}
                                                            setData={setVoucher}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <hr/>
                                    <div className="row">
                                        <h3 className="col-auto mr-auto">Amazon-Spezifische Aufwandskonten</h3>
                                        <button title="Ausklappen/Einklappen"
                                                className="col-auto btn btn-secondary hide-btn mr-3"
                                                onClick={() => {
                                                    setHideExpense(!hideExpense)
                                                }
                                                }
                                                type="button">{!hideExpense && '-' || '+'}
                                        </button>
                                    </div>
                                    {(expense !== null && !hideExpense) &&
                                    <div className="repeater-default mt-4">
                                        <div data-repeater-list="">
                                            <div data-repeater-item="">
                                                <div className="row">
                                                    <div className="col-sm-12 table-responsive">
                                                        <Table
                                                            columns={columns_expense}
                                                            data={expense}
                                                            setData={setExpense}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <hr/>
                                    <div className="row">
                                        <h3 className="col-auto mr-auto">Vermischter Warenbestand Amazon</h3>
                                        <button title="Ausklappen/Einklappen"
                                                className="col-auto btn btn-secondary hide-btn mr-3"
                                                onClick={() => {
                                                    setHideInventory(!hideInventory)
                                                }
                                                }
                                                type="button">{!hideInventory && '-' || '+'}
                                        </button>
                                    </div>
                                    {(inventory !== null && !hideInventory) &&
                                    <div className="repeater-default mt-4">
                                        <div data-repeater-list="">
                                            <div data-repeater-item="">
                                                <div className="row">
                                                    <div className="col-sm-12 table-responsive">
                                                        <Table
                                                            columns={columns_inventory}
                                                            data={inventory}
                                                            setData={setInventory}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <hr/>
                                    <div className="row">
                                        <h3 className="col-auto mr-auto">Warenverbringungen (Amazon CEE/PAN-EU)</h3>
                                        <button title="Ausklappen/Einklappen"
                                                className="col-auto btn btn-secondary hide-btn mr-3"
                                                onClick={() => {
                                                    setHideShipment(!hideShipment)
                                                }
                                                }
                                                type="button">{!hideShipment && '-' || '+'}
                                        </button>
                                    </div>
                                    {(shipment !== null && !hideShipment) &&
                                    <div className="repeater-default mt-4">
                                        <div data-repeater-list="">
                                            <div data-repeater-item="">
                                                <div className="row">
                                                    <div className="col-sm-12 table-responsive">
                                                        <Table
                                                            columns={colums_shipment}
                                                            data={shipment}
                                                            setData={setShipment}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <hr/>
                                    <div className="row">
                                        <h3 className="col-auto mr-auto">Paymentgebühren</h3>
                                        <button title="Ausklappen/Einklappen"
                                                className="col-auto btn btn-secondary hide-btn mr-3"
                                                onClick={() => {
                                                    setHideFee(!hideFee)
                                                }
                                                }
                                                type="button">{!hideFee && '-' || '+'}
                                        </button>
                                    </div>
                                    {(fee !== null && !hideFee) &&
                                    <div className="repeater-default mt-4">
                                        <div data-repeater-list="">
                                            <div data-repeater-item="">
                                                <div className="row">
                                                    <div className="col-sm-12 table-responsive">
                                                        <Table
                                                            columns={columns_fee}
                                                            data={fee}
                                                            setData={setFee}
                                                            footer={fee_footer}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <hr/>
                                    <div className="row">
                                        <h3 className="col-auto mr-auto">Debitoren nach Paymenttype</h3>
                                        <button title="Ausklappen/Einklappen"
                                                className="col-auto btn btn-secondary hide-btn mr-3"
                                                onClick={() => {
                                                    setHideCustomerPaymentType(!hideCustomerPaymentType)
                                                }
                                                }
                                                type="button">{!hideCustomerPaymentType && '-' || '+'}
                                        </button>
                                    </div>
                                    {(customer_payment_type !== null && !hideCustomerPaymentType) &&
                                    <div className="repeater-default mt-4">
                                        <div data-repeater-list="">
                                            <div data-repeater-item="">
                                                <div className="row">
                                                    <div className="col-sm-12 table-responsive">
                                                        <Table
                                                            columns={columns_customer_payment_type}
                                                            data={customer_payment_type}
                                                            setData={setCustomer_payment_type}
                                                            footer={customer_payment_type_footer}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <hr/>
                                    <div className="row">
                                        <h3 className="col-auto mr-auto">Payment-Anbieter</h3>
                                        <button title="Ausklappen/Einklappen"
                                                className="col-auto btn btn-secondary hide-btn mr-3"
                                                onClick={() => {
                                                    setHideCustomerPaymentProvider(!hideCustomerPaymentProvider)
                                                }
                                                }
                                                type="button">{!hideCustomerPaymentProvider && '-' || '+'}
                                        </button>
                                    </div>
                                    {(payment_providers !== null && !hideCustomerPaymentProvider) &&
                                    <div className="repeater-default mt-4">
                                        <div data-repeater-list="">
                                            <div data-repeater-item="">
                                                <div className="row">
                                                    <div className="col-sm-12 table-responsive">
                                                        <Table
                                                            columns={columns_payment_providers}
                                                            data={payment_providers}
                                                            setData={setPayment_providers}
                                                            footer={payment_providers_footer}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <hr/>
                                    <div className="row">
                                        <h3 className="col-auto mr-auto">Länderspezifische Erlöskonten Soll
                                            Besteuerung</h3>
                                        <button title="Ausklappen/Einklappen"
                                                className="col-auto btn btn-secondary hide-btn mr-3"
                                                onClick={() => {
                                                    setHideCountryCodeTarget(!hideCountryCodeTarget)
                                                }
                                                }
                                                type="button">{!hideCountryCodeTarget && '-' || '+'}
                                        </button>
                                    </div>
                                    {(country_code_target && !hideCountryCodeTarget) &&
                                    <div className="repeater-default mt-4">
                                        <div data-repeater-list="">
                                            <div data-repeater-item="">
                                                <div className="row">
                                                    <div className="col-sm-12 table-responsive">
                                                        <Table
                                                            columns={columns_country_code_target}
                                                            data={country_code_target}
                                                            setData={setCountry_code_target}
                                                            footer={country_code_target_footer}
                                                            additionalClasses={'fixed-first-columns'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <hr/>
                                    <div className="row">
                                        <h3 className="col-auto mr-auto">Länderspezifische Erlöskonten Ist
                                            Besteuerung</h3>
                                        <button title="Ausklappen/Einklappen"
                                                className="col-auto btn btn-secondary hide-btn mr-3"
                                                onClick={() => {
                                                    setHideCountryCodeIs(!hideCountryCodeIs)
                                                }
                                                }
                                                type="button">{!hideCountryCodeIs && '-' || '+'}
                                        </button>
                                    </div>
                                    {(country_code_is && !hideCountryCodeIs) &&
                                    <div className="repeater-default mt-4">
                                        <div data-repeater-list="">
                                            <div data-repeater-item="">
                                                <div className="row">
                                                    <div className="col-sm-12 table-responsive">
                                                        <Table
                                                            columns={columns_country_code_is}
                                                            data={country_code_is}
                                                            setData={setCountry_code_is}
                                                            footer={country_code_is_footer}
                                                            additionalClasses={'fixed-first-columns'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <hr/>
                                </div>
                                <div className="row m-1">
                                    <div className="btn-toolbar">
                                        <button data-repeater-delete=""
                                                className="btn px-4 btn-danger font-weight-medium waves-effect waves-light m-l-10"
                                                type="button"
                                                onClick={() => {
                                                    setShowDeleteModal(true)
                                                }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                 height="24" viewBox="0 0 24 24" fill="none"
                                                 stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-trash-2 feather-sm ms-2 fill-white">
                                                <polyline points="3 6 5 6 21 6"/>
                                                <path
                                                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/>
                                                <line x1="10" y1="11" x2="10" y2="17"/>
                                                <line x1="14" y1="11" x2="14" y2="17"/>
                                            </svg>
                                            Alles löschen
                                        </button>
                                        <button type="button" className="btn mx-1 btn-success"
                                                onClick={() => {
                                                    setShowSaveModal(true)
                                                }}>
                                            Als Kanzleistandard definieren
                                        </button>
                                        <button type="button" className=" btn mx-1 btn-secondary">
                                            Kanzleistandard auf alle Mandanten anwenden
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        ;
}

export default Kanzleistandard;