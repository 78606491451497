import React from "react";
import {TaxDigitsContext} from "../../context";

const ConnectedCell = ({setTrigger, setData, cell, account_length}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState('')
    const userContext = React.useContext(TaxDigitsContext);

    const onChange = e => {
        setValue(e.target.value.substring(0, userContext.account_length - 4))
    }

    // We'll only update the external data when the input is blurred
    const onBlur = (e) => {
        //props.setData(e.target.value, props.cell.row.index, props.cell.column.id)

        setTrigger(Math.random())
        setData(old =>
            old.map((row, index) => {
                if (index === cell.row.index) {
                    const old_data = {...old}
                    old[cell.row.index][cell.column.id] = e.target.value
                    return old_data[cell.row.index]
                }
                return row
            })
        )
    }

    // If the value is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(cell.value)
        //props.data.row.original.skr_four = props.data.value + "test"
    }, [cell.value])
    return <input onWheel={(e) => e.target.blur()} type="number" className="connected-cell" maxLength="4"
                  pattern="[0-9]" value={value || ''} onChange={onChange} onBlur={onBlur}/>
}
export default ConnectedCell
