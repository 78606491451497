import React, {useCallback, useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useFetch} from "use-http";
import Notifications, {notify} from "react-notify-toast";
import Spinner from "../../components/Spinner";
import isValidIBANNumber from "../../components/validators"
import {TaxDigitsContext} from "../../context";
import {useHistory} from "react-router";
import ConfirmationModal from "../../components/cofirnmation_modal";

const Mandantenstammdaten = (props) => {
    const history = useHistory();
    const {register, handleSubmit, setError, errors, clearErrors} = useForm();
    const [loadedData, setLoadedData] = useState({});
    const {put, get, response, loading, error} = useFetch({cachePolicy: 'no-cache'});
    const userContext = useContext(TaxDigitsContext);
    const [ustId, setUstId] = useState([])
    const [profit_calc_type, setProfit_calc_type] = useState('')
    const [ctype, setCtype] = useState('')
    const [country, setCountry] = useState('')
    const [tax_type, setTax_type] = useState('')
    const [ustIdInput, setUstIdInput] = useState('')
    const [voucher, setVoucher] = useState('')
    const [oss, setOss] = useState(true)
    const [registration_begin, setRegistration_begin] = useState()
    const [insignificance, setInsignificance] = useState(true)
    const [skr, setSkr] = useState(null)
    const [account_length, setAccount_length] = useState(null)
    const [datev_fix_stack, setDatev_fix_stack] = useState(null)
    const [country_specific_accounts, setCountry_specific_accounts] = useState(null)
    const [book_income_separated, setBook_income_separated] = useState(null)

    const isClient = !userContext.profileUser.is_tax_user
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [shwoRemoveModal, setShowRemoveModal] = useState(false)
    const contextData = useContext(TaxDigitsContext);
    const [tooltip, setTooltip] = useState("mb-3 col-md-6")

    const keyAddUst = (e) => {
        if (e.keyCode === 13) {
            if (e.target.value) {
                let data = ustId
                data.push(e.target.value)
                setUstId([...data])
                e.target.value = null
                setUstIdInput('')
            }
        }
    }
    const buttonAddUst = (e) => {
        if (ustIdInput) {
            let data = ustId
            data.push(ustIdInput)
            setUstId([...data])
            setUstIdInput('')
        }
    }

    const removeClient = async () => {
        setShowRemoveModal(false)
        const data = {request_state: 'none', tax_office: null}
        const formResponse = await put('/api/v1/taxdigits/client/' + contextData.selectedClient.id + '/', data);
        if (response.ok) {
            notify.show("Mandant wurde entfernt", "success", 3000);
            localStorage.removeItem('selectedClient');
            contextData.setSelectedClient(null)
            window.scrollTo(0, 0)
            history.push("/kanzleistammdaten");
        } else if (response.status === 403) {
            notify.show("Fehler: " + formResponse.detail, "error", 3000);
        } else {
            notify.show("Fehler: Etwas ist schief gelaufen.", "error", 3000);
        }
    }

    async function submitForm(data) {
        setShowSaveModal(false)
        if (Object.keys(errors).length === 0) {
            data.ust_id = ustId;
            data.profit_calc_type = profit_calc_type;
            data.tax_type = tax_type;
            data.voucher = voucher;
            data.oss = oss;
            data.insignificance = insignificance;
            data.registration_begin = registration_begin;
            data.ctype = ctype;
            data.country = country;
            if (ctype === "gbr" || ctype === "one_man") {
                data.reg_name = ""
                data.reg_no = ""
            }
            let formResponse = null
            if (!isClient && userContext) {
                data.skr = skr
                data.account_length = account_length
                data.datev_fix_stack = datev_fix_stack
                data.country_specific_accounts = country_specific_accounts
                data.book_income_separated = book_income_separated
                formResponse = await put('/api/v1/taxdigits/client/' + userContext.selectedClient.id + '/', data);
            } else {
                formResponse = await put('/api/v1/taxdigits/company/' + userContext.profileUser.id + '/', data);
            }
            if (response.ok) {
                notify.show("Daten erfolgreich gespeichert", "success", 3000);
                setLoadedData(formResponse)
                // set client to approved after setting initial data
                if (userContext.profileUser.approved === false && userContext.profileUser.is_tax_user === false) {
                    let newUser = userContext.profileUser
                    newUser.approved = true
                    localStorage.setItem('profileUser', JSON.stringify(newUser));
                    userContext.setProfileUser({...userContext.profileUser, approved: true})
                }
                if(!isClient && userContext){
                    userContext.setSelectedClient(formResponse)
                    localStorage.setItem('selectedClient', JSON.stringify(formResponse));
                }
            } else if (response.status === 403) {
                notify.show("Fehler: " + formResponse.detail, "error", 3000);
            } else {
                for (const [key, value] of Object.entries(formResponse)) {
                    setError(key, {
                        type: "manual",
                        message: value[0],
                    })
                }
                notify.show("Fehler: Die Daten konnten nicht gespeichert werden.", "error", 3000);
            }
        }
        notify.show("Bitte füllen Sie alle Felder korrekt aus.", "warning", 3000);
    }

    const getInitialData = useCallback(async () => {
        let initialData = null
        if (!isClient && userContext) {
            initialData = await get('/api/v1/taxdigits/client/' + userContext.selectedClient.id + '/');
        } else {
            initialData = await get('/api/v1/taxdigits/company/' + userContext.profileUser.id + '/');
        }
        if (response.ok) {
            setProfit_calc_type(initialData.profit_calc_type)
            setTax_type(initialData.tax_type)
            setCountry(initialData.country)
            setVoucher(initialData.voucher)
            setCtype(initialData.ctype)
            setLoadedData(initialData)
            setSkr(initialData.skr)
            setOss(initialData.oss)
            setInsignificance(initialData.insignificance)
            setRegistration_begin(initialData.registration_begin)
            setAccount_length(initialData.account_length)
            setDatev_fix_stack(initialData.datev_fix_stack)
            setCountry_specific_accounts(initialData.country_specific_accounts)
            setBook_income_separated(initialData.book_income_separated)
            const data = []
            initialData.ust_id.forEach((item) => {
                data.push(item)
            })
            setUstId([...data])
        } else if (response.status === 403) {
            notify.show("Fehler: " + initialData.detail, "error", 3000);
        } else {
            if (!isClient && response.status === 404) {
                notify.show("Mandant nicht gefunden.", "warning", 3000);
                history.push('/mandantenafragen')
            }
        }
    }, [get, response])


    useEffect(() => {
        if (isClient) {
            setTooltip("mb-3 col-md-6 custom-tooltip")
        } else {
            setTooltip("mb-3 col-md-6")
        }
        getInitialData()
    }, [])

    const changeInput = (e) => {
        setUstIdInput(e.target.value)
    }

    const checkIban = (e) => {
        if (!isValidIBANNumber(e.target.value)) {
            setError("iban", {
                type: "manual",
                message: "Die IBAN ist nicht valide.",
            })
        } else {
            clearErrors("iban")
        }
    }

    return <div className="page-wrapper" style={{display: "block"}}>
        <Notifications options={{zIndex: 45, top: '70px', animationDuration: 800}}/>
        {shwoRemoveModal &&
        <ConfirmationModal message={"Sind Sie sicher, dass sie den Mandanten entfernen möchten?"}
                           header={'Mandant entfernen'}
                           hide={setShowRemoveModal}
                           confirm="Entfernen"
                           submitFunction={removeClient}

        />}
        {(loading) && <Spinner/>}
        <div className="container-fluid">
            <div className="reverse-mode">
                <div className="dashboard-part" style={{height: "100%"}}>
                    <div className="row page-titles">
                        <div className="col-md-5 col-12 align-self-center">
                            <h3 className="text-themecolor mb-0">Mandantenstammdaten</h3>
                        </div>
                        <div className="col-md-7 col-12 align-self-center d-none d-md-flex justify-content-end">
                            <ol className="breadcrumb mb-0 p-0 bg-transparent">
                                <li className="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                                <li className="breadcrumb-item active d-flex align-items-center">Mandantenstammdaten</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="border-bottom title-part-padding">
                                    <h4 className="card-title mb-0">Allgemeine Unternehmensangaben</h4>
                                </div>
                                <div className="card-body">
                                    {(userContext.profileUser.is_tax_user === false && userContext.profileUser.approved === false) &&
                                    <div>
                                        <h5 className="text-center">HINWEIS:</h5>
                                        <h5 className="text-center">Bitte füllen Sie initial die Daten aus, um
                                            erweiterten Zugriff zu erhalten.</h5>
                                    </div>
                                    }
                                    <div className="repeater-default mt-4">
                                        <div data-repeater-list="">
                                            <div data-repeater-item="">
                                                <form onSubmit={handleSubmit(submitForm)}
                                                      onKeyPress={(e) => {
                                                          e.key === 'Enter' && e.preventDefault();
                                                      }}>
                                                    {showSaveModal &&
                                                    <ConfirmationModal message={"Möchten Sie die Daten speichern?"}
                                                                       header={'Speichern'}
                                                                       hide={setShowSaveModal}
                                                                       confirm="Speichern"

                                                    />}
                                                    <div className="row">
                                                        <div className="mb-3 col-md-3">
                                                            <label htmlFor="contact_salutation">Anrede</label>
                                                            <input ref={register()} type="text" className="form-control"
                                                                   placeholder="" required={isClient}
                                                                   readOnly={!isClient}
                                                                   defaultValue={loadedData.contact_salutation}
                                                                   name="contact_salutation"/>
                                                            {errors.contact_salutation &&
                                                            <div style={{display: "block"}}
                                                                 className="invalid-feedback">
                                                                {errors.contact_salutation.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-3">
                                                            <label htmlFor="contact_first_name">Vorname</label>
                                                            <input ref={register()} type="text" className="form-control"
                                                                   placeholder="" required={isClient}
                                                                   readOnly={!isClient}
                                                                   defaultValue={loadedData.contact_first_name}
                                                                   name="contact_first_name"/>
                                                            {errors.contact_first_name &&
                                                            <div style={{display: "block"}}
                                                                 className="invalid-feedback">
                                                                {errors.contact_first_name.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="contact_last_name">Nachname</label>
                                                            <input ref={register()} type="text" className="form-control"
                                                                   placeholder="" required={isClient}
                                                                   readOnly={!isClient}
                                                                   defaultValue={loadedData.contact_last_name}
                                                                   name="contact_last_name"/>
                                                            {errors.contact_last_name &&
                                                            <div style={{display: "block"}}
                                                                 className="invalid-feedback">
                                                                {errors.contact_last_name.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-12">
                                                            <label htmlFor="name">Firmenname</label>
                                                            <input ref={register()} type="text" className="form-control"
                                                                   placeholder="" required={isClient}
                                                                   readOnly={!isClient}
                                                                   defaultValue={loadedData.name} name="name"/>
                                                            {errors.name && <div style={{display: "block"}}
                                                                                 className="invalid-feedback">
                                                                {errors.name.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="street">Straße</label>
                                                            <input ref={register()} type="text" className="form-control"
                                                                   placeholder="" required={isClient}
                                                                   readOnly={!isClient}
                                                                   defaultValue={loadedData.street} name="street"/>
                                                            {errors.street && <div style={{display: "block"}}
                                                                                   className="invalid-feedback">
                                                                {errors.street.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="streetno">Hausnummer</label>
                                                            <input ref={register()} type="text" className="form-control"
                                                                   placeholder="" required={isClient}
                                                                   readOnly={!isClient}
                                                                   defaultValue={loadedData.streetno} name="streetno"/>
                                                            {errors.streetno && <div style={{display: "block"}}
                                                                                     className="invalid-feedback">
                                                                {errors.streetno.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="zip">PLZ</label>
                                                            <input ref={register({
                                                                required: isClient,
                                                                pattern: {
                                                                    value: /[0-9]{5}/,
                                                                    message: 'Keine gültige Postleitzahl.'
                                                                }
                                                            })} type="text"
                                                                   inputMode="numeric"
                                                                   readOnly={!isClient}
                                                                   maxLength="5"
                                                                   className="form-control"
                                                                   defaultValue={loadedData.zip} name="zip"/>
                                                            {errors.zip && <div style={{display: "block"}}
                                                                                className="invalid-feedback">
                                                                {errors.zip.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="city">Stadt</label>
                                                            <input ref={register()} type="text" className="form-control"
                                                                   placeholder="" required={isClient}
                                                                   readOnly={!isClient}
                                                                   defaultValue={loadedData.city} name="city"/>
                                                            {errors.city && <div style={{display: "block"}}
                                                                                 className="invalid-feedback">
                                                                {errors.city.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label
                                                                className="control-label">Nation</label>
                                                            <select ref={register("country")}
                                                                    className="form-control form-select"
                                                                    data-placeholder="Choose a Category" tabIndex="1"
                                                                    value={country} required={isClient}
                                                                    disabled={!isClient}
                                                                    onChange={e => setCountry(e.currentTarget.value)}
                                                                    name="country">
                                                                <option value="germany">Deutschland</option>
                                                            </select>
                                                            {errors.country && <div style={{display: "block"}}
                                                                                    className="invalid-feedback">
                                                                {errors.country.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="phone">Telefonnummer</label>
                                                            <input ref={register()} type="text" className="form-control"
                                                                   placeholder="" required={isClient}
                                                                   readOnly={!isClient}
                                                                   defaultValue={loadedData.phone} name="phone"/>
                                                            {errors.phone && <div style={{display: "block"}}
                                                                                  className="invalid-feedback">
                                                                {errors.phone.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="email">E-Mail (Standard)</label>
                                                            <input ref={register()} type="email"
                                                                   className="form-control" required={isClient}
                                                                   readOnly={!isClient}
                                                                   placeholder="" defaultValue={loadedData.email}
                                                                   name="email"/>
                                                            {errors.email && <div style={{display: "block"}}
                                                                                  className="invalid-feedback">
                                                                {errors.email.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="email2">E-Mail (Rechnungsversand)</label>
                                                            <input ref={register()} type="email"
                                                                   className="form-control" required={isClient}
                                                                   readOnly={!isClient}
                                                                   placeholder="" defaultValue={loadedData.email2}
                                                                   name="email2"/>
                                                            {errors.email2 && <div style={{display: "block"}}
                                                                                   className="invalid-feedback">
                                                                {errors.email2.message}
                                                            </div>}
                                                        </div>
                                                        <div className="w-100"/>
                                                        <div className="mb-3 col-md-6">
                                                            <label
                                                                className="control-label">Rechtsform</label>
                                                            <select ref={register("ctype")}
                                                                    className="form-control form-select"
                                                                    data-placeholder="Choose a Category" tabIndex="1"
                                                                    value={ctype} required={isClient}
                                                                    disabled={!isClient}
                                                                    onChange={e => setCtype(e.currentTarget.value)}
                                                                    name="ctype">
                                                                <option hidden disabled value={""}>Bitte wählen...
                                                                </option>
                                                                <option value="one_man">Einzelunternehmen</option>
                                                                <option value="ek">e.K.</option>
                                                                <option value="gbr">GBR</option>
                                                                <option value="kg">KG</option>
                                                                <option value="gmbh_kg">GmbH & Co. KG</option>
                                                                <option value="ug">UG</option>
                                                                <option value="gmbh">GmbH</option>
                                                                <option value="ag">AG</option>
                                                            </select>
                                                            {errors.ctype && <div style={{display: "block"}}
                                                                                  className="invalid-feedback">
                                                                {errors.ctype.message}
                                                            </div>}
                                                        </div>
                                                        <div className="w-100"/>
                                                        {(ctype && (ctype !== 'one_man' && ctype !== "gbr")) &&
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="reg_name">Registergericht</label>
                                                            <input ref={register()} type="text"
                                                                   className="form-control"
                                                                   placeholder=""
                                                                   required={((ctype && ctype !== 'one_man') && isClient)}
                                                                   readOnly={(ctype === 'one_man' || !isClient)}
                                                                   defaultValue={loadedData.reg_name}
                                                                   name="reg_name"/>
                                                            {errors.reg_name && <div style={{display: "block"}}
                                                                                     className="invalid-feedback">
                                                                {errors.reg_name.message}
                                                            </div>}
                                                        </div>}
                                                        {(ctype && (ctype !== 'one_man' && ctype !== 'gbr')) &&
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="reg_no">Registernummer</label>
                                                            <input ref={register()} type="text"
                                                                   className="form-control"
                                                                   placeholder=""
                                                                   required={((ctype && ctype !== 'one_man') && isClient)}
                                                                   readOnly={(ctype === 'one_man' || !isClient)}
                                                                   defaultValue={loadedData.reg_no} name="reg_no"/>
                                                            {errors.reg_no && <div style={{display: "block"}}
                                                                                   className="invalid-feedback">
                                                                {errors.reg_no.message}
                                                            </div>}
                                                        </div>}
                                                        <div
                                                            className="mb-3 col-md-12 border-bottom title-part-padding">
                                                            <h4 className="card-title mb-0">Bankverbindung</h4>
                                                        </div>
                                                        <div className="mb-3 col-md-12">
                                                            <label htmlFor="bank_ownername">Kontoinhaber</label>
                                                            <input ref={register()} type="text" className="form-control"
                                                                   defaultValue={loadedData.bank_ownername}
                                                                   required={isClient} readOnly={!isClient}
                                                                   name="bank_ownername" placeholder=""/>
                                                            {errors.bank_ownername && <div style={{display: "block"}}
                                                                                           className="invalid-feedback">
                                                                {errors.bank_ownername.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-4">
                                                            <label htmlFor="bank_name">Bank</label>
                                                            <input ref={register()} type="text" className="form-control"
                                                                   defaultValue={loadedData.bank_name} name="bank_name"
                                                                   placeholder="" required={isClient}
                                                                   readOnly={!isClient}/>
                                                            {errors.bank_name && <div style={{display: "block"}}
                                                                                      className="invalid-feedback">
                                                                {errors.bank_name.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-4">
                                                            <label htmlFor="iban">IBAN</label>
                                                            <input ref={register()} type="text" className="form-control"
                                                                   defaultValue={loadedData.iban} name="iban"
                                                                   onBlur={checkIban.bind(this)}
                                                                   placeholder="" required={isClient}
                                                                   readOnly={!isClient}/>
                                                            {errors.iban && <div style={{display: "block"}}
                                                                                 className="invalid-feedback">
                                                                {errors.iban.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-4">
                                                            <label htmlFor="bic">BIC</label>
                                                            <input ref={register({
                                                                pattern: {
                                                                    value: /[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}/,
                                                                    message: 'Bitte geben Sie eine gültige BIC ein.'
                                                                }
                                                            })} type="text" className="form-control"
                                                                   defaultValue={loadedData.bic} name="bic"
                                                                   placeholder="" required={isClient}
                                                                   readOnly={!isClient}/>
                                                            {errors.bic && <div style={{display: "block"}}
                                                                                className="invalid-feedback">
                                                                {errors.bic.message}
                                                            </div>}
                                                        </div>
                                                        <div
                                                            className="mb-3 col-md-12 border-bottom title-part-padding">
                                                            <h4 className="card-title mb-0">Finanzamt und Steuern</h4>
                                                        </div>
                                                        <div className="mb-3 col-md-12">
                                                            <label htmlFor="tax_no">Steuernummer</label>
                                                            <input ref={register()} type="text" className="form-control"
                                                                   defaultValue={loadedData.tax_no} name="tax_no"
                                                                   placeholder="" required/>
                                                            {errors.tax_no && <div style={{display: "block"}}
                                                                                   className="invalid-feedback">
                                                                {errors.tax_no.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-12">
                                                            <label className="control-label">USt-ID (optional):</label>
                                                            <div className="input-group">
                                                                <input onKeyDown={keyAddUst.bind(this)} type="text"
                                                                       className="form-control" value={ustIdInput}
                                                                       onChange={changeInput.bind(this)}
                                                                       placeholder="USt-ID eintragen und hinzufügen"/>
                                                                <div className="input-group-append">
                                                                    <button className="btn btn-secondary"
                                                                            onClick={buttonAddUst.bind(this)}
                                                                            type="button">+
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <ul className="list-group">
                                                                {ustId.map((item, idx) => {
                                                                    return (<li key={idx}
                                                                                className="list-group-item py-2">{item}
                                                                        <button onClick={() => {
                                                                            const data = ustId;
                                                                            data.splice(idx, 1);
                                                                            setUstId([...data]);
                                                                        }}
                                                                                type="button"
                                                                                className="btn float-right btn-rounded btn-secondary btn-xs remove-item">X
                                                                        </button>
                                                                    </li>)
                                                                })}
                                                            </ul>
                                                            {errors.ust_id && <div style={{display: "block"}}
                                                                                   className="invalid-feedback">
                                                                {errors.ust_id.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-12">
                                                            <label
                                                                className="control-label">Gutscheine</label>
                                                            <select ref={register("ctype")}
                                                                    className="form-control form-select"
                                                                    data-placeholder="Choose a Category" tabIndex="1"
                                                                    value={voucher} required
                                                                    onChange={e => setVoucher(e.currentTarget.value)}
                                                                    name="voucher">
                                                                <option hidden disabled value={""}>Bitte wählen...
                                                                </option>
                                                                <option value="no_voucher">keine Gutscheine</option>
                                                                <option value="single_purpose">Einzweck-Gutscheine
                                                                </option>
                                                                <option value="multi_purpose">Mehrzweck-Gutscheine
                                                                </option>
                                                            </select>
                                                            {errors.ctype && <div style={{display: "block"}}
                                                                                  className="invalid-feedback">
                                                                {errors.ctype.message}
                                                            </div>}
                                                        </div>
                                                        <div className={tooltip}>
                                                            <span className="custom-tooltip-text">Das Feld wird durch Ihren Steuerberater ausgefüllt.</span>
                                                            <label className={"control-label custom-tooltip"}>
                                                                Besteuerungsart
                                                            </label>
                                                            <select ref={register("tax_type")}
                                                                    className="form-control form-select"
                                                                    data-placeholder="Choose a Category" tabIndex="1"
                                                                    value={tax_type} required={!isClient}
                                                                    disabled={isClient}
                                                                    onChange={e => setTax_type(e.currentTarget.value)}
                                                                    name="tax_type">
                                                                <option hidden disabled value={""}>-</option>
                                                                <option value="target_taxation">Soll-Besteuerung
                                                                </option>
                                                                <option value="is_taxation">Ist-Besteuerung</option>
                                                            </select>
                                                            {errors.tax_type && <div style={{display: "block"}}
                                                                                     className="invalid-feedback">
                                                                {errors.tax_type.message}
                                                            </div>}
                                                        </div>
                                                        <div className={tooltip}>
                                                            <span className="custom-tooltip-text">Das Feld wird durch Ihren Steuerberater ausgefüllt.</span>
                                                            <label className={"control-label"}>
                                                                Gewinnermittlungsart
                                                            </label>
                                                            <select ref={register("profit_calc_type")}
                                                                    className="form-control form-select"
                                                                    required={!isClient} disabled={isClient}
                                                                    data-placeholder="Choose a Category" tabIndex="1"
                                                                    value={profit_calc_type}
                                                                    onChange={e => setProfit_calc_type(e.currentTarget.value)}
                                                                    name="profit_calc_type">
                                                                <option hidden disabled value={""}>-</option>
                                                                <option value="accounting">Bilanzierung</option>
                                                                <option value="eur">EÜR</option>
                                                            </select>
                                                            {errors.profit_calc_type && <div style={{display: "block"}}
                                                                                             className="invalid-feedback">
                                                                {errors.profit_calc_type.message}
                                                            </div>}
                                                        </div>
                                                        {loadedData.tax_office !== null &&
                                                        <div className="row px-3">
                                                            <div
                                                                className="mb-3 col-md-12 border-bottom title-part-padding">
                                                                <h4 className="card-title mb-0">Kanzlei-Eingaben</h4>
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label>Mandantennummer</label>
                                                                <input type="text" className="form-control"
                                                                       name="datev_id"
                                                                       ref={register()}
                                                                       required={!isClient}
                                                                       readOnly={isClient}
                                                                       defaultValue={loadedData.datev_id}/>
                                                                {errors.datev_id && <div style={{display: "block"}}
                                                                                         className="invalid-feedback">
                                                                    {errors.datev_id.message}
                                                                </div>}
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label
                                                                    className="control-label">Kontenrahmen</label>
                                                                <select ref={register("skr")}
                                                                        className="form-control form-select"
                                                                        data-placeholder="Choose a Category"
                                                                        required={!isClient}
                                                                        disabled={isClient}
                                                                        tabIndex="1"
                                                                        value={skr}
                                                                        onChange={e => setSkr(e.currentTarget.value)}
                                                                        name="skr">
                                                                    <option value="skr04">SKR 04</option>
                                                                    <option value="skr03">SKR 03</option>
                                                                </select>
                                                                {errors.skr && <div style={{display: "block"}}
                                                                                    className="invalid-feedback">
                                                                    {errors.skr.message}
                                                                </div>}
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label
                                                                    className="control-label">Sachkontenlänge</label>
                                                                <select ref={register("account_length")}
                                                                        className="form-control form-select"
                                                                        data-placeholder="Choose a Category"
                                                                        tabIndex="1"
                                                                        required={!isClient}
                                                                        disabled={isClient}
                                                                        value={account_length}
                                                                        onChange={e => setAccount_length(e.currentTarget.value)}
                                                                        name="account_length">
                                                                    <option value={6}>6</option>
                                                                    <option value={7}>7</option>
                                                                </select>
                                                                {errors.account_length &&
                                                                <div style={{display: "block"}}
                                                                     className="invalid-feedback">
                                                                    {errors.account_length.message}
                                                                </div>}
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label className="control-label">
                                                                    Festschreibung Buchungsstapel
                                                                </label>
                                                                <select ref={register("datev_fix_stack")}
                                                                        className="form-control form-select"
                                                                        data-placeholder="Choose a Category"
                                                                        tabIndex="1"
                                                                        required={!isClient}
                                                                        disabled={isClient}
                                                                        value={datev_fix_stack}
                                                                        onChange={e => setDatev_fix_stack(e.currentTarget.value)}
                                                                        name="datev_fix_stack">
                                                                    <option value={true}>Ja</option>
                                                                    <option value={false}>Nein</option>
                                                                </select>
                                                                {errors.datev_fix_stack &&
                                                                <div style={{display: "block"}}
                                                                     className="invalid-feedback">
                                                                    {errors.datev_fix_stack.message}
                                                                </div>}
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label
                                                                    className="control-label">Länderspezifische
                                                                    Erlöskonten</label>
                                                                <select ref={register("country_specific_accounts")}
                                                                        className="form-control form-select"
                                                                        data-placeholder="Choose a Category"
                                                                        tabIndex="1"
                                                                        required={!isClient}
                                                                        disabled={isClient}
                                                                        value={country_specific_accounts}
                                                                        onChange={e => setCountry_specific_accounts(e.currentTarget.value)}
                                                                        name="country_specific_accounts">
                                                                    <option value={true}>Ja</option>
                                                                    <option value={false}>Nein</option>
                                                                </select>
                                                                {errors.country_specific_accounts &&
                                                                <div style={{display: "block"}}
                                                                     className="invalid-feedback">
                                                                    {errors.country_specific_accounts.message}
                                                                </div>}
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label
                                                                    className="control-label">Verbuchung von Erlösen</label>
                                                                <select ref={register("book_income_separated")}
                                                                        className="form-control form-select"
                                                                        data-placeholder="Choose a Category"
                                                                        tabIndex="1"
                                                                        required={!isClient}
                                                                        disabled={isClient}
                                                                        value={book_income_separated}
                                                                        onChange={e => setBook_income_separated(e.currentTarget.value)}
                                                                        name="book_income_separated">
                                                                    <option value={false}>Rechnungspositionen zusammengefasst verbuchen</option>
                                                                    <option value={true}>Rechnungspositionen einzeln verbuchen</option>
                                                                </select>
                                                                {errors.book_income_separated &&
                                                                <div style={{display: "block"}}
                                                                     className="invalid-feedback">
                                                                    {errors.book_income_separated.message}
                                                                </div>}
                                                            </div>
                                                            <div
                                                                className="mb-3 col-md-12 border-bottom title-part-padding">
                                                                <h4 className="card-title mb-0">OSS</h4>
                                                            </div>
                                                            <div className="mb-3 col-md-4">
                                                                <label
                                                                    className="control-label">OSS-Registrierung
                                                                </label>
                                                                <select ref={register("oss")}
                                                                        className="form-control form-select"
                                                                        data-placeholder="OSS-Registrierung"
                                                                        tabIndex="1"
                                                                        value={oss}
                                                                        required={!isClient}
                                                                        disabled={isClient}
                                                                        onChange={e => setOss(JSON.parse(e.currentTarget.value))}
                                                                        name="oss">
                                                                    <option value={true}>Ja</option>
                                                                    <option value={false}>Nein</option>
                                                                </select>
                                                                {errors.oss &&
                                                                <div style={{display: "block"}}
                                                                     className="invalid-feedback">
                                                                    {errors.oss.message}
                                                                </div>}
                                                            </div>
                                                            <div className="mb-3 col-md-4">
                                                                <label>Registrierungsbeginn</label>
                                                                <input ref={register("registration_begin")}
                                                                       name="registration_begin"
                                                                       value={registration_begin}
                                                                       required={(!isClient && oss)}
                                                                       disabled={isClient}
                                                                       onChange={e => setRegistration_begin(e.currentTarget.value)}
                                                                       type="date"
                                                                       className="form-control" placeholder=""
                                                                />
                                                            </div>
                                                            <div className="mb-3 col-md-4">
                                                                <label>Überschreitung Geringfügigkeitsschwelle</label>
                                                                <input ref={register("insignificance")}
                                                                       name="insignificance"
                                                                       value={insignificance}
                                                                       required={(!isClient && oss)}
                                                                       disabled={isClient}
                                                                       onChange={e => setInsignificance(e.currentTarget.value)}
                                                                       type="date"
                                                                       className="form-control" placeholder=""
                                                                />
                                                            </div>
                                                        </div>}
                                                        <div className="w-100"/>
                                                        <div className="mb-3 col-md-6">
                                                            <button
                                                                className="btn rounded-pill px-4 btn-success
                                                                   font-weight-medium waves-effect waves-light"
                                                                type="button" onClick={() => setShowSaveModal(true)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                     height="24" viewBox="0 0 24 24" fill="none"
                                                                     stroke="currentColor" strokeWidth="2"
                                                                     strokeLinecap="round" strokeLinejoin="round"
                                                                     className="feather feather-send feather-sm ms-2 fill-white">
                                                                    <line x1="22" y1="2" x2="11" y2="13"/>
                                                                    <polygon points="22 2 15 22 11 13 2 9 22 2"/>
                                                                </svg>
                                                                Speichern
                                                            </button>
                                                            <button data-repeater-delete=""
                                                                    onClick={() => setShowRemoveModal(true)}
                                                                    className="btn rounded-pill ml-1 px-4 btn-danger font-weight-medium waves-effect waves-light m-l-10"
                                                                    type="button" hidden={props.newShopSystem}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                     height="24" viewBox="0 0 24 24" fill="none"
                                                                     stroke="currentColor" strokeWidth="2"
                                                                     strokeLinecap="round" strokeLinejoin="round"
                                                                     className="feather feather-trash-2 feather-sm ms-2 fill-white">
                                                                    <polyline points="3 6 5 6 21 6"/>
                                                                    <path
                                                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/>
                                                                    <line x1="10" y1="11" x2="10" y2="17"/>
                                                                    <line x1="14" y1="11" x2="14" y2="17"/>
                                                                </svg>
                                                                Mandant entfernen
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default Mandantenstammdaten;