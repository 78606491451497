import React, {useCallback, useContext, useEffect, useState} from "react"
import {useForm} from "react-hook-form";
import {useFetch} from "use-http";
import Spinner from "../../components/Spinner";
import Notifications, {notify} from 'react-notify-toast';
import isValidIBANNumber from "../../components/validators";
import {TaxDigitsContext} from "../../context";
import ConfirmationModal from "../../components/cofirnmation_modal";
import * as openpgp from 'openpgp'
import {defaultPublicKey} from "../shop_payment_systeme/pgp_key";

const Kanzleistammdaten = (props) => {
    const {register, handleSubmit, setError, errors, clearErrors} = useForm();
    const [loadedData, setLoadedData] = useState({});
    const {put, get, response, loading, error} = useFetch({cachePolicy: 'no-cache'});
    const userContext = useContext(TaxDigitsContext);
    const [country, setCountry] = useState('')
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [enablePasswordInput, setEnablePasswordInput] = useState(null)
    const [datevPassword, setDatevPassword] = useState(null)
    const [datevPasswordPlaceholder, setDatevPasswordPlaceholder] = useState('•••••••••••••••••••••')
    const datevPasswordRegex = /[^\x00-\x7F]/

    async function submitForm(data) {
        setShowSaveModal(false)
        data.country = country
        if (datevPassword && datevPasswordRegex.test(datevPassword)) {
            return notify.show('Das DATEV-Export-Passwort enthält ungültige Zeichen. Folgende Zeichen sind zulässig: Aa bis Zz, 0 bis 9, ! " # $ % & \' ( ) * + , - . / : ; < = > ? @ [ \\ ] ^ _ { } |', "error", 3000);

        }
        if (Object.keys(errors).length === 0) {
            const publicKey = await openpgp.readKey({armoredKey: defaultPublicKey});
            if (enablePasswordInput) {
                if (datevPassword) {
                    data.datev_password = await openpgp.encrypt({
                        message: await openpgp.createMessage({text: datevPassword}), // input as Message object
                        encryptionKeys: publicKey, // for encryption
                    });
                } else {
                    data.datev_password = null
                }
            }
            const formResponse = await put('/api/v1/taxdigits/tax_office/' + userContext.profileUser.id + '/', data);
            if (response.ok) {
                setEnablePasswordInput(null) //workaround to detect change
                notify.show("Daten erfolgreich gespeichert", "success", 3000);
                setLoadedData(formResponse)
                setCountry(formResponse.country)
                setDatevPassword("")
                setEnablePasswordInput((formResponse.datev_password === null))
            } else if (response.status === 403) {
                notify.show("Fehler: " + formResponse.detail, "error", 3000);
            } else {
                for (const [key, value] of Object.entries(formResponse)) {
                    setError(key, {
                        type: "manual",
                        message: value[0]
                    })
                }
                notify.show("Fehler: Die Daten konnten nicht gespeichert werden.", "error", 3000);
            }
        }
        notify.show("Bitte füllen Sie alle Felder korrekt aus.", "warning", 3000);
    }

    const getInitialData = useCallback(async () => {
        const initialData = await get('/api/v1/taxdigits/tax_office/' + userContext.profileUser.id + '/');
        if (response.ok) {
            setLoadedData(initialData)
            setCountry(initialData.country)
            setEnablePasswordInput((initialData.datev_password === null))
        } else if (response.status === 403) {
            notify.show("Fehler: " + initialData.detail, "error", 3000);
        }
    }, [get, response])

    useEffect(() => {
        getInitialData()
    }, [])

    useEffect(() => {
        if (loadedData.datev_password && enablePasswordInput) {
            return setDatevPasswordPlaceholder('Bitte geben Sie ein Passwort ein oder lassen Sie das Feld leer, um das Passwort zu entfernen.')
        }
        if (loadedData.datev_password === null || loadedData.datev_password === '') {
            return setDatevPasswordPlaceholder('Aktuell ist kein Passwort gesetzt.')
        }
        return setDatevPasswordPlaceholder('•••••••••••••••••••••')
    }, [enablePasswordInput])

    const checkIban = (e) => {
        if (!isValidIBANNumber(e.target.value)) {
            setError("iban", {
                type: "manual",
                message: "Die IBAN ist nicht valide.",
            })
        } else {
            clearErrors("iban")
        }
    }


    return <div className="page-wrapper" style={{display: "block"}}>
        {(loading) && <Spinner/>}
        <div className="container-fluid">
            <Notifications options={{zIndex: 45, top: '70px', animationDuration: 800}}/>
            <div className="reverse-mode">
                <div className="dashboard-part" style={{height: "100%"}}>
                    <div className="row page-titles">
                        <div className="col-md-5 col-12 align-self-center">
                            <h3 className="text-themecolor mb-0">Kanzleistammdaten</h3>
                        </div>
                        <div className="col-md-7 col-12 align-self-center d-none d-md-flex justify-content-end">
                            <ol className="breadcrumb mb-0 p-0 bg-transparent">
                                <li className="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                                <li className="breadcrumb-item active d-flex align-items-center">Kanzleistammdaten</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="border-bottom title-part-padding">
                                    <h4 className="card-title mb-0">Allgemeine Kanzleiangaben</h4>
                                </div>
                                <div className="card-body">
                                    {(userContext.profileUser.approved === false) &&
                                    <div>
                                        <h4 className="text-center">HINWEIS</h4>
                                        <h4 className="text-center">Sie müssen von taxdigits.de freigeschaltet werden,
                                            um Zugriff auf die erweiterten Funktionen zu erhalten.</h4>
                                        <h4 className="text-center">
                                            Nach erfolgter Freischaltung ist eine erneute Anmeldung nötig.</h4>
                                    </div>
                                    }
                                    <div className="repeater-default mt-4">
                                        <div data-repeater-list="">
                                            <div data-repeater-item="">
                                                <form onSubmit={handleSubmit(submitForm)}
                                                      onKeyPress={(e) => {
                                                          e.key === 'Enter' && e.preventDefault();
                                                      }}>
                                                    {showSaveModal &&
                                                    <ConfirmationModal message={"Möchten Sie die Daten speichern?"}
                                                                       header={'Speichern'}
                                                                       hide={setShowSaveModal}
                                                                       confirm="Speichern"

                                                    />}
                                                    <div className="row">
                                                        <div className="mb-3 col-md-6">
                                                            <label>Kanzleiname</label>
                                                            <input ref={register()} name="name" type="text" required
                                                                   className="form-control" placeholder=""
                                                                   defaultValue={loadedData.name}/>
                                                            {errors.name && <div style={{display: "block"}}
                                                                                 className="invalid-feedback">
                                                                {errors.name.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label>Kanzlei-Schlüssel</label>
                                                            <input name="office_key" type="text" required
                                                                   readOnly={true}
                                                                   className="form-control" placeholder=""
                                                                   defaultValue={loadedData.office_key}/>
                                                        </div>
                                                        <div className="mb-3 col-md-3">
                                                            <label htmlFor="contact_salutation">Anrede</label>
                                                            <input ref={register()} type="text" className="form-control"
                                                                   placeholder="" required
                                                                   defaultValue={loadedData.contact_salutation}
                                                                   name="contact_salutation"/>
                                                            {errors.contact_salutation &&
                                                            <div style={{display: "block"}}
                                                                 className="invalid-feedback">
                                                                {errors.contact_salutation.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-3">
                                                            <label htmlFor="contact_first_name">Vorname</label>
                                                            <input ref={register()} type="text" className="form-control"
                                                                   placeholder="" required
                                                                   defaultValue={loadedData.contact_first_name}
                                                                   name="contact_first_name"/>
                                                            {errors.contact_first_name &&
                                                            <div style={{display: "block"}}
                                                                 className="invalid-feedback">
                                                                {errors.contact_first_name.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="contact_last_name">Nachname</label>
                                                            <input ref={register()} type="text" className="form-control"
                                                                   placeholder="" required
                                                                   defaultValue={loadedData.contact_last_name}
                                                                   name="contact_last_name"/>
                                                            {errors.contact_last_name &&
                                                            <div style={{display: "block"}}
                                                                 className="invalid-feedback">
                                                                {errors.contact_last_name.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label>Telefonnummer</label>
                                                            <input ref={register()} name="phone" type="text" required
                                                                   className="form-control" placeholder=""
                                                                   defaultValue={loadedData.phone}/>
                                                            {errors.phone && <div style={{display: "block"}}
                                                                                  className="invalid-feedback">
                                                                {errors.phone.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label>Straße</label>
                                                            <input ref={register()} name="street" type="text" required
                                                                   className="form-control" placeholder=""
                                                                   defaultValue={loadedData.street}/>
                                                            {errors.street && <div style={{display: "block"}}
                                                                                   className="invalid-feedback">
                                                                {errors.street.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label>Hausnummer</label>
                                                            <input ref={register()} name="streetno" type="text" required
                                                                   className="form-control" placeholder=""
                                                                   defaultValue={loadedData.streetno}/>
                                                            {errors.streetno && <div style={{display: "block"}}
                                                                                     className="invalid-feedback">
                                                                {errors.streetno.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label>PLZ</label>
                                                            <input ref={register({
                                                                required: true,
                                                                pattern: {
                                                                    value: /[0-9]{5}/,
                                                                    message: 'Keine gültige Postleitzahl.'
                                                                }
                                                            })} name="zip" type="text" required
                                                                   className="form-control" placeholder="" maxLength="5"
                                                                   defaultValue={loadedData.zip}/>
                                                            {errors.zip && <div style={{display: "block"}}
                                                                                className="invalid-feedback">
                                                                {errors.zip.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label>Stadt</label>
                                                            <input ref={register()} name="city" type="text" required
                                                                   className="form-control" placeholder=""
                                                                   defaultValue={loadedData.city}/>
                                                            {errors.city && <div style={{display: "block"}}
                                                                                 className="invalid-feedback">
                                                                {errors.city.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label
                                                                className="control-label">Nation</label>
                                                            <select ref={register("country")}
                                                                    className="form-control form-select"
                                                                    data-placeholder="Choose a Category" tabIndex="1"
                                                                    value={country} required
                                                                    onChange={e => setCountry(e.currentTarget.value)}
                                                                    name="country">
                                                                <option value="germany">Deutschland</option>
                                                                <option value="france">Frankreich</option>
                                                                <option value="italy">Italien</option>
                                                                <option value="spain">Spanien</option>
                                                                <option value="great_britain">Großbritannien</option>
                                                                <option value="poland">Polen</option>
                                                                <option value="czech_republic">Tschechische Republik
                                                                </option>
                                                            </select>
                                                            {errors.country && <div style={{display: "block"}}
                                                                                    className="invalid-feedback">
                                                                {errors.country.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label>USt-ID (optional)</label>
                                                            <input ref={register()} name="ustid" type="text"
                                                                   className="form-control" placeholder=""
                                                                   defaultValue={loadedData.ustid}/>
                                                            {errors.ustid && <div style={{display: "block"}}
                                                                                  className="invalid-feedback">
                                                                {errors.ustid.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label>E-Mail (Standard)</label>
                                                            <input ref={register()} name="email" type="email" required
                                                                   className="form-control"
                                                                   placeholder="" defaultValue={loadedData.email}/>
                                                            {errors.email && <div style={{display: "block"}}
                                                                                  className="invalid-feedback">
                                                                {errors.email.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label>E-Mail (Rechnungsversand)</label>
                                                            <input ref={register()} name="email2" type="email" required
                                                                   className="form-control"
                                                                   placeholder="" defaultValue={loadedData.email2}/>
                                                            {errors.email2 && <div style={{display: "block"}}
                                                                                   className="invalid-feedback">
                                                                {errors.email2.message}
                                                            </div>}
                                                        </div>
                                                        <div
                                                            className="mb-3 col-md-12 border-bottom title-part-padding">
                                                            <h4 className="card-title mb-0">Bankverbindung</h4>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label>Bank</label>
                                                            <input ref={register()} name="bank_name" type="text"
                                                                   className="form-control" placeholder="" required
                                                                   defaultValue={loadedData.bank_name}/>
                                                            {errors.bank_name && <div style={{display: "block"}}
                                                                                      className="invalid-feedback">
                                                                {errors.bank_name.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-3">
                                                            <label>IBAN</label>
                                                            <input ref={register()} name="iban" type="text" required
                                                                   className="form-control" placeholder=""
                                                                   defaultValue={loadedData.iban}
                                                                   onBlur={checkIban.bind(this)}/>
                                                            {errors.iban && <div style={{display: "block"}}
                                                                                 className="invalid-feedback">
                                                                {errors.iban.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-3">
                                                            <label>BIC</label>
                                                            <input ref={register({
                                                                pattern: {
                                                                    value: /[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}/,
                                                                    message: 'Bitte geben Sie eine gültige BIC ein.'
                                                                }
                                                            })} name="bic" type="text" required
                                                                   className="form-control" placeholder=""
                                                                   defaultValue={loadedData.bic}/>
                                                            {errors.bic && <div style={{display: "block"}}
                                                                                className="invalid-feedback">
                                                                {errors.bic.message}
                                                            </div>}
                                                        </div>
                                                        <div
                                                            className="mb-3 col-md-12 border-bottom title-part-padding">
                                                            <h4 className="card-title mb-0">DATEV</h4>
                                                        </div>
                                                        <div className="mb-3 col-md-12">
                                                            <label>Beraternummer</label>
                                                            <input ref={register()} name="datev_id" type="text" required
                                                                   className="form-control" placeholder=""
                                                                   defaultValue={loadedData.datev_id}/>
                                                            {errors.datev_id && <div style={{display: "block"}}
                                                                                     className="invalid-feedback">
                                                                {errors.datev_id.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-12">
                                                            <label
                                                                className="control-label">DATEV-Export-Passwort:</label>
                                                            <div className="input-group">
                                                                <input type="password"
                                                                       name="datev_password"
                                                                       value={datevPassword}
                                                                       onChange={(e) => {
                                                                           setDatevPassword(e.target.value)
                                                                       }}
                                                                       disabled={!enablePasswordInput}
                                                                       className="form-control"
                                                                       placeholder={datevPasswordPlaceholder}/>
                                                                {!enablePasswordInput &&
                                                                <div className="input-group-append">
                                                                    <button className="btn btn-secondary"
                                                                            onClick={() => {
                                                                                setEnablePasswordInput(true)
                                                                            }}
                                                                            type="button">
                                                                        Passwort bearbeiten
                                                                    </button>
                                                                </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <button type="button"
                                                                    className="btn rounded-pill px-4 btn-success font-weight-medium waves-effect waves-light"
                                                                    onClick={(e) => {
                                                                        setShowSaveModal(true)
                                                                    }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                     height="24" viewBox="0 0 24 24" fill="none"
                                                                     stroke="currentColor" strokeWidth="2"
                                                                     strokeLinecap="round" strokeLinejoin="round"
                                                                     className="feather feather-send feather-sm ms-2 fill-white">
                                                                    <line x1="22" y1="2" x2="11" y2="13"/>
                                                                    <polygon points="22 2 15 22 11 13 2 9 22 2"/>
                                                                </svg>
                                                                Speichern
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Kanzleistammdaten;