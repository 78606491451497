import React, {useState} from "react";
import {useFetch} from "use-http";
import Spinner from "../../components/Spinner";
import {notify} from "react-notify-toast";

const RequestCard = (props) => {
    const [initialData, setInitialData] = useState(props.data);
    const [removed, setRemoved] = useState(false);
    const {put, response, loading, error} = useFetch({cachePolicy: 'no-cache'});
    const setState = async (state) => {
        const acceptResponse = await put('/api/v1/taxdigits/client_requests/' + initialData.id + '/', {request_state: state});
        if (response.ok) {
            setRemoved(true)
            props.reloadClient()
        } else if (response.status === 403) {
            notify.show("Fehler: " + acceptResponse.detail, "error", 3000);
        } else {
            props.showRequestToast()
        }
    }

    if (removed) {
        return null
    }
    return <div className="row col-md-12 mr-auto request-items">
        {(loading) && <Spinner/>}
        <div className="request-item-value-container">
            <div className="request-item-value">
                {initialData.contact_salutation + " " + initialData.contact_first_name + " " + initialData.contact_last_name + " - " + initialData.name}
            </div>
        </div>
        <div className="btn-toolbar py-1">
            <button type="button" className="btn mx-1 btn-danger" onClick={() => {
                setState("none")
            }}>
                Anfrage ablehnen
            </button>
            <button type="button" className=" btn mx-1 btn-success" onClick={() => {
                setState("accepted")
            }}>
                Anfrage annehmen
            </button>
        </div>
    </div>
}
export default RequestCard;
