import React from "react"
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import BankKonverter from "./bank_konverter";

const BankKonverterPage = (props) => {
    return <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
     data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
        <Header/>
        <Sidebar/>
        <BankKonverter/>
        </div>;
}

export default BankKonverterPage;