import React, {useCallback, useState, useEffect, useContext} from "react";
import Table from "../../components/table"
import {notify} from "react-notify-toast";
import {useFetch} from "use-http";
import {useForm} from "react-hook-form";
import ConfirmationModal from "../../components/cofirnmation_modal";
import {TaxDigitsContext} from "../../context";

const Download = (props) => {
    const {register, handleSubmit, watch, reset, errors, clearErrors} = useForm();
    const userContext = useContext(TaxDigitsContext);
    const [file_data, setFileData] = useState(null)
    const [timePeriodsTransaction, setTimePeriodsTransaction] = useState([])
    const [timePeriodsPayment, setTimePeriodsPayment] = useState([])
    const [selectedTimePeriodTransaction, setSelectedTimePeriodTransaction] = useState("Null")
    const [selectedTimePeriodPayment, setSelectedTimePeriodPayment] = useState("Null")
    const {post, put, del, get, response, loading, error} = useFetch({cachePolicy: 'no-cache'}, opts => {
        delete opts.headers['Content-Type']
        return opts
    });
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState()
    let query = ''
    let clientId = null
    if (userContext.selectedClient) {
        query = '?company_id=' + userContext.selectedClient.id
    }


    useEffect(() => {
        if (userContext.selectedClient) {
            clientId = userContext.selectedClient.id
        } else {
            clientId = userContext.profileUser.company
        }
        getTimePeriods()
        getData()
    }, [])



    const getData = useCallback(async (name = null) => {
        let extra_query = '' + query
        const initialData = await get('/api/v1/taxdigits/company_reports/' + extra_query);
        if (response.ok) {
            setFileData(initialData)
        } else if (response.status === 403) {
            notify.show("Fehler: " + initialData.detail, "error", 3000);
        }
    }, [get, response])

    const getTimePeriods = useCallback(async (name = null) => {
        const initialData = await get('/api/v1/taxdigits/company_download_period/' + clientId + '/');
        if (response.ok) {
            let payments = []
            let transactions = []
            initialData.forEach(e => {
                if (e.type === 'transactions') {
                    transactions.push(e)
                } else {
                    payments.push(e)
                }
            })
            setTimePeriodsTransaction(transactions)
            setTimePeriodsPayment(payments)
        } else if (response.status === 403) {
            notify.show("Fehler: " + initialData.detail, "error", 3000);
        }
    }, [get, response])

    async function submitForm(report_type, selectedTimePeriod) {
        setShowSaveModal(false)
        const upload_data = new FormData()
        upload_data.append('company', userContext.selectedClient.id)
        upload_data.append('report_type', report_type)
        upload_data.append('time_period', selectedTimePeriod)
        const form_response = await post('/api/v1/taxdigits/company_reports/' + query, upload_data);
        if (response.ok) {
            reset()
            getData()
            notify.show("Daten erfolgreich gespeichert", "success", 3000);
        } else if (response.status === 400) {
            notify.show(form_response[Object.keys(form_response)[0]][0], "error", 3000);
        }
    }

    const createTransactionReport = () => {
        submitForm('transactions', selectedTimePeriodTransaction)
        setSelectedTimePeriodTransaction('Null')
    }

    const createPaymentReport = () => {
        submitForm('payments', selectedTimePeriodPayment)
        setSelectedTimePeriodPayment('Null')
    }

    const deleteData = async (id) => {
        setShowDeleteModal(false)
        const deleteData = await del('/api/v1/taxdigits/company_reports/' + id + '/' + query)
        if (response.ok) {
            getData()
            notify.show("Daten erfolgreich entfernt.", "success", 3000);
        } else {
            notify.show("Beim löschen ist ein Fehler aufgetreten.", "error", 3000);
        }
    }

    const downloadFile = async (id, name) => {
        const file = await get('/api/v1/taxdigits/company_reports/' + id + '/' + query)
        if (response.ok) {
            const blob = await response.blob()
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name)
            document.body.appendChild(link);
            link.click();
            getData()
        } else {
            notify.show("Beim download ist ein Fehler aufgetreten.", "error", 30000000);
        }
    }

    const report_types = {
        transactions: 'Erlöse',
        payments: 'Zahlungen',
    }

    const name_choices = {
        paypal: 'PayPal',
        klarna: 'Klarna',
        stripe: 'Stripe',
        shopify: 'Shopify',
        ebay: 'ebay',
        amazon: 'Amazon',
        mango: 'Bookingkit-Mango',
        bk_stripe: 'Bookingkit-Stripe',
        bk_reach: 'Bookingkit-Reach',
        avocado: 'Avocadostore',
        n26: 'N26',
        n26_converter: 'N26 Kontoauszug-Konverter',
    }

    const states = {
        pending: 'Steht aus',
        in_progress: 'In Arbeit',
        available: 'Verfügbar',
        canceled: 'Abgebrochen',
        deleted: 'Gelöscht',
    }

    const date_time_cell = (cell) => {
        if (cell.value === null) {
            return <span>Noch nicht erzeugt</span>
        }
        let date = new Date(cell.value)
        const day = ('0' + (date.getDate())).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const hours = ('0' + (date.getHours())).slice(-2);
        const minutes = ('0' + (date.getMinutes())).slice(-2);
        return <span>{day}.{month}.{date.getFullYear()} - {hours}:{minutes}</span>
    }
    const date_cell = (cell) => {
        return <span>{monthsMapping[cell.value.month]} {cell.value.year}</span>
    }
    const delete_cell = (cell) => {
        return <button onClick={() => {
            setShowDeleteModal(true)
            setDeleteId(cell.row.values['id'])
        }} className='btn btn-danger btn-sm'>Eintrag löschen</button>
    }

    const download_cell = (cell) => {
        if (cell.row.values['state'] !== 'available') {
            return ""
        }
        return <button
            disabled={cell.row.values['state'] !== 'available'}
            onClick={() => {
                const file_name = cell.row.values['file']
                downloadFile(cell.row.values['id'], file_name)
            }}
            className={`${cell.row.values['download_counter'] === 0 ? 'cell-btn btn btn-primary btn-sm' : 'cell-btn btn btn-secondary btn-sm'}`}>
            Download
        </button>

    }

    const monthsMapping = {
        'january': 'Januar',
        'february': 'Februar',
        'march': 'März',
        'april': 'April',
        'may': 'Mai',
        'june': 'Juni',
        'july': 'Juli',
        'august': 'August',
        'september': 'September',
        'october': 'Oktober',
        'november': 'November',
        'december': 'Dezember',
    }

    const columns = [
        {
            Header: 'Downloads', accessor: 'download_counter', Cell: (cell) => {
                return <span>{cell.value}</span>
            }
        },
        {Header: 'Zeitraum/Monat', accessor: 'time_period_details', Cell: date_cell},
        {Header: 'Angefragt am', accessor: 'request_date', Cell: date_time_cell},
        {Header: 'Erstellt am', accessor: 'generated_at', Cell: date_time_cell},
        {
            Header: 'Typ', accessor: 'report_type', Cell: (cell) => {
                if (cell.value) {
                    return <span>{Object.assign(report_types, name_choices)[cell.value]}</span>
                }
                return <span> - </span>
            },
        },
        {
            Header: 'Status', accessor: 'state', Cell: (cell) => {
                if (cell.value) {
                    return <span>{states[cell.value]}</span>
                }
                return <span>-</span>
            }
        },
        {
            Header: 'Download', accessor: 'id', Cell: download_cell
        },
        {
            Header: 'Löschen', accessor: 'file', Cell: delete_cell
        },
    ]
    return <div className="page-wrapper" style={{display: "block"}}>
        {showDeleteModal &&
        <ConfirmationModal message={"Möchten Sie den Eintrag wirklich löschen?"}
                           header={'Löschen'}
                           hide={setShowDeleteModal}
                           confirm="Löschen"
                           submitFunction={deleteData}
                           data={deleteId}

        />}
        <div className="container-fluid">
            <div className="reverse-mode">
                <div className="dashboard-part" style={{height: "100%"}}>
                    <div className="row page-titles">
                        <div className="col-md-5 col-12 align-self-center">
                            <h3 className="text-themecolor mb-0">Bericht-Download</h3>
                        </div>
                        <div className="col-md-7 col-12 align-self-center d-none d-md-flex justify-content-end">
                            <ol className="breadcrumb mb-0 p-0 bg-transparent">
                                <li className="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                                <li className="breadcrumb-item active d-flex align-items-center">Download</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="border-bottom title-part-padding">
                                    <h4 className="card-title mb-0">Download</h4>
                                </div>
                                <div className="card-body">
                                    <div className="repeater-default">
                                        <h3>DATEV-Export erzeugen:</h3>
                                        <form onSubmit={handleSubmit(submitForm)}
                                              onKeyPress={(e) => {
                                                  e.key === 'Enter' && e.preventDefault();
                                              }}>
                                            {showSaveModal &&
                                            <ConfirmationModal message={"Möchten Sie den Bericht anfragen?"}
                                                               header={'Speichern'}
                                                               hide={setShowSaveModal}
                                                               confirm="Speichern"

                                            />}
                                            <table className="table table-borderless">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Bericht-Typ</th>
                                                    <th scope="col">Zeitraum/Monat</th>
                                                    <th scope="col"></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>Erlöse</td>
                                                    <td>
                                                        <select className="form-control form-select height-39"
                                                                data-placeholder="Choose a Category" tabIndex="1"
                                                                value={selectedTimePeriodTransaction}
                                                                onChange={e => {
                                                                    setSelectedTimePeriodTransaction(e.currentTarget.value)
                                                                }
                                                                }
                                                                name="name">
                                                            <option value={"Null"}>-</option>
                                                            {timePeriodsTransaction.map(function (e) {
                                                                return <option
                                                                    value={e.id}>{monthsMapping[e.month]} {e.year}</option>
                                                            })}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <button type="button"
                                                                className="width-250 float-right height-39 px-4 btn rounded-pill btn-success font-weight-medium waves-effect waves-light"
                                                                disabled={selectedTimePeriodTransaction === "Null"}
                                                                onClick={createTransactionReport}>
                                                            Erlös-Export erzeugen
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Zahlungen</td>
                                                    <td>
                                                        <select className="form-control form-select height-39"
                                                                data-placeholder="Choose a Category" tabIndex="1"
                                                                value={selectedTimePeriodPayment}
                                                                onChange={e => {
                                                                    setSelectedTimePeriodPayment(e.currentTarget.value)
                                                                }
                                                                }
                                                                name="name">
                                                            <option value={"Null"}>-</option>
                                                            {timePeriodsPayment.map(function (e) {
                                                                return <option
                                                                    value={e.id}>{monthsMapping[e.month]} {e.year}</option>
                                                            })}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <button type="button"
                                                                className="width-250 float-right height-39 px-4 btn rounded-pill btn-success font-weight-medium waves-effect waves-light"
                                                                disabled={selectedTimePeriodPayment === "Null"}
                                                                onClick={createPaymentReport}>
                                                            Zahlungs-Export erzeugen
                                                        </button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                        {file_data !== null &&
                                        <div className="mt-5" data-repeater-list="">
                                            <div data-repeater-item="">
                                                <div className="row">
                                                    <div className="col-sm-12 mt-3 table-responsive">
                                                        <Table
                                                            columns={columns}
                                                            data={file_data}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        ;
}

export default Download;