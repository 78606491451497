import React from "react";
import {useRowSelect, useTable} from 'react-table'

// Create an editable cell renderer
const EditableCell = ({
                          value: initialValue,
                          row: {index},
                          column: {id},
                          updateMyData, // This is a custom function that we supplied to our table instance
                      }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)

    const onChange = e => {
        setValue(e.target.value.substring(0, 8))
    }

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        if (updateMyData) {
            updateMyData(index, id, value)
        }
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    return <input onWheel={(e) => e.target.blur()} type="number" value={value || ''} onChange={onChange}
                  onBlur={onBlur}/>
}

const FooterCell = (props) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(props.value)

    const onChange = e => {
        setValue(e.target.value.substring(0, 5))
    }

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        props.blurFunction(value, props.accessor)
    }

    // If the value is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(props.value)
    }, [props.value])
    if (!props.editable) {
        return <th>{props.value}</th>
    }
    return <th className="footer-cell">
        <input onWheel={(e) => e.target.blur()} type="number" pattern="[0-9]" maxLength="5" value={value || ''}
               onChange={onChange} onBlur={onBlur}/>
    </th>
}


const Button = () => {
    return <Button> Test </Button>
}

const defaultColumn = {
    Cell: EditableCell,
}

const extraButton = {
    Cell: Button,
}


const Table = (props) => {
    const updateMyData = (rowIndex, columnId, value) => {
        props.setData(old =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    }
                }
                return row
            })
        )
    }
    const columns = React.useMemo(
        () => props.columns, [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        footerGroups,
        prepareRow,
    } = useTable({columns, data: props.data, defaultColumn, extraButton, updateMyData},
        useRowSelect)

    return (
        <table {...getTableProps()} className={'editable-table table-bordered table-striped table table-hover '+props.additionalClasses}>
            <thead className='font-weight-bold'>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                </tr>
            ))}
            {props.footer &&
            <tr>
                {props.footer.map(entry => (
                    <FooterCell accessor={entry.accessor} editable={entry.editable} value={entry.value}
                                blurFunction={entry.blurFunction}
                    />
                ))}
            </tr>
            }
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map(row => {
                prepareRow(row)
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return (
                                <td
                                    {...cell.getCellProps()}
                                >
                                    {cell.render('Cell')}
                                </td>
                            )
                        })}
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}

export default Table;