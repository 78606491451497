export const defaultPublicKey = `
-----BEGIN PGP PUBLIC KEY BLOCK-----

mQINBGDZeZQBEADbl+cgTQFEwIA+AP99hmed2CxO1V/HQlcMrZPIjEvHS8zoy4ul
RNZcTdpn7Qr4AvFSXMINMOoTqcEwl/jjYKjtB27QN7KyXIXGEgmR3B4QSJPovwsT
wY9/hIzXDA6LpE8WvKeYjshg3LFHEQ3tzic3uellTJscteqR0OuacE1/JMID2Cnw
7NP5rDxXa0K4P7wQGAKU6v9HUnRW2F45Ay4yleO1BYiILrDdsQpuF9aIiO6Wz7yE
8KCuCNr2BM1OwGkEC+F41zi3GY7PSDEcTbmfcOxno2if8Mi0vWCBcuCkPfw/3MCm
ii5cgX2XBePCcJStSBRpDHeryQsatRNmmn2Siv6GrllO7VBPMhr5ZEyjyowuGxuL
v+ui2J2M736PJsmfl/z+iPT/wr/3Jb3nIe12nCe98H8xbi3e6IcO/dMw/Gy9CI2+
ZcQkapD/9S8ygvyvm1bLc9ZTOgT50xZ1y4dz/PeTeQkkiYT+A1yKi33pDYjQWay5
WQBWQe17BNok1lKDORFbEqZOwzKZM6Ipe6DvSzqbu0mjAyZ3xD531kL21K2fDi1F
PjFwF0f9xV/uV93QnO6j9a3PMgoU+VnoNqgwkCbMrZD4cYFAUZZiKVJxF2mQR9Qs
icPY1k3cVi0rcnnBjGC5NBRRigjLzHSAMSKQqoVvIvJmoC3RDg25RqCwaQARAQAB
tCZCZXJuZCBQZXNzYXJhIDxiLnBlc3NhcmFAdGF4ZGlnaXRzLmRlPokCTgQTAQoA
OBYhBK8hYZUYYPQY1Tr7+lJ7Dw71HEXSBQJg2XmUAhsDBQsJCAcCBhUKCQgLAgQW
AgMBAh4BAheAAAoJEFJ7Dw71HEXSpbgQAK5lyzul9+mj+N/E0EnK5WW7y/3iNJG1
7YYM7BoYyiDKi2Fi6ODCBt0ikZSnugH01T5O2p1A1PlX583q1Dv3jQgQ5uVGPCUm
ZN6fDDNWnKL29qoodH3JtccSCdwUuF2x9Nx9xCbxiTlPlwfuDlkp5dnzH7c2vjxY
x8afGhebfj/YM17HHnOJeuaCdXNN3NhFtKx89u7oIYF4sHsA7UV7RXhoUVNarCYN
NLsKiqUHRhBXdTC7IpFtNYPO20xBRNm54WOLNe0c4aBjEySlAQJPUtLe3WyBlKi1
58ZS5sJmdCjGUaNZQY8oknp279W7P95HygFkM/zDuxbYBxVbLDpMIlqCj094mb2C
scq0MCvT3ws/yzPEsypuL7rG8k07+PzkaVaoXr7F3X28OduRt4uqhv8pI8y9PyLV
RW5zI+UAeShe10SWSQ/zjUIzFUrglLUUCM+MksdMd0gc7n2eY2Nw5GE5gvQKToge
h0SSvKFDBT23wibChv2OR6acmI1MDBLtNYOh4/rYNq2HJMkhEx3ZDGCqw3Qfaqre
2v1aQaL9uufg+SmdXtCTYVKCtO1RIEZ1B5mm7JSddgUcExs/u0/uXIQY/FGIUNJc
lMaL9tUmN9OO19WsdeC6VPwzOmckWHbDuCC0oxay9uQ0gsC1o50R2YzsUDSLKg42
ALyjVJ8x6QeJuQINBGDZeZQBEADRMKMkLHvoD+aqeHOIE+rKKwdjCRBqffFlvCh1
TBg36BHOYv4IKe5JBPixAOWbtZoQT1WSuVtm3SE17lW6eW1NK9d7AbOgkdR0mhXo
51cHMdWEELt97zgtr6anbEJM4bvwbTrnZ5gKzDA5+hYN8hHiHdofzsJfjJd76a+M
5R6VrP189dA8EFUWrbZqcH1RVSP2qr9wGUOHBf3w9xmYWgWwCls5KsOB6kSh/Uaf
x3hA6umacZlgoI1VT2/fUiH5NOQiQKbIowWQSYUVtulJCG/j1R9HFyKn1pF58/43
Pbu/AHvSOxVGGU0+SRdJH7TEqWePl1cAhSXLwCI9WcYD2Tg2wqlzIphO+vtiQp+d
ffwSv1BriNsuCBjpSDkUXJAcN6EUi2j42+zzsQ0Rn2nRlwNYIO3cZSbmuSY8QLSj
sQhgMQdU1qBgQt/gITOKb3kTd+Pk46l71QatELr5Qp+hxF6hTM3kABOsO6SUP1cm
gYkjVEBr/Oi5iW9unA/zbKWgNa+xfHcqKcHPBNOGirNmjPC+ckhvBBiAyg5TD8L3
W2sZ109f/ZNbNo/vdDm5k0+Dp5gOXdUZi7rfxsTIl8pG6YqT8x1iJhEHiS/n9b0R
zaDOAMu2KFYdrE/ZYOvBDNEJicBn3B0kKLaSmQzJ0a8WcChPSChGODmzbBexVpHm
JpDrKQARAQABiQI2BBgBCgAgFiEEryFhlRhg9BjVOvv6UnsPDvUcRdIFAmDZeZQC
GwwACgkQUnsPDvUcRdKtyQ//UfBvAGK1hYTgpY5t2kl2vi21CNUoxcVdO5rYbu9l
b3Xjp03FNXj+8NEIrIvUXqn1eEEYbRdQ4tfkvOux5bSN3d0HG0xKKMqTCV3GzxEO
g6k2X2qSbB1VkmGnYjHSSU1mVP3U9nk9fchQLgFs/2PSnpJQNFrwIXD8SXgkNYrc
7O4PtnCU+woyvmGwQBSX7sMulF4X47FONd5JwXts7ScWRIM9vHa8oArHBntTMday
1BKSIsp11h5OYI0Y+dY9mPJSTW6cKeYg2gwkM8McBDcZzPOX+E9AFw5o17xO/q48
DPM88Ruf2nuS1TgtW2TK9QVWHRJRPQhGtJYWUgJXDcN29rw62BUQEIlZKN+Y62CY
3h1fCL0OvhfE+ZK/rEmObpby7o7fT2ajoSIj9vVFbaBVeCK8zyKlUv8P9abNb5dq
YQaXeXqgDOX8vjr1G8rYE3S6O/R0ye7aV9WU91YwYfvMBakKucqHH6OBPprC4fGS
A/dA6pIKPsnZ6sMEzEGMOvFIQr9/BgcdY4o9S2P2JDkGmMamu1P5BTc/cwrv7t3U
VP8tj74SL63migZWGnXTtPqwr2l4PZf8uzCT79xiPxtJykbgdJ9r6Gws59xoDa22
lSZo/0Ku3/pwmPYQk8FvJX4VqZ2ZDpRK63rwBr+Zasml7CxsfQbBKMWrP3ffYHbA
1uY=
=YhGQ
-----END PGP PUBLIC KEY BLOCK-----
`
