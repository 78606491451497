import React, {useContext, useEffect} from "react"
import {useFetch} from "use-http";
import {TaxDigitsContext} from "../../context";
import {useHistory} from "react-router";

const LogoutPage = (props) => {
    const SUCCESS_REDIRECT_URL = "/login";
    const history = useHistory();
    const userContext = useContext(TaxDigitsContext);
    const {post, response, loading, error} = useFetch({cachePolicy: 'no-cache'});

    useEffect(() => {
        const doLogout = async () => {
            const res = await post('/api/v1/accounts/logout/');
            if (response.ok) {
                localStorage.removeItem("profileUser");
                localStorage.removeItem("selectedClient");
                userContext.setProfileUser(null);
                userContext.setSelectedClient(null);
            }
            history.push(SUCCESS_REDIRECT_URL);
        }
        doLogout();
    }, []);

    return <div/>;
}

export default LogoutPage;