import React, {useCallback, useEffect, useState} from "react"
import {useForm} from "react-hook-form";
import {useFetch} from "use-http";
import Spinner from "../../components/Spinner";
import Notifications, {notify} from 'react-notify-toast';

const Kanzleianfrage = (props) => {
    const {register, handleSubmit, setError, errors} = useForm();
    const [loadedData, setLoadedData] = useState({});
    const {put, get, response, loading, error} = useFetch({cachePolicy: 'no-cache'});
    const user = JSON.parse(localStorage.getItem("profileUser"))


    async function submitForm(data) {
        const formResponse = await put('/api/v1/taxdigits/tax_office_request/' + user.id + '/', data);
        if (response.ok) {
            notify.show("Eine Kanzleianfrage wurde gestellt.", "success", 3000);
            await getInitialData()
        } else if (response.status === 403) {
            notify.show("Fehler: " + formResponse.detail, "error", 3000);
        } else {
            for (const [key, value] of Object.entries(formResponse)) {
                setError(key, {
                    type: "manual",
                    message: value[0]
                })
            }
            if (Array.isArray(formResponse)) {
                notify.show(formResponse[0], "error", 3000);
            } else {
                notify.show("Anfrage konnte nicht gestellt werden", "error", 3000);
            }
        }
    }

    const getInitialData = useCallback(async () => {
        const initialData = await get('/api/v1/taxdigits/tax_office_request/' + user.id + '/');
        if (response.ok) {
            setLoadedData(initialData)
        } else if (response.status === 403) {
            notify.show("Fehler: " + initialData.detail, "error", 3000);
        }
    }, [get, response])

    const abortRequest = async () => {
        const data = {
            request_state: "none",
            office_key: loadedData.requested_office
        }
        const abortResponse = await put('/api/v1/taxdigits/tax_office_request/' + user.id + '/', data);
        if (response.ok) {
            notify.show("Anfrage wurde storniert.", "success", 3000);
            await getInitialData()
        } else if (response.status === 403) {
            notify.show("Fehler: " + abortResponse.detail, "error", 3000);
        } else {
            notify.show("Ein Fehler ist aufgetreten.", "error", 3000);
        }
    }
    useEffect(() => {
        getInitialData()
    }, [])


    return <div className="page-wrapper" style={{display: "block"}}>
        {(loading) && <Spinner/>}
        <div className="container-fluid">
            <Notifications options={{zIndex: 45, top: '70px', animationDuration: 800}}/>
            <div className="reverse-mode">
                <div className="dashboard-part" style={{height: "100%"}}>
                    <div className="row page-titles">
                        <div className="col-md-5 col-12 align-self-center">
                            <h3 className="text-themecolor mb-0">Kanzleianfrage</h3>
                        </div>
                        <div className="col-md-7 col-12 align-self-center d-none d-md-flex justify-content-end">
                            <ol className="breadcrumb mb-0 p-0 bg-transparent">
                                <li className="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                                <li className="breadcrumb-item active d-flex align-items-center">Kanzleianfrage</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="repeater-default mt-4">
                                        <div data-repeater-list="">
                                            <div data-repeater-item="">
                                                {loadedData.request_state === 'none' &&
                                                <form onSubmit={handleSubmit(submitForm)}
                                                      onKeyPress={(e) => {
                                                          e.key === 'Enter' && e.preventDefault();
                                                      }}>
                                                    <h3>Kanzlei Anfragen:</h3>
                                                    <div className="row">
                                                        <div className="mb-3 col-md-12">
                                                            <label>Kanzleischlüssel</label>
                                                            <input ref={register()} name="office_key" type="text"
                                                                   required
                                                                   className="form-control" placeholder=""
                                                                   defaultValue={loadedData.office_key}/>
                                                            {errors.office_key && <div style={{display: "block"}}
                                                                                       className="invalid-feedback">
                                                                {errors.office_key.message}
                                                            </div>}
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 col-md-12">
                                                        <button
                                                            className="btn rounded-pill px-4 btn-success font-weight-medium waves-effect waves-light"
                                                            type="submit">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 width="24"
                                                                 height="24" viewBox="0 0 24 24" fill="none"
                                                                 stroke="currentColor" strokeWidth="2"
                                                                 strokeLinecap="round"
                                                                 strokeLinejoin="round"
                                                                 className="mr-2 feather feather-send feather-sm fill-white">
                                                                <line x1="22" y1="2" x2="11" y2="13"/>
                                                                <polygon
                                                                    points="22 2 15 22 11 13 2 9 22 2"/>
                                                            </svg>
                                                            Anfragen
                                                        </button>
                                                    </div>
                                                </form>
                                                }
                                                {loadedData.request_state === 'open' &&
                                                <div>
                                                    <h3>Offene Anfrage:</h3>
                                                    <div className="row">
                                                        <div className="mb-3 col-md-12">
                                                            <label>Kanzleischlüssel</label>
                                                            <input name="office_key" type="text"
                                                                   readOnly={true}
                                                                   className="form-control" placeholder=""
                                                                   defaultValue={loadedData.requested_office}/>
                                                            {errors.office_key && <div style={{display: "block"}}
                                                                                       className="invalid-feedback">
                                                                {errors.office_key.message}
                                                            </div>}
                                                        </div>
                                                        <div className="col-md-12">
                                                            <button data-repeater-delete=""
                                                                    onClick={abortRequest}
                                                                    className="btn rounded-pill ml-3 px-4 btn-danger font-weight-medium waves-effect waves-light m-l-10"
                                                                    type="button">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                     height="24" viewBox="0 0 24 24" fill="none"
                                                                     stroke="currentColor" strokeWidth="2"
                                                                     strokeLinecap="round" strokeLinejoin="round"
                                                                     className="feather feather-trash-2 feather-sm ms-2 fill-white">
                                                                    <polyline points="3 6 5 6 21 6"/>
                                                                    <path
                                                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/>
                                                                    <line x1="10" y1="11" x2="10" y2="17"/>
                                                                    <line x1="14" y1="11" x2="14" y2="17"/>
                                                                </svg>
                                                                Anfrage stornieren
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                {loadedData.request_state === 'accepted' &&
                                                <form onSubmit={handleSubmit(submitForm)}
                                                      onKeyPress={(e) => {
                                                          e.key === 'Enter' && e.preventDefault();
                                                      }}>
                                                    <div className="row">
                                                        <h4 className="mb-1 col-md-12">Ansprechpartner</h4>
                                                        <div className="mb-3 col-md-3">
                                                            <label htmlFor="contact_salutation">Anrede</label>
                                                            <input ref={register()} type="text" className="form-control"
                                                                   placeholder="" readOnly
                                                                   defaultValue={loadedData.tax_office.contact_salutation}
                                                                   name="contact_salutation"/>
                                                            {errors.contact_salutation &&
                                                            <div style={{display: "block"}}
                                                                 className="invalid-feedback">
                                                                {errors.contact_salutation.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-3">
                                                            <label htmlFor="contact_first_name">Vorname</label>
                                                            <input ref={register()} type="text" className="form-control"
                                                                   placeholder="" readOnly
                                                                   defaultValue={loadedData.tax_office.contact_first_name}
                                                                   name="contact_first_name"/>
                                                            {errors.contact_first_name &&
                                                            <div style={{display: "block"}}
                                                                 className="invalid-feedback">
                                                                {errors.contact_first_name.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="contact_last_name">Nachname</label>
                                                            <input ref={register()} type="text" className="form-control"
                                                                   placeholder="" readOnly
                                                                   defaultValue={loadedData.tax_office.contact_last_name}
                                                                   name="contact_last_name"/>
                                                            {errors.contact_last_name &&
                                                            <div style={{display: "block"}}
                                                                 className="invalid-feedback">
                                                                {errors.contact_last_name.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label>Kanzleiname</label>
                                                            <input ref={register()} name="name" type="text" readOnly
                                                                   className="form-control" placeholder=""
                                                                   defaultValue={loadedData.tax_office.name}/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label>Kanzlei-Schlüssel</label>
                                                            <input name="office_key" type="text" readOnly
                                                                   className="form-control" placeholder=""
                                                                   defaultValue={loadedData.tax_office.office_key}/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label>Telefonnummer</label>
                                                            <input ref={register()} name="phone" type="text" readOnly
                                                                   className="form-control" placeholder=""
                                                                   defaultValue={loadedData.tax_office.phone}/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label>Straße</label>
                                                            <input ref={register()} name="street" type="text" readOnly
                                                                   className="form-control" placeholder=""
                                                                   defaultValue={loadedData.tax_office.street}/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label>Hausnummer</label>
                                                            <input ref={register()} name="streetno" type="text" readOnly
                                                                   className="form-control" placeholder=""
                                                                   defaultValue={loadedData.tax_office.streetno}/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label>PLZ</label>
                                                            <input ref={register()} name="zip" type="text" readOnly
                                                                   className="form-control" placeholder="" maxLength="5"
                                                                   defaultValue={loadedData.tax_office.zip}/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label>Stadt</label>
                                                            <input ref={register()} name="city" type="text" readOnly
                                                                   className="form-control" placeholder=""
                                                                   defaultValue={loadedData.tax_office.city}/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label
                                                                className="control-label">Nation</label>
                                                            <select ref={register("country")}
                                                                    className="form-control form-select"
                                                                    data-placeholder="Choose a Category" tabIndex="1"
                                                                    value={loadedData.tax_office.country} disabled
                                                                    name="country">
                                                                <option value="germany">Deutschland</option>
                                                                <option value="france">Frankreich</option>
                                                                <option value="italy">Italien</option>
                                                                <option value="spain">Spanien</option>
                                                                <option value="great_britain">Großbritannien</option>
                                                                <option value="poland">Polen</option>
                                                                <option value="czech_republic">Tschechische Republik
                                                                </option>
                                                            </select>
                                                            {errors.country && <div style={{display: "block"}}
                                                                                    className="invalid-feedback">
                                                                {errors.country.message}
                                                            </div>}
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label>USt-ID</label>
                                                            <input ref={register()} name="ustid" type="text" readOnly
                                                                   className="form-control" placeholder=""
                                                                   defaultValue={loadedData.tax_office.ustid}/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label>E-Mail (Standard)</label>
                                                            <input ref={register()} name="email" type="email" readOnly
                                                                   className="form-control"
                                                                   placeholder=""
                                                                   defaultValue={loadedData.tax_office.email}/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label>E-Mail (Rechnungsversand)</label>
                                                            <input ref={register()} name="email2" type="email" readOnly
                                                                   className="form-control"
                                                                   placeholder=""
                                                                   defaultValue={loadedData.tax_office.email2}/>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <button data-repeater-delete=""
                                                                    onClick={abortRequest}
                                                                    className="btn rounded-pill ml-3 px-4 btn-danger font-weight-medium waves-effect waves-light m-l-10"
                                                                    type="button">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                     height="24" viewBox="0 0 24 24" fill="none"
                                                                     stroke="currentColor" strokeWidth="2"
                                                                     strokeLinecap="round" strokeLinejoin="round"
                                                                     className="feather feather-trash-2 feather-sm ms-2 fill-white">
                                                                    <polyline points="3 6 5 6 21 6"/>
                                                                    <path
                                                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/>
                                                                    <line x1="10" y1="11" x2="10" y2="17"/>
                                                                    <line x1="14" y1="11" x2="14" y2="17"/>
                                                                </svg>
                                                                Kanzlei entfernen
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Kanzleianfrage;